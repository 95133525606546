var KeyHelper = {};

/**
 * @const
 */
KeyHelper.KEY_CODE_TAB = 9;

/**
 * @const
 */
KeyHelper.KEY_CODE_ENTER = 13;

export default KeyHelper;
