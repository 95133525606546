import BaseComponent from 'js/base_v2/component';
import Renderer from 'js/components/renderer';
/**
 * Notifier.
 *
 * @class
 * @extends BaseComponent
 *
 * @param {DOMElement} ct
 * @param {object}     [options]
 */
function Notifier(ct, options) {
    BaseComponent.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {DOMElement}
     */
    this.ct = ct;

    /**
     * @inheritDoc
     */
    this.create = function() {
        parent.create.call(this);

        this.ct.hide();

        return this;
    };

    /**
     * Notify.
     *
     * @param  {string}   message
     * @param  {string}   type
     * @return {Notifier}
     */
    this.notify = function(message, type) {
        this.ct
            .removeClass('error info loading success warning hide')
            .addClass(type)
            .html(this.getMessage(message, type))
            .fadeIn();

        return this;
    };

    /**
     * Notify error.
     *
     * @param  {string}   message
     * @return {Notifier}
     */
    this.notifyError = function(message) {
        return this.notify(message, 'error');
    };

    /**
     * Notify info.
     *
     * @param  {string}   message
     * @return {Notifier}
     */
    this.notifyInfo = function(message) {
        return this.notify(message, 'info');
    };

    /**
     * Notify loading.
     *
     * @param  {string}   message
     * @return {Notifier}
     */
    this.notifyLoading = function(message) {
        return this.notify(message, 'loading');
    };

    /**
     * Notify success.
     *
     * @param  {string}   message
     * @return {Notifier}
     */
    this.notifySuccess = function(message) {
        return this.notify(message, 'success');
    };

    /**
     * Notify warning.
     *
     * @param  {string}   message
     * @return {Notifier}
     */
    this.notifyWarning = function(message) {
        return this.notify(message, 'warning');
    };

    /**
     * Clear notifications.
     *
     * @return {Notifier}
     */
    this.clear = function() {
        this.ct
            .hide()
            .html('');

        return this;
    };

    /**
     * Get message.
     *
     * @param  {string} message
     * @param  {string} type
     * @return {string}
     */
    this.getMessage = function(message, type) {
        return Renderer.Message(type || 'info', message, 'left');
    };
}

export default Notifier;
