import ModalAlert from 'js/components_v2/modal-alert';
import QuillTextarea from 'js/components_v2/quill-textarea';

const staticSelf = ModalConfirmation;

/**
 * @const
 */
staticSelf.DESTROY_DELAY = ModalAlert.DESTROY_DELAY;

/**
 * Modal Confirmation.
 *
 * @class
 * @extends ModalAlert
 *
 * @param {object}  [options]
 * @param {boolean} [inherited]
 */
function ModalConfirmation(options, inherited) {
  ModalAlert.call(this, options, true);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {object}
   */
  this.confirmData = {};

  /**
   * @prop {QuillTextarea}
   */
  this.quill = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      message: undefined,
      tmplEl: '#modal-confirmation',
      onConfirm: undefined,
      hasNote: false,
      modal: {
        onShown() {
          self.createQuillTextArea();
        },
      },
      quill: {},
    });
  };

  /**
   * Prepare template parameters.
   *
   * @return {ModalConfirmation}
   */
  this.prepareTmplParams = function() {
    parent.prepareTmplParams.call(this);

    return this.extendOptions({
      tmplParams: {
        message: this.options.message,
        confirmBtnClassName: 'green_btn',
        confirmBtnLabel: this.options.confirmBtnLabel ||
          this.translator.get('modal_confirmation_btn'),
        cancelBtnClassName: 'btn-white',
        cancelBtnLabel: this.options.cancelBtnLabel || 'Cancel',
        noteLabel: this.options.noteLabel,
        noteClassName: true === this.options.hasNote ?
          '' :
          'hidden',
      },
    });
  };

  /**
   * Create Quill Text Area.
   */
  this.createQuillTextArea = function() {
    if (true !== this.options.hasNote) {
      return;
    }

    this.quill = new QuillTextarea(
      $('.textEditor', this.modal.getContainer()),
      this.options.quill,
    ).create();
  };

  /**
   * Register event listeners.
   *
   * @return {ModalConfirmation}
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.modal.getContainer().on('click', '.confirm', (event) => {
      event.preventDefault();
      self.onConfirm();
    });

    return this;
  };

  /**
   * Confirm event handler.
   *
   * @param  {object}            [extraData]
   * @return {boolean|undefined}
   */
  this.onConfirm = function(extraData = {}) {
    let note = null;

    if (this.options.hasNote) {
      note = this.quill.getContent();
    }

    if (_.isFunction(this.options.onConfirm) &&
      false === this.options.onConfirm(note, {
        ...this.confirmData,
        ...extraData,
      })
    ) {
      return false;
    }

    return undefined;
  };

  if (true !== inherited) {
    // Initialize
    this.init();
  }
}

export default ModalConfirmation;
