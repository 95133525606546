import BaseHelper from 'js/base_v3/helpers/helper';
/**
 * Base Renderer.
 *
 * @class
 * @abstract
 * @extends BaseHelper
 *
 * @param {object} [options]
 */
function BaseRenderer(options) {
  BaseHelper.call(this, options);

  /**
     * Wrap text in container.
     *
     * @param  {string}  text
     * @param  {object}  [opts]
     * @return {string}
     */
  this.wrapTextInContainer = function(text, opts = {}) {
    const defaultOptions = {
      containerClassName: '',
      wrapperClassName: '',
      editEnabled: false,
    };

    const renderOptions = defaultOptions.extend(opts);

    return `\
      <div class="${renderOptions.containerClassName}">\
        ${this.wrapText(text, renderOptions)}\
        ${renderOptions.editEnabled ? '<span class="message"></span>' : ''}\
      </div>\
    `;
  };

  /**
     * Wrap text.
     *
     * @param  {string}  text
     * @param  {object} [opts]
     * @return {string}
     */
  this.wrapText = function(text, opts = {}) {
    const defaultOptions = {
      wrapperClassName: '',
    };

    const renderOptions = defaultOptions.extend(opts);

    return `\
      <span class="${renderOptions.wrapperClassName}">\
      ${text}\
      </span>\
    `;
  };

  /**
     * Wrap subtext.
     *
     * @param  {string}  subtext
     * @param  {object}  [opts]
     * @return {string}
     */
  this.wrapSubtext = function(subtext, opts = {}) {
    const defaultOptions = {
      wrapperClassName: 'help_text',
    };

    const renderOptions = defaultOptions.extend(opts);

    return this.wrapText(subtext, renderOptions);
  };

  /**
     * Render checkbox.
     *
     * @param  {object} record
     * @param  {object} [opts]
     * @return {string}
     */
  /* eslint-disable-next-line no-unused-vars */
  this.renderCheckbox = function(record, opts = {}) {
    const defaultOptions = {
      checkboxClassName: '',
    };

    const renderOptions = defaultOptions.extend(opts);

    return `
      <label
        class="selectSingle custom-control ${renderOptions.checkboxClassName}"
      >
        <span class="customControlIndicator custom-control-indicator"></span>
      </label>
      `;
  };

  /**
   * Render name (with pencil icon).
   *
   * @param  {object} record
   * @return {string}
   */
  this.renderEditableName = function(record) {
    return `
      <span>${record.name}</span>
      <svg class="icon-small"><use xlink:href="#icon-pencil"></use></svg>
    `;
  };

  /**
   * Render link text.
   *
   * @param  {string} text
   * @param  {object} [opts]
   * @return {string}
   */
  this.renderLinkText = function(text, opts = {}) {
    const defaultOptions = {
      url: '#',
      placeholder: '-',
    };

    const renderOptions = defaultOptions.extend(opts);

    if (_.isEmpty(text)) {
      return renderOptions.placeholder;
    }

    return `\
      <a\
        href="${renderOptions.url}"\
        class="defaultLink default-link blue-color-hover"\
      >\
        ${text}\
      </a>
      `;
  };
}

export default BaseRenderer;
