/* eslint-disable max-len */
import BaseSelect2AutocompleteField from 'js/base_v2/select2-autocomplete-field';
import CorporateUser from 'js/corporate_v2/models/user';
/* eslint-enable max-len */

/**
 * Corporate User Field.
 *
 * @class
 * @extends BaseSelect2AutocompleteField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} selectEl
 * @param {object}     [options]
 * @param {boolean}    [inherited]
 */
function CorporateUserField(fieldCt, selectEl, options, inherited) {
  BaseSelect2AutocompleteField.call(this, fieldCt, selectEl, options);
  const parent = this.clone();

  /**
     * @prop {object}
     */
  this.user = null;

  /**
     * @inheritDoc
     */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entitiesName: 'corporate_users',
      showCompany: false,
      showShortName: false,
      select2: {
        placeholder: 'Select a User',
        allowClear: false,
        ajax: {
          url: '/corporate/user/autocomplete',
        },
      },
    });
  };

  /**
     * @inheritDoc
     */
  this.select2TemplateResult = function(record) {
    if (record.loading) {
      return record.text;
    }

    if (record.newTag) {
      return parent.select2TemplateResult.call(this, record);
    }

    if (!this.options.showCompany) {
      return record.name;
    }

    return `${record.name} (${record.company_name})`;
  };

  /**
     * @inheritDoc
     */
  this.getRecordLabel = function(record) {
    const corporateUser = new CorporateUser(record);

    let currentUserSelected = false;

    if (_.isObject(this.user)) {
      currentUserSelected = !record.id ?
        false :
        this.user.corporate_user_id === record.id;
    }

    if (!record.id ||
      (!this.options.showShortName &&
        !this.options.showCompany &&
        !currentUserSelected
      )
    ) {
      return parent.getRecordLabel.call(this, record);
    }

    if (currentUserSelected && this.multiple) {
      return this.translator.getTitle('current_user_me_label');
    }

    if (this.options.showCompany) {
      return record.text || (`${record.name} (${record.company_name})`);
    }

    return corporateUser.getShortName();
  };

  if (true !== inherited) {
    // Initialize
    this.init();
  }
}

export default CorporateUserField;
