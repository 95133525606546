import BaseComponent from 'js/base_v3/components/component';

export default function AttachmentList(ct, options) {
    BaseComponent.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {DOMElement}
     */
    this.ct = ct;

    /**
     * @inheritDoc
     */
    this._initDefaults = function() {
        this.defaultOptions = {
            enableRemoval: false,
            addInputs: false,
            files: undefined
        };

        return this;
    };

    /**
     * @inheritDoc
     */
    this._registerEventListeners = function() {
        this.ct.on('click', 'a.removeAttachment', function(e) {
            e.preventDefault();
            $(this.closest('li')).remove();
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this._processOptions = function() {
        if (this._options.files) {
            for (var i=0; i<this._options.files.length; i++) {
                this.addFile(this._options.files[i]);
            }
        }

        return this;
    };

    /**
     * @param {string} mime
     * @return {string}
     */
    this.getIconFromMimeType = function(mime) {
        switch (mime) {
            case 'application/pdf':
                return 'icon-file-pdf';
        }

        var type = mime.split('/')[0];

        switch (type) {
            case 'image':
                return 'icon-instagram';
        }

        return 'icon-file';
    };

    /**
     * @prop {object} file
     * @return {AttachmentList}
     */
    this.addFile = function(file) {
        var icon = this.getIconFromMimeType(file.file_mime);

        var li = $('\
            <li>\
                <svg><use xlink:href="#' + icon + '"></use></svg>\
                <a class="defaultLink downloadAttachment" href=""></a>\
                <a class="removeAttachment" href="">\
                    <svg><use xlink:href="#icon-remove"></use></svg> \
                </a>\
                <input type="hidden" name="attachments[]" value=""> \
            </li>\
        ');

        li.find('.downloadAttachment').html(file.file_name);
        li.find('.downloadAttachment').attr('href', '/files/index/download/file/' + file.file_id);

        li.find('input').val(file.file_id);
        li.data('file', file);

        if (!this._options.enableRemoval) {
            li.find('.removeAttachment').remove();
        }

        if (!this._options.addInputs) {
            li.find('input').remove();
        }

        this.ct.prepend(li);

        return this;
    };

    /**
     * @return {AttachmentList}
     */
    this.clear = function() {
        this.ct.html('');

        return this;
    };

    this._init();
};
