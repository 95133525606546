import BaseField from 'js/base_v2/field';

const staticSelf = FileUploadField;

/**
 * @const
 */
staticSelf.FILE_TYPE_BOM_ATTACHMENT = 'bom_attachment';

/**
 * @const
 */
staticSelf.VENDOR_QUOTE_IMPORT = 'vendor_quote_import';

/**
 * File Upload Field.
 *
 * @class
 * @extends BaseField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     options
 */
function FileUploadField(fieldCt, fieldEl, options) {
  BaseField.call(this, fieldCt, fieldEl, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {number}
   */
  this.fileId = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    this.extendDefaultOptions({
      fileUploadParams: {},
      fileupload: {
        dataType: 'json',
        done(e, data) {
          self.onFileUploadDone(data);
        },
        progressall(e, data) {
          self.onFileUploadProgress(data);
        },
        send(e, data) {
          self.onFileUploadStart(data);
        },
        fail(e, data) {
          self.onFileUploadFail(data);
        },
      },
      beforeFileUpload: undefined,
      onFileUploadStart: undefined,
      onFileUploadDone: undefined,
      onFileUploadProgress: undefined,
      onFileUploadFail: undefined,
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);
    return this.initUpload();
  };

  /**
   * Initialize upload.
   *
   * @return {FileUploadField}
   */
  this.initUpload = function() {
    this.fieldEl.fileupload(this.options.fileupload.extend({
      formData: this.options.fileUploadParams,
    }));

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.fieldCt.on('click', '.upload', (e) => {
      self.onUploadBtnClick(e);
    });
  };

  /**
   * Upload file button click event handler.
   *
   * @param {Event} e
   */
  this.onUploadBtnClick = function(e) {
    e.preventDefault();

    if (false === this.beforeFileUpload()) {
      return;
    }

    this.fieldEl = this.fieldCt.find('input[type=file]');
    this.fieldEl.click();
  };

  /**
   * File upload done event handler.
   *
   * @param {object} data
   */
  this.onFileUploadDone = function(data) {
    this.fileId = data.result[0].id;

    if (self.options.notifier) {
      self.options.notifier.clear();
    }

    if (_.isFunction(this.options.onFileUploadDone) &&
      false === this.options.onFileUploadDone(data)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * File upload progress event handler.
   *
   * @param {object} data
   */
  this.onFileUploadProgress = function(data) {
    if (_.isFunction(this.options.onFileUploadProgress) &&
      false === this.options.onFileUploadProgress(data)
    ) {
      return false;
    }

    if (self.options.notifier) {
      self.options.notifier.notifyLoading(
        `Uploaded ${String(parseInt((data.loaded / data.total) * 100, 10))}%`,
      );
    }

    return undefined;
  };

  /**
   * Before file upload start event handler.
   *
   * @return {boolean|undefined}
   */
  this.beforeFileUpload = function() {
    if (_.isFunction(this.options.beforeFileUpload) &&
      false === this.options.beforeFileUpload()
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * File upload start event handler.
   *
   * @param {object} data
   */
  this.onFileUploadStart = function(data) {
    if (_.isFunction(this.options.onFileUploadStart) &&
      false === this.options.onFileUploadStart(data)
    ) {
      return false;
    }

    if (self.options.notifier) {
      self.options.notifier.notifyLoading('Uploading...');
    }

    return undefined;
  };

  /**
   * File upload fail event handler.
   *
   * @param {object} data
   */
  this.onFileUploadFail = function(data) {
    if (_.isFunction(this.options.onFileUploadFail) &&
      false === this.options.onFileUploadFail(data)
    ) {
      return false;
    }

    if (self.options.notifier) {
      self.options.notifier.notifyError(
        'An error occurred while uploading the file.',
      );
    }

    return undefined;
  };

  /**
   * Get file id.
   *
   * @return {string}
   */
  this.getFileId = function() {
    return this.fileId;
  };

  // Initialize
  this.init();
}

export default FileUploadField;
