import BaseHelper from 'js/base_v3/helpers/helper';

/**
 * Storage Helper.
 *
 * @class
 * @extends BaseHelper
 *
 * @param {object} [options]
 */
function StorageHelper(options) {
  BaseHelper.call(this, options);
  const parent = this.clone();

  /**
   * @protected
   *
   * @prop {?Storage}
   */
  this._localStorage = null;

  /**
   * Save content to given namespace.
   *
   * @param  {string}        namespace
   * @param  {*}             content
   * @return {StorageHelper}
   */
  this.save = function(namespace, content) {
    if (!_.isObject(this._localStorage)) {
      return this;
    }

    this._localStorage.setItem(namespace, JSON.stringify(content));

    return this;
  };

  /**
   * Get content saved in given namespace.
   *
   * @param  {string} namespace
   * @return {*}
   */
  this.get = function(namespace) {
    if (!_.isObject(this._localStorage)) {
      return undefined;
    }

    return JSON.parse(this._localStorage.getItem(namespace));
  };

  /**
   * Remove content from given namespace.
   *
   * @return {StorageHelper}
   */
  this.remove = function(namespace) {
    this._localStorage.removeItem(namespace);

    return this;
  };

  /**
   * @inheritDoc
   */
  this._initProps = function() {
    parent._initProps.call(this);

    try {
      this._localStorage = window.localStorage;
    } catch (error) {
      // Do nothing
    }

    return this;
  };

  // Initialize
  this._init();
}

export const storageHelper = new StorageHelper();

export default StorageHelper;
