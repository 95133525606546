import BaseComponent from 'js/base_v2/component';

/**
 * Modal.
 *
 * @class
 * @extends BaseComponent
 *
 * @param {DOMElement} ct
 * @param {object}     [options]
 */
function Modal(ct, options) {
  BaseComponent.call(this, options);
  const parent = this.clone();

  /**
   * @prop {DOMElement}
   */
  this.ct = ct;

  /**
   * @prop {boolean}
   */
  this.destroyTriggered = false;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      modal: {
        backdrop: 'static',
      },
      onShow: undefined,
      onShown: undefined,
      onHide: undefined,
      onHidden: undefined,
      onClose: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    this.registerEventListeners();

    this.ct.modal(this.options.modal);

    return this;
  };

  /**
   * Show modal.
   *
   * @return {Modal}
   */
  this.show = function() {
    this.ct.modal('show');

    return this;
  };

  /**
   * @inheritDoc
   */
  this.destroyNow = function() {
    if (!this.isVisible()) {
      this.ct.remove();
    } else {
      this.destroyTriggered = true;
      this.ct.modal('hide');
    }

    return this;
  };

  /**
   * Hide modal.
   *
   * @return {Modal}
   */
  this.hide = function() {
    this.ct.modal('hide');

    return this;
  };

  /**
   * Register event listeners.
   *
   * @return {Modal}
   */
  this.registerEventListeners = function() {
    this.ct.on('show.bs.modal', () => {
      this.onShow();
    });

    this.ct.on('shown.bs.modal', () => {
      this.onShown();
    });

    this.ct.on('hide.bs.modal', () => {
      this.onHide();
    });

    this.ct.on('hidden.bs.modal', () => {
      this.onHidden();
    });

    $('.closeModal', this.ct).on('click', (event) => {
      this.onCloseBtnClick(event);
    });

    return this;
  };

  /**
   * Modal show event handler.
   *
   * @return {boolean|undefined}
   */
  this.onShow = function() {
    if (_.isFunction(this.options.onShow) &&
      false === this.options.onShow()
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Modal shown event handler.
   *
   * @return {boolean|undefined}
   */
  this.onShown = function() {
    if (_.isFunction(this.options.onShown) &&
      false === this.options.onShown()
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Modal hide event handler.
   *
   * @return {boolean|undefined}
   */
  this.onHide = function() {
    if (_.isFunction(this.options.onHide) &&
      false === this.options.onHide(this.destroyTriggered)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Modal hidden event handler.
   *
   * @return {boolean|undefined}
   */
  this.onHidden = function() {
    if (_.isFunction(this.options.onHidden) &&
      false === this.options.onHidden(this.destroyTriggered)
    ) {
      return false;
    }

    if (this.destroyTriggered) {
      this.ct.remove();
      this.destroyTriggered = false;
    }

    return undefined;
  };

  /**
   * Close button click event handler.
   *
   * @param {Event} event
   */
  this.onCloseBtnClick = function(event) {
    event.preventDefault();

    if (_.isFunction(this.options.onClose)) {
      this.options.onClose();
    }

    this.destroy();
  };

  /**
   * Check whether the modal is visible.
   *
   * @return {boolean}
   */
  this.isVisible = function() {
    return 'true' !== this.ct.attr('aria-hidden');
  };

  /**
   * Get container DOM element.
   *
   * @return {DOMElement}
   */
  this.getContainer = function() {
    return this.ct;
  };

  // Initialize
  this.init();
}

export default Modal;
