import BaseEntity from 'js/base_v3/models/entity';
var staticSelf = CliJob;

/**
 * @const
 */
staticSelf.STATUS_IDLE = 'idle';

/**
 * @const
 */
staticSelf.STATUS_QUEUED = 'queued';

/**
 * @const
 */
staticSelf.STATUS_RUNNING = 'running';

/**
 * @const
 */
staticSelf.STATUS_FINISHED = 'finished';

/**
 * @const
 */
staticSelf.STATUS_FAILED = 'failed';

/**
 * @const
 */
staticSelf.STATUS_CANCELED = 'canceled';

/**
 * @const
 */
staticSelf.STATUS_EXPIRED = 'expired';

/**
 * Cli Job Entity.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {?object} data
 */
function CliJob(data) {
    BaseEntity.call(this, data);
    var parent = this.clone();
    var self = this;

    /**
     * Get result.
     *
     * @return {?object}
     */
    this.getResult = function() {
        return JSON.parse(this.result);
    };

    // Initialize
    this._init();
}

export default CliJob;
