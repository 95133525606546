import BaseHelper from 'js/helpers/base/helper';
import TableColumnTemplateHelper from 'js/helpers/table/column-template-helper';
import Renderer from 'js/components/renderer';

const staticSelf = TableColumnRenderHelper;

/**
 * @const
 */
staticSelf.TYPE_CHECKBOX = 'checkbox';

/**
 * @const
 */
staticSelf.TYPE_DATETIME = 'datetime';

/**
 * @const
 */
staticSelf.TYPE_DATE = 'date';

/**
 * @const
 */
staticSelf.TYPE_TIME = 'time';

/**
 * @const
 */
staticSelf.TYPE_NUMBER = '';

/**
 * @const
 */
staticSelf.TYPE_INT = 'int';

/**
 * @const
 */
staticSelf.TYPE_QUANTITY = 'quantity';

/**
 * @const
 */
staticSelf.TYPE_QUANTITY_AND_UOM = 'quantity_and_uom';

/**
 * @const
 */
staticSelf.TYPE_RESPONSIVE_BTN = 'responsive_btn';

/**
 * @const
 */
staticSelf.TYPE_PERCENTAGE = 'percentage';

/**
 * @const
 */
staticSelf.TYPE_VALUE_TO_LABEL = 'value_to_label';

/**
 * @const
 */
staticSelf.TYPE_ADDRESS = 'address';

/**
 * @const
 */
staticSelf.TYPE_TEXT = 'text';

/**
 * @const
 */
staticSelf.TYPE_TEXT_WORDS_CAPITALIZED = 'text_capitalized';

/**
 * @const
 */
staticSelf.TYPE_EMAIL = 'email';

/**
 * @const
 */
staticSelf.TYPE_PRICING = 'pricing';

/**
 * @const
 */
staticSelf.TYPE_TOGGLE = 'toggle';

/**
 * @const
 */
staticSelf.TYPE_TOGGLE_ACTION = 'toggle_action';

/**
 * @const
 */
staticSelf.TYPE_LIST = 'list';

/**
 * @const
 */
staticSelf.DEFAULT_TYPE = staticSelf.TYPE_TEXT;

/**
 * @const
 */
staticSelf.CONTENT_DISPOSITION_DEFAULT = 'default';

/**
 * @const
 */
staticSelf.CONTENT_DISPOSITION_SUB = 'sub';

/**
 * Table Column Render Helper.
 *
 * @class
 * @extends BaseHelper
 *
 * @param {object} options
 */
function TableColumnRenderHelper(options) {
  BaseHelper.call(this, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {TableColumnTemplateHelper}
   */
  this.columnTemplateHelper = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      columnTemplateHelper: {},
    });
  };

  /**
   * @inheritDoc
   */
  this.initProps = function() {
    parent.initProps.call(this);

    this.columnTemplateHelper = new TableColumnTemplateHelper(
      this.options.columnTemplateHelper,
    );

    return this;
  };

  /**
   * Process column render.
   *
   * @param  {object} column
   * @return {object}
   */
  this.processRender = function(column) {
    if (!_.isUndefined(column.render)) {
      return column;
    }

    const columnRenderer = this.buildRenderer(column);

    const columnSubRenderer = this.buildRenderer(column, {
      contentType: staticSelf.CONTENT_DISPOSITION_SUB,
    });

    // eslint-disable-next-line no-param-reassign
    column.render = function(record) {
      let content = '';

      if (_.isFunction(columnRenderer)) {
        content += columnRenderer(record);
      }

      if (_.isFunction(columnSubRenderer)) {
        content += columnSubRenderer(record);
      }

      return content;
    };

    return column;
  };

  /**
   * Build column renderer.
   *
   * @param  {object}    column
   * @param  {object}    [renderOpts]
   * @return {?function}
   */
  this.buildRenderer = function(column, renderOpts = {}) {
    const renderOptions = {
      contentType: staticSelf.CONTENT_DISPOSITION_DEFAULT,
      ...renderOpts,
    };

    let rendererType = null;

    switch (renderOptions.contentType) {
      case staticSelf.CONTENT_DISPOSITION_SUB:
        rendererType = column.subType;

        if (!_.isString(rendererType) || 0 === rendererType.length) {
          return null;
        }

        break;
      case staticSelf.CONTENT_DISPOSITION_DEFAULT:
      default:
        rendererType = column.type;
    }

    switch (rendererType) {
      case staticSelf.TYPE_CHECKBOX:
        return this.buildCheckboxRenderer(column);
      case staticSelf.TYPE_DATETIME:
        return this.buildDatetimeRenderer(column, renderOptions);
      case staticSelf.TYPE_DATE:
        return this.buildDateRenderer(column, renderOptions);
      case staticSelf.TYPE_TIME:
        return this.buildTimeRenderer(column, renderOptions);
      case staticSelf.TYPE_NUMBER:
        return this.buildNumberRenderer(column, renderOptions);
      case staticSelf.TYPE_INT:
        return this.buildIntRenderer(column, renderOptions);
      case staticSelf.TYPE_QUANTITY:
        return this.buildQuantityRenderer(column, renderOptions);
      case staticSelf.TYPE_QUANTITY_AND_UOM:
        return this.buildQuantityAndUomRenderer(column, renderOptions);
      case staticSelf.TYPE_RESPONSIVE_BTN:
        return this.buildResponsiveBtnRenderer();
      case staticSelf.TYPE_PERCENTAGE:
        return this.buildPercentageRenderer(column, renderOptions);
      case staticSelf.TYPE_VALUE_TO_LABEL:
        return this.buildValueToLabelRenderer(column, renderOptions);
      case staticSelf.TYPE_ADDRESS:
        return this.buildAddressRenderer(column, renderOptions);
      case staticSelf.TYPE_TEXT_WORDS_CAPITALIZED:
        return this.buildTextWordsCapitalizedRenderer(column, renderOptions);
      case staticSelf.TYPE_EMAIL:
        return this.buildEmailRenderer(column, renderOptions);
      case staticSelf.TYPE_PRICING:
        return this.buildPricingRenderer(column, renderOptions);
      case staticSelf.TYPE_TOGGLE:
        return this.buildToggleRenderer(column, renderOptions);
      case staticSelf.TYPE_TOGGLE_ACTION:
        return this.buildToggleActionRenderer(column, renderOptions);
      case staticSelf.TYPE_LIST:
        return this.buildListRenderer(column, renderOptions);
      case staticSelf.TYPE_TEXT:
      default:
        return this.buildTextRenderer(column, renderOptions);
    }
  };

  /**
   * Build checkbox renderer.
   *
   * @param  {object}   column
   * @return {function}
   */
  this.buildCheckboxRenderer = function(column) {
    return function() {
      return _.template(self.columnTemplateHelper.getCheckboxTmpl())({
        className: column.wrapperClassName || 'selectSingle',
      });
    };
  };

  /**
   * Build date and time renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildDatetimeRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.DateAndTime(self.getValue(record, column, renderOptions)),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build date renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildDateRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.Date(self.getValue(record, column, renderOptions)),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build time renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildTimeRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.Time(self.getValue(record, column, renderOptions)),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build number renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildNumberRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.Number(self.getValue(record, column, renderOptions)),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build integer renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildIntRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.Int(self.getValue(record, column, renderOptions)),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build quantity renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildQuantityRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.Quantity(
          self.getValue(record, column, renderOptions), // Amount
          undefined, // Format
          undefined, // Allow Zero Amount
          undefined, // Thousand Separator
          undefined, // Decimals
          self.getPlaceholder(column, renderOptions), // Placeholder
        ),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build quantity and UOM renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildQuantityAndUomRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.QuantityAndUom(
          self.getValue(record, column, renderOptions),
          record.uom,
        ),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build responsive button renderer.
   *
   * @return {function}
   */
  this.buildResponsiveBtnRenderer = function() {
    return function() {
      return _.template(self.columnTemplateHelper.getResponsiveBtnTmpl())();
    };
  };

  /**
   * Build percentage renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildPercentageRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.Percentage(self.getValue(record, column, renderOptions)),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build value to label renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildValueToLabelRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.valueToLabel(
          self.getValue(record, column, renderOptions),
          { placeholder: self.getPlaceholder(column, renderOptions) },
        ),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build address renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildAddressRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.Address(self.getValue(record, column, renderOptions)),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build text words capitalized renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildTextWordsCapitalizedRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        self.getValue(record, column, renderOptions).capitalizeWords(),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build email renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildEmailRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.Email(self.getValue(record, column, renderOptions)),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build text renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildTextRenderer = function(column, renderOptions) {
    return function(record) {
      let content = self.getValue(record, column, renderOptions);

      if ($.trim(content) === '') {
        content = self.getPlaceholder(column, renderOptions);
      }

      return self.wrapContent(
        content,
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build pricing renderer.
   *
   * @param  {object}   column
   * @param  {object}   renderOptions
   * @return {function}
   */
  this.buildPricingRenderer = function(column, renderOptions) {
    return function(record) {
      return self.wrapContent(
        Renderer.Price(self.getValue(record, column, renderOptions)),
        {
          ...renderOptions,
          className: column.wrapperClassName,
        },
      );
    };
  };

  /**
   * Build toggle button renderer.
   *
   * @param  {object}   column
   * @return {function}
   */
  this.buildToggleRenderer = function(column) {
    return function(record) {
      return _.template(self.columnTemplateHelper.getToggleTmpl())({
        checked: 1 === +self.getValue(record, column) ? 'checked' : '',
      });
    };
  };

  /**
   * Build toggle action button renderer.
   *
   * @param  {object}   column
   * @return {function}
   */
  this.buildToggleActionRenderer = function(column) {
    return function(record) {
      const value = self.getValue(record, column);
      let actionButtonClassName = column.actionButtonClassName || '';

      let { checked } = column;

      if (_.isFunction(checked)) {
        checked = checked(record);
      } else if (_.isBoolean(checked)) {
        // Nothing to do
      } else {
        checked = 1 === +value;
      }

      let { disabled } = column;

      if (_.isFunction(disabled)) {
        disabled = disabled(record);
      }

      if (disabled) {
        actionButtonClassName += ' disable';
      }

      return _.template(self.columnTemplateHelper.getToggleActionTmpl())({
        containerClassName: column.containerClassName || '',
        actionButtonClassName,
        name: column.name,
        value,
        checked: checked ? 'checked' : '',
      });
    };
  };

  /**
   * Build list renderer.
   *
   * @param  {object}   column
   * @return {function}
   */
  this.buildListRenderer = function(column) {
    return (record) => {
      const placeholder = _.isString(self.getPlaceholder(column)) ?
        self.getPlaceholder(column) :
        '';

      const valueArr = self.getValue(record, column);

      if (!_.isArray(valueArr) || _.isEmpty(valueArr)) {
        return placeholder;
      }

      const itemToLabel = _.isFunction(column.itemToLabel) ?
        column.itemToLabel :
        (item) => item.name;

      const separator = _.isString(column.separator) ?
        column.separator :
        ', ';

      return valueArr
        .map((item) => itemToLabel(item))
        .join(separator);
    };
  };

  /**
   * Get value from record based on column config and options.
   *
   * @param {object} record
   * @param {object} column
   * @param {object} [renderOpts]
   */
  this.getValue = function(record, column, renderOpts = {}) {
    const renderOptions = {
      contentType: staticSelf.CONTENT_DISPOSITION_DEFAULT,
      ...renderOpts,
    };

    switch (renderOptions.contentType) {
      case staticSelf.CONTENT_DISPOSITION_SUB:
        return Object.byString(record, column.subName);
      case staticSelf.CONTENT_DISPOSITION_DEFAULT:
      default:
        return Object.byString(record, column.name);
    }
  };

  /**
   * Get placeholder based on column config and options.
   *
   * @param  {object} column
   * @param  {object} [renderOpts]
   * @return {string}
   */
  this.getPlaceholder = function(column, renderOpts = {}) {
    const renderOptions = {
      contentType: staticSelf.CONTENT_DISPOSITION_DEFAULT,
      ...renderOpts,
    };

    switch (renderOptions.contentType) {
      case staticSelf.CONTENT_DISPOSITION_SUB:
        return column.subPlaceholder;
      case staticSelf.CONTENT_DISPOSITION_DEFAULT:
      default:
        return column.placeholder;
    }
  };

  /**
   * Wrap content.
   *
   * @param  {string} content
   * @param  {object} [renderOpts]
   * @return {string}
   */
  this.wrapContent = function(content, renderOpts) {
    const renderOptions = {
      contentType: staticSelf.CONTENT_DISPOSITION_DEFAULT,
      className: '',
      ...renderOpts,
    };

    switch (renderOptions.contentType) {
      case staticSelf.CONTENT_DISPOSITION_SUB:
        renderOptions.className += ' help_text';
        break;
      case staticSelf.CONTENT_DISPOSITION_DEFAULT:
      default:
        // Do nothing
    }

    return _.template(this.columnTemplateHelper.getContentWrapperTmpl())({
      className: renderOptions.className,
      content,
    });
  };

  // Initialize
  this.init();
}

export default TableColumnRenderHelper;
