import BaseFilter from 'js/base_v2/filter';
import KeyHelper from 'js/helpers/key-helper';

/**
 * Text Filter.
 *
 * @class
 * @extends BaseFilter
 *
 * @param {DOMElement} filterEl
 * @param {object}     [options]
 */
function TextFilter(filterEl, options) {
  BaseFilter.call(this, filterEl, options);
  const parent = this.clone();

  /**
   * @prop {BaseField}
   */
  this.field = null;

  /**
   * @inheritDoc
   */
  this.create = function() {
    this.initFilterButtons();

    this.filterEl.attr('autocomplete', 'off');

    return parent.create.call(this);
  };

  /**
   * @inheritDoc
   */
  this.initializeValue = function() {
    parent.initializeValue.call(this);

    if (_.isFunction(this.options.fieldClassName)) {
      this.extendOptions({
        fieldOptions: {
          initialValue: this.options.initialValue,
        },
      });
    } else {
      this.filterEl.val(this.options.initialValue);
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.createField = function() {
    parent.createField.call(this);

    if (_.isFunction(this.options.fieldClassName)) {
      this.field = new this.options.fieldClassName(
        this.filterEl.closest('.selectCt'),
        this.filterEl,
        this.options.fieldOptions,
      ).create();
    }

    if (!_.isEmpty(this.getValue())) {
      this.showClearSearchButton();
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      triggerEvent: '',
    });
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.filterEl.on('input', () => {
      this.showStartSearchButton();
    });

    this.filterEl.on('keypress', (event) => {
      if (KeyHelper.KEY_CODE_ENTER === event.which) {
        this.trigger();
      }
    });

    this.startSearchButton.on('click', () => {
      this.trigger();
    });

    this.clearSearchButton.on('click', () => {
      this.clear();
      this.hideFilterButtons();
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.clear = function() {
    parent.clear.call(this);

    this.filterEl.val(null);

    return this.trigger();
  };

  /**
   * @inheritDoc
   */
  this.reset = function() {
    parent.reset.call(this);

    this.filterEl.val(!_.isUndefined(this.options.defaultValue) ?
      this.options.defaultValue :
      null);

    this.hideFilterButtons();

    return this.trigger();
  };

  /**
   * initialize filter buttons.
   *
   * @return {TextFilter}
   */
  this.initFilterButtons = function() {
    this.filterElContainer = this.filterEl.parent();

    const filterButtons = `
      <div class="text-filter-buttons-container">
        <button type="button" class="startSearchButton filter-button hidden">
          <span class="in_button wrap_tooltip">
            <svg>
              <use xlink:href="#icon-magnifying-glass-arrow-right"></use>
            </svg>
            <span class="desc_tooltip">
              ${this.translator.get('label_search')}
            </span>
          </span>
        </button>
        <button type="button" class="clearSearchButton filter-button hidden">
          <span class="in_button wrap_tooltip">
            <svg><use xlink:href="#icon-circle-xmark"></use></svg>
            <span class="desc_tooltip">
              ${this.translator.get('label_clear')}
            </span>
          </span>
        </button>
      </div>
    `;

    this.filterElContainer
      .addClass('filter-buttons-container')
      .append(filterButtons);

    this.startSearchButton = $('.startSearchButton', this.filterElContainer);
    this.clearSearchButton = $('.clearSearchButton', this.filterElContainer);

    return this;
  };

  /**
   * Hide filter buttons.
   *
   * @return {TextFilter}
   */
  this.hideFilterButtons = function() {
    this.filterEl.removeClass('active-text-filter');

    this.clearSearchButton.addClass('hidden');
    this.startSearchButton.addClass('hidden');

    return this;
  };

  /**
   * Show start search button.
   *
   * @return {TextFilter}
   */
  this.showStartSearchButton = function() {
    this.filterEl.addClass('active-text-filter');

    this.clearSearchButton.addClass('hidden');
    this.startSearchButton.removeClass('hidden');

    return this;
  };

  /**
   * Show clear search button.
   *
   * @return {TextFilter}
   */
  this.showClearSearchButton = function() {
    this.filterEl.addClass('active-text-filter');

    this.clearSearchButton.removeClass('hidden');
    this.startSearchButton.addClass('hidden');

    return this;
  };

  /**
   * Update filter buttons state.
   *
   * @return {TextFilter}
   */
  this.updateFilterButtonsState = function() {
    if (_.isEmpty(this.getValue())) {
      return this.hideFilterButtons();
    }

    this.showClearSearchButton();

    return this;
  };

  // Initialize
  this.init();
}

export default TextFilter;
