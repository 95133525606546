import BaseHelper from 'js/helpers/base/helper';
/**
 * Table Column Template Helper.
 *
 * @class
 * @extends BaseHelper
 *
 * @param {object} options
 */
function TableColumnTemplateHelper(options) {
    BaseHelper.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * Get content wrapper template.
     *
     * @return {string}
     */
    this.getContentWrapperTmpl = function() {
        return '<span class="{{ className }}">{{ content }}</span>';
    };

    /**
     * Get checkbox template.
     *
     * @return {string}
     */
    this.getCheckboxTmpl = function() {
        return '\
            <label class="{{ className }} custom-control custom-checkbox">\
                <span class="customControlIndicator custom-control-indicator"></span>\
            </label>\
        ';
    };

    /**
     * Get responsive button template.
     *
     * @return {string}
     */
    this.getResponsiveBtnTmpl = function() {
        return '<span class="table_responsive_bt">...</span>';
    };

    /**
     * Get toggle button template.
     *
     * @return {string}
     */
    this.getToggleTmpl = function() {
        return '\
            <label class="toggleButton btn_toogle">\
                <input type="checkbox" {{ checked }}>\
                <span class="c-indicator"></span>\
            </label>\
        ';
    };

    /**
     * Get toggle action button template.
     *
     * @return {string}
     */
    this.getToggleActionTmpl = function() {
        return '\
            <div class="{{ containerClassName }}">\
                <a href="#" class="{{ actionButtonClassName }} toggle-action-button" data-name="{{ name }}" data-value="{{ value }}">\
                    <label class="btn_toogle">\
                        <input type="checkbox" {{ checked }}>\
                        <span class="c-indicator"></span>\
                    </label>\
                </a>\
                <span class="messageSpan message-span"></span>\
            </div>\
        ';
    };

    // Initialize
    this.init();
}

export default TableColumnTemplateHelper;
