import BaseComponent from 'js/base_v2/component';
import FileUploadField from 'js/base_v2/fields/file-upload-field';
import AttachmentList from 'js/base_v3/components/attachment-list';
import { cliAjax } from 'js/cli_v2/helpers/ajax';
import FieldExtension from 'js/common_v3/extensions/field-extension';
import Ajax from 'js/components/ajax';
import Notifier from 'js/components/notifier';

/**
 * Ajax Form.
 *
 * @class
 * @extends BaseComponent
 *
 * @param {DOMElement} formEl
 * @param {object}     [options]
 * @param {boolean}    [inherited]
 */
function AjaxForm(formEl, options, inherited) {
  BaseComponent.call(this, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {object}
   */
  this.fieldsRequired = null;

  /**
   * @prop {DOMElement}
   */
  this.formEl = formEl;

  /**
   * @prop {FieldExtension}
   */
  this.fieldExtension = null;

  /**
   * @prop {FileUploadField}
   */
  this.fileUploadField = null;

  /**
   * @prop {Notifier}
   */
  this.notifier = null;

  /**
   * @prop {AttachmentList}
   */
  this.attachmentList = null;

  /**
   * @prop {boolean}
   */
  this.uploadInProgress = false;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    this.defaultOptions = {
      ajaxSubmit: true,
      cliAjax: false,
      autoSubmitIfValid: false,
      hiddenFields: [],
      serverParams: {},
      fieldExtension: {},
      fileUploadFieldCt: undefined,
      fileUploadFieldEl: undefined,
      fileUploadField: {
        onFileUploadStart: () => {
          this.uploadInProgress = true;
        },
        onFileUploadFail: () => {
          this.uploadInProgress = false;
        },
        onFileUploadDone: (data) => {
          this.uploadInProgress = false;
          this.onFileUploaded(data.result[0]);
        },
      },
      attachmentsCt: undefined,
      attachmentList: {
        enableRemoval: true,
        addInputs: true,
      },
      validate: undefined,
      beforeSubmit: undefined,
      afterSubmit: undefined,
      onSubmitError: undefined,
    };

    return this.initUploadOptions();
  };

  /**
   * Init upload options
   *
   * @return {AjaxForm}
   */
  this.initUploadOptions = function() {
    parent.processOptions.call(this);

    if (this.formEl) {
      this.options.attachmentsCt =
        this.options.attachmentsCt || $('.attachmentsCt', this.formEl);

      this.options.fileUploadFieldCt =
        this.options.fileUploadFieldCt || $('.fileUploadCt', this.formEl);

      this.options.fileUploadFieldEl =
        this.options.fileUploadFieldEl || $('.fileUpload', this.formEl);
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initProps = function() {
    parent.initProps.call(this);

    this.fieldExtension = new FieldExtension(
      this,
      this.options.fieldExtension,
    );

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    // Create notifier
    this.notifier = new Notifier($('.notificationCt', this.formEl)).create();

    return this
      .registerEventListeners()
      .createUploadField()
      .createAttachmentList()
      .tryAutoSubmit();
  };

  /**
   * Create file upload field.
   *
   * @return {AjaxForm}
   */
  this.createUploadField = function() {
    if ($(this.options.fileUploadFieldCt).length > 0) {
      this.fileUploadField = new FileUploadField(
        this.options.fileUploadFieldCt,
        this.options.fileUploadFieldEl,
        this.options.fileUploadField.extend({
          notifier: this.notifier,
        }),
      ).create();
    }

    return this;
  };

  /**
   * Create attachment list.
   *
   * @return {AjaxForm}
   */
  this.createAttachmentList = function() {
    this.attachmentList = new AttachmentList(
      this.options.attachmentsCt,
      this.options.attachmentList,
    ).create();

    return this;
  };

  /**
   * Prepare server parameters.
   *
   * @return {AjaxForm}
   */
  this.prepareServerParams = function() {
    return this;
  };

  /**
   * Register event listeners.
   *
   * @return {AjaxForm}
   */
  this.registerEventListeners = function() {
    this.formEl.on('submit', (event) => {
      self.onSubmit(true, event);
    });

    return this;
  };

  /**
   * Try auto submit.
   *
   * @return {AjaxForm}
   */
  this.tryAutoSubmit = function() {
    if (!this.options.autoSubmitIfValid) {
      return this;
    }

    if (this.validate(false)) {
      return this.submit();
    }

    return this;
  };

  /**
   * Form submit event handler.
   *
   * @param {?boolean} validate
   * @param {?Event}   event
   */
  this.onSubmit = function(validate, event) {
    // eslint-disable-next-line no-param-reassign
    validate = _.isBoolean(validate) ? validate : true;

    if (validate && !this.validate()) {
      if (_.isObject(event)) {
        event.preventDefault();
      }

      return;
    }

    if (this.uploadInProgress) {
      if (_.isObject(event)) {
        event.preventDefault();
      }

      return;
    }

    const formData = this.getFormData(true, true);

    const method = this.getSubmitMethod();

    if (false === this.beforeSubmit(formData, method)) {
      event.preventDefault();
      return;
    }

    if (!this.options.ajaxSubmit) {
      return;
    }

    if (_.isObject(event)) {
      event.preventDefault();
    }

    if (this.options.cliAjax) {
      cliAjax[method](
        this.getActionUrl(),
        formData,
        (cliJob) => {
          this.onSubmitSuccess(cliJob.getResult());
        },
        (errorMessage) => {
          this.onSubmitError({}, errorMessage);
        },
      );
    } else {
      Ajax[method](
        this.getActionUrl(),
        formData,
        (response) => {
          self.onSubmitSuccess(response);
        },
        (response, errorMessage) => {
          self.onSubmitError(response, errorMessage);
        },
      );
    }
  };

  /**
   * @return {string}
   */
  this.getSubmitMethod = function() {
    const method = this.formEl.attr('method');
    return method === 'GET' ? 'get' : 'post';
  };

  /**
   * Submit success event handler.
   *
   * @param {object} response
   */
  this.onSubmitSuccess = function(response) {
    this.afterSubmit(response);
  };

  /**
   * Submit error event handler.
   *
   * @param  {object}            response
   * @param  {string}            errorMessage
   * @return {boolean|undefined}
   */
  this.onSubmitError = function(response, errorMessage) {
    if (_.isFunction(this.options.onSubmitError) &&
      false === this.options.onSubmitError(response, errorMessage)
    ) {
      return false;
    }

    this
      .notifyError(errorMessage, response?.error_type)
      .enableSubmitBtn();

    return undefined;
  };

  /**
   * Before submit event handler.
   *
   * @param  {object}            formData
   * @param  {string}            method
   * @return {boolean|undefined}
   */
  // eslint-disable-next-line no-unused-vars
  this.beforeSubmit = function(formData, method) {
    if (_.isFunction(this.options.beforeSubmit)) {
      const ret = this.options.beforeSubmit(formData);

      if (false === ret) {
        return ret;
      }
    }

    this
      .disableSubmitBtn()
      .notifyLoading(this.getBeforeSubmitMessage());

    return undefined;
  };

  /**
   * Process form data before submit.
   *
   * @param  {object} formData
   * @return {object}
   */
  this.processFormData = function(formData) {
    const processedFormData = formData;

    $('input[type="checkbox"]:enabled', this.formEl).each(function() {
      const name = $(this).attr('name');
      const value = $(this).prop('checked') ? 1 : 0;

      if (_.isString(name) && !_.isEmpty(name)) {
        processedFormData[name] = value;
      }
    });

    return processedFormData;
  };

  /**
   * Create input fields from data.
   *
   * @param  {object}   data
   * @param  {?string}  namePrefix
   * @return {AjaxForm}
   */
  this.createInputsFromData = function(data, namePrefix) {
    // eslint-disable-next-line no-param-reassign
    namePrefix = _.isString(namePrefix) ? namePrefix : '';
    let name;

    _.each(data, function(value, key) {
      name = _.isEmpty(namePrefix) ? key : `${namePrefix}[${key}]`;

      if (_.isObject(value)) {
        this.createInputsFromData(value, name);
        return;
      }

      this.appendHiddenInput(name, value);
    }, this);

    return this;
  };

  /**
   * Append hidden input to form.
   *
   * @param  {string}   name
   * @param  {*}        value
   * @return {AjaxForm}
   */
  this.appendHiddenInput = function(name, value) {
    const hiddenInput = $('<input>')
      .attr('type', 'hidden')
      .attr('name', name)
      .val(value);

    this.formEl.append(hiddenInput);

    return this;
  };

  /**
   * After submit event handler.
   *
   * @param  {object}            response
   * @return {boolean|undefined}
   */
  this.afterSubmit = function(response) {
    if (_.isFunction(this.options.afterSubmit)) {
      const ret = this.options.afterSubmit(response);

      if (false === ret) {
        return ret;
      }
    }

    const afterSubmitMessage = this.getAfterSubmitMessage(response);

    if (_.isString(afterSubmitMessage) && !_.isEmpty(afterSubmitMessage)) {
      this.notifySuccess(afterSubmitMessage);
    } else {
      this.clearNotifications();
    }

    this.enableSubmitBtn();

    return undefined;
  };

  /**
   * Get constraints.
   *
   * @return {object}
   */
  this.getConstraints = function() {
    return {};
  };

  /**
   * Validate form.
   *
   * @param  {boolean} notify
   * @return {boolean}
   */
  this.validate = function(notify) {
    // eslint-disable-next-line no-param-reassign
    notify = _.isUndefined(notify) ? true : notify;
    const formData = this.getFormData();
    const constraints = this.getConstraints();

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const field in constraints) {
      const fieldConstraints = constraints[field];

      const valid = this.validateField(
        formData,
        field,
        fieldConstraints,
        notify,
      );

      if (!valid) {
        return false;
      }
    }

    if (_.isFunction(this.options.validate)) {
      return this.options.validate(notify, formData);
    }

    return true;
  };

  /**
   * Validate form field.
   *
   * @param  {object}   formData
   * @param  {string}   field
   * @param  {object[]} fieldConstraints
   * @param  {boolean}  notify
   * @return {boolean}
   */
  this.validateField = function(formData, field, fieldConstraints, notify) {
    // eslint-disable-next-line no-param-reassign
    notify = _.isUndefined(notify) ? true : notify;

    const hidden = _.contains(this.options.hiddenFields, field);
    const disabled = _.contains(this.options.disabledFields, field);

    if ((hidden && fieldConstraints.skipIfHidden) ||
      (disabled && fieldConstraints.skipIfDisabled)
    ) {
      return true;
    }

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const constraintName in fieldConstraints) {
      const constraint = fieldConstraints[constraintName];
      let valid = true;

      switch (constraintName) {
        case 'required':
          valid = this.checkRequiredConstraint(
            formData,
            field,
            constraint,
          );

          break;
        case 'number_int':
          valid = this.checkIntegerConstraint(
            formData,
            field,
            constraint,
          );
          break;
        case 'number_gt':
          valid = this.checkNumberGtConstraint(
            formData,
            field,
            constraint,
          );

          break;
        case 'number_gte':
          valid = this.checkNumberGteConstraint(
            formData,
            field,
            constraint,
          );

          break;
        case 'number_lt':
          valid = this.checkNumberLtConstraint(
            formData,
            field,
            constraint,
          );

          break;
        case 'number_lte':
          valid = this.checkNumberLteConstraint(
            formData,
            field,
            constraint,
          );

          break;
        default:
          // Do nothing
      }

      if (!valid) {
        if (notify) {
          this.notifyValidationError(field, constraint.message);
        }

        return false;
      }
    }

    return true;
  };

  /**
   * Check "required" constraint.
   *
   * @param  {object}  formData
   * @param  {string}  field
   * @param  {object}  constraint
   * @return {boolean}
   */
  // eslint-disable-next-line no-unused-vars
  this.checkRequiredConstraint = function(formData, field, constraint) {
    const value = Object.byString(formData, field);

    if (_.isUndefined(value) ||
      _.isNull(value) ||
      (_.isString(value) && 0 === $.trim(value).length) ||
      (_.isObject(value) && _.isEmpty(value))
    ) {
      return false;
    }

    return true;
  };

  /**
   * Check "integer" constraint.
   *
   * @param  {object}  formData
   * @param  {string}  field
   * @param  {object}  constraint
   * @return {boolean}
   */
  // eslint-disable-next-line no-unused-vars
  this.checkIntegerConstraint = function(formData, field, constraint) {
    const value = Object.byString(formData, field) * 1;

    if (!_.isNumber(value)) {
      return false;
    }

    if (!Number.isInteger(value)) {
      return false;
    }

    return true;
  };

  /**
   * Check "number greater than" constraint.
   *
   * @param  {object}  formData
   * @param  {string}  field
   * @param  {object}  constraint
   * @return {boolean}
   */
  this.checkNumberGtConstraint = function(formData, field, constraint) {
    let value = Object.byString(formData, field);

    if (_.isEmpty(value)) {
      return true;
    }

    value = parseInt(value, 10);

    if (!_.isNumber(value) || _.isNaN(value)) {
      return false;
    }

    return value > constraint.value;
  };

  /**
   * Check "number greater than or equal" constraint.
   *
   * @param  {object}  formData
   * @param  {string}  field
   * @param  {object}  constraint
   * @return {boolean}
   */
  this.checkNumberGteConstraint = function(formData, field, constraint) {
    let value = Object.byString(formData, field);

    if (_.isEmpty(value)) {
      return true;
    }

    value = parseInt(value, 10);

    if (!_.isNumber(value) || _.isNaN(value)) {
      return false;
    }

    return value >= constraint.value;
  };

  /**
   * Check "number less than" constraint.
   *
   * @param  {object}  formData
   * @param  {string}  field
   * @param  {object}  constraint
   * @return {boolean}
   */
  this.checkNumberLtConstraint = function(formData, field, constraint) {
    let value = Object.byString(formData, field);

    if (_.isEmpty(value)) {
      return true;
    }

    value = parseInt(value, 10);

    if (!_.isNumber(value) || _.isNaN(value)) {
      return false;
    }

    return value < constraint.value;
  };

  /**
   * Check "number less than or equal" constraint.
   *
   * @param  {object}  formData
   * @param  {string}  field
   * @param  {object}  constraint
   * @return {boolean}
   */
  this.checkNumberLteConstraint = function(formData, field, constraint) {
    let value = Object.byString(formData, field);

    if (_.isEmpty(value)) {
      return true;
    }

    value = parseInt(value, 10);

    if (!_.isNumber(value) || _.isNaN(value)) {
      return false;
    }

    return value <= constraint.value;
  };

  /**
   * Notify error.
   *
   * @param  {string}   message
   * @param  {string}  [errorType]
   * @return {AjaxForm}
   */
  /* eslint-disable-next-line no-unused-vars */
  this.notifyError = function(message, errorType = null) {
    this.notifier.notifyError(message);

    this.onNotificationShown();

    return this;
  };

  /**
   * Notify loading.
   *
   * @param  {string}   message
   * @return {AjaxForm}
   */
  this.notifyLoading = function(message) {
    this.notifier.notifyLoading(message);

    this.onNotificationShown();

    return this;
  };

  /**
   * Notify success.
   *
   * @param  {string}   message
   * @return {AjaxForm}
   */
  this.notifySuccess = function(message) {
    this.notifier.notifySuccess(message || 'Success.');

    this.onNotificationShown();

    return this;
  };

  /**
   * Notify validation error.
   *
   * @param  {string}   field
   * @param  {string}   message
   * @return {AjaxForm}
   */
  this.notifyValidationError = function(field, message) {
    this.notifier.notifyError(message);

    this.onNotificationShown();

    return this;
  };

  /**
   * Notify warning.
   *
   * @param  {string}   message
   * @return {AjaxForm}
   */
  this.notifyWarning = function(message) {
    this.notifier.notifyWarning(message);

    this.onNotificationShown();

    return this;
  };

  /**
   * Clear notifications.
   *
   * @return {AjaxForm}
   */
  this.clearNotifications = function() {
    this.notifier.clear();
    return this;
  };

  /**
   * Notification shown event handler.
   */
  this.onNotificationShown = function() {};

  /**
   * Enable submit button.
   *
   * @return {AjaxForm}
   */
  this.enableSubmitBtn = function() {
    $('input[type="submit"],button[type="submit"]', this.formEl)
      .prop('disabled', false)
      .removeClass('disable');

    return this;
  };

  /**
   * Disable submit button.
   *
   * @return {AjaxForm}
   */
  this.disableSubmitBtn = function() {
    $('input[type="submit"],button[type="submit"]', this.formEl)
      .addClass('disable');

    return this;
  };

  /**
   * Get form data.
   *
   * @param  {boolean} full
   * @param  {boolean} processed
   * @return {object}
   */
  this.getFormData = function(full, processed) {
    // eslint-disable-next-line no-param-reassign
    full = !_.isUndefined(full) ? full : false;
    // eslint-disable-next-line no-param-reassign
    processed = !_.isUndefined(processed) ? processed : false;

    let formData = this.getRawFormData();

    if (full) {
      this.prepareServerParams();
      formData = Object.extend(formData, this.options.serverParams);
    }

    if (processed) {
      formData = this.processFormData(formData);
    }

    return formData;
  };

  /**
   * Get raw form data.
   *
   * @return {object}
   */
  this.getRawFormData = function() {
    return this.formEl.serializeObject();
  };

  /**
   * Get form action URL.
   *
   * @return {string}
   */
  this.getActionUrl = function() {
    return this.formEl.attr('action');
  };

  /**
   * Get before submit message.
   *
   * @return {string|undefined}
   */
  this.getBeforeSubmitMessage = function() {
    return 'Processing...';
  };

  /**
   * Get after submit message.
   *
   * @param  {object}                response
   * @return {string|null|undefined}
   */
  // eslint-disable-next-line no-unused-vars
  this.getAfterSubmitMessage = function(response) {
    return 'Success.';
  };

  /**
   * Return form DOM element.
   *
   * @return {DOMElement}
   */
  this.getFormEl = function() {
    return this.formEl;
  };

  /**
   * @param  {string}   formPartGroup
   * @param  {string}   formPartName
   * @param  {object}   opts
   * @return {AjaxForm}
   */
  this.switchFormPart = function(formPartGroup, formPartName, opts) {
    const classPrefix = `formPart${formPartGroup.capitalize()}`;

    const allParts = this.formEl.find(`*[class^="${classPrefix}"]`);

    const visiblePart = this.formEl.find(
      `.${classPrefix}${formPartName.capitalize()}`,
    );

    allParts.hide();
    visiblePart.show();

    if (opts && opts.value) {
      visiblePart.find('.takeSelect2Value').val(opts.value);
    }

    this
      .setFormPartInputVisibility(allParts, visiblePart)
      .onFormPartActivated(formPartGroup, formPartName, opts);

    return this;
  };

  /**
   * Add all inputs from allCt to hiddenFields except of those contained
   * in visibleCt.
   *
   * @param  {DOMElement} allCt
   * @param  {DOMElement} visibleCt
   * @return {AjaxForm}
   */
  this.setFormPartInputVisibility = function(allCt, visibleCt) {
    const inputsSelector = 'input,select,textarea';

    const allFields = _.map(
      allCt.find(inputsSelector),
      (e) => $(e).attr('name'),
    );

    const shownFields = _.map(
      visibleCt.find(inputsSelector),
      (e) => $(e).attr('name'),
    );

    this.options.hiddenFields = _.difference(
      _.union(this.options.hiddenFields, allFields),
      shownFields,
    );

    return this;
  };

  /**
   * @param  {string}   formPartGroup
   * @param  {string}   formPartName
   * @param  {object}   opts
   * @return {AjaxForm}
   */
  // eslint-disable-next-line no-unused-vars
  this.onFormPartActivated = function(formPartGroup, formPartName, opts) {
    return this;
  };

  /**
   * Get form type.
   *
   * @return {string} ['form', 'form_group']
   */
  this.getFormType = function() {
    return 'form';
  };

  /**
   * Save form state.
   *
   * @return {AjaxForm}
   */
  this.saveState = function() {
    this.extendOptions({
      defaults: this.getFormData(false, false),
    });

    return this;
  };

  /**
   * On file uploaded event handler.
   *
   * @param  {object}  file
   */
  this.onFileUploaded = function(file) {
    this.attachmentList.addFile({
      file_id: file.id,
      file_name: file.name,
      file_mime: file.mime,
    });
  };

  /**
   * Reset form
   *
   * @return {AjaxForm}
   */
  this.reset = function() {
    this.formEl.find('input').val('');
    this.formEl.find('textarea').html('');

    if (this.attachmentList) {
      this.attachmentList.clear();
    }
  };

  /**
   * Submit form.
   *
   * @return {AjaxForm}
   */
  this.submit = function() {
    this.formEl.submit();
    return this;
  };

  /**
   * Get container.
   *
   * @return {DOMElement}
   */
  this.getContainer = function() {
    return this.formEl;
  };

  if (true !== inherited) {
    // Initialize
    this.init();
  }
}

export default AjaxForm;
