import AjaxForm from 'js/base_v2/ajax-form';
import Modal from 'js/components/modal';
import Notifier from 'js/components/notifier';

const staticSelf = ModalForm;

/**
 * @const
 */
staticSelf.APPLY_TO_ALL = 'apply_to_all';

/**
 * @const
 */
staticSelf.APPLY_TO_SAME_BASE = 'apply_to_same_base';

/**
 * @const
 */
staticSelf.APPLY_TO_SELECTED = 'apply_to_selected';

/**
 * Modal Form.
 *
 * @class
 * @extends AjaxForm
 *
 * @param {object}  [options]
 * @param {boolean} [inherited]
 */
function ModalForm(options, inherited) {
  AjaxForm.call(this, null, options, true);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {Modal}
   */
  this.modal = null;

  /**
   * @prop {DOMElement}
   */
  this.modalCt = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      focusFirstField: true,
      focusFirstNonEmptyField: false,
      tmplEl: undefined,
      tmplParams: {},
      switchButtons: [],
      defaults: {},
      onShow: undefined,
      onShown: undefined,
      onHide: undefined,
      onHidden: undefined,
      onClose: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    if (_.isString(this.options.tmplEl)) {
      this.options.tmplEl = $(this.options.tmplEl);
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    this
      .processOptions()
      .prepareServerParams()
      .prepareTmplParams()
      .createModal();

    this.formEl = $('form', this.modal.getContainer());
    this.initUploadOptions();

    // Create notifier
    this.notifier =
      new Notifier($('.notificationCt', this.formEl)).create();

    return this
      .initFields()
      .initMessages()
      .registerEventListeners()
      .createSubcomponents()
      .createUploadField()
      .createAttachmentList()
      .tryAutoSubmit();
  };

  /**
   * Show modal form.
   *
   * @return {BaseModalList}
   */
  this.show = function() {
    if ($(this.modal.ct).next('.modal-backdrop').length > 0) {
      $(this.modal.ct).detach();
    }

    this.modal.show();
    return this;
  };

  /**
   * Create form modal.
   *
   * @return {ModalForm}
   */
  this.createModal = function() {
    const modalCt = this.options.tmplEl.tmpl(this.options.tmplParams);

    this.modal = new Modal(modalCt, {
      onShow() {
        return self.onShow();
      },
      onShown() {
        return self.onShown();
      },
      onHide(destroyTriggered) {
        return self.onHide(destroyTriggered);
      },
      onHidden(destroyTriggered) {
        return self.onHidden(destroyTriggered);
      },
      onClose: () => {
        this.onClose();
      },
    }).create();

    this.modalCt = this.modal.getContainer();

    return this;
  };

  /**
   * Destroy modal form.
   *
   * @param  {number}    delay
   * @return {ModalForm}
   */
  this.destroy = function(delay) {
    this.modal.destroy(delay || 0);

    return this;
  };

  /**
   * Hide modal form.
   *
   * @return {BaseModalList}
   */
  this.hide = function() {
    this.modal.hide();
  };

  /**
   * Initialize form fields.
   *
   * @return {ModalForm}
   */
  this.initFields = function() {
    return this;
  };

  /**
   * Initialize messages.
   *
   * @return {ModalForm}
   */
  this.initMessages = function() {
    return this;
  };

  /**
   * Prepare template parameters.
   *
   * @return {ModalForm}
   */
  this.prepareTmplParams = function() {
    this.options = this.options.extend({
      tmplParams: {
        translator: this.translator,
        switchButtons: this.options.switchButtons,
        defaults: this.options.defaults,
        actionUrl: this.options.actionUrl,
      },
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    _.each(this.options.switchButtons, function(switchButton) {
      if (!_.isFunction(switchButton.callback)) {
        return;
      }

      const btnEl = `.switchTo-${switchButton.targetFormName}`;

      this.formEl.on('click', btnEl, function(e) {
        e.preventDefault();
        switchButton.callback(switchButton.targetFormName, $(this));
      });
    }, this);

    return this;
  };

  /**
   * @inheritDoc
   */
  this.afterSubmit = function(response) {
    const ret = parent.afterSubmit.call(this, response);

    if (false === ret) {
      return ret;
    }

    this.destroy(750);

    return undefined;
  };

  /**
   * Form show event handler.
   *
   * @return {boolean|undefined}
   */
  this.onShow = function() {
    if (_.isFunction(this.options.onShow)) {
      const ret = this.options.onShow();

      if (false === ret) {
        return ret;
      }
    }

    return undefined;
  };

  /**
   * Form shown event handler.
   *
   * @return {boolean|undefined}
   */
  this.onShown = function() {
    // Refresh all Select2 fields present on the modal form. If they are created
    // before the modal is shown they won't have correct width property.
    $('.select2-hidden-accessible', this.formEl).trigger('change.select2');

    if (_.isFunction(this.options.onShown) &&
      false === this.options.onShown()
    ) {
      return false;
    }

    // Initialize field focus
    this.initFieldFocus();

    return undefined;
  };

  /**
   * Form hide event handler.
   *
   * @param  {boolean}           destroyTriggered
   * @return {boolean|undefined}
   */
  this.onHide = function(destroyTriggered) {
    if (_.isFunction(this.options.onHide)) {
      const ret = this.options.onHide(destroyTriggered);

      if (false === ret) {
        return ret;
      }
    }

    return undefined;
  };

  /**
   * Form hidden event handler.
   *
   * @param  {boolean}           destroyTriggered
   * @return {boolean|undefined}
   */
  this.onHidden = function(destroyTriggered) {
    if (_.isFunction(this.options.onHidden)) {
      const ret = this.options.onHidden(destroyTriggered);

      if (false === ret) {
        return ret;
      }
    }

    return undefined;
  };

  /**
   * Form close event handler (i.e. close button clicked).
   */
  this.onClose = function() {
    if (_.isFunction(this.options.onClose)) {
      this.options.onClose();
    }
  };

  /**
   * @inheritDoc
  */
  this.onNotificationShown = function() {
    parent.onNotificationShown.call(this);

    this.scrollToTop();

    return this;
  };

  /**
   * Initialize field focus.
   *
   * @return {ModalForm}
   */
  this.initFieldFocus = function() {
    if (this.options.focusFirstNonEmptyField) {
      this.focusFirstNonEmptyField();
    } else if (this.options.focusFirstField) {
      this.focusFirstField();
    }

    return this;
  };

  /**
   * Focus first field.
   *
   * @return {ModalForm}
   */
  this.focusFirstField = function() {
    if (!this.options.focusFirstField) {
      return this;
    }

    $('input,select,textarea', this.formEl).each(function() {
      if ($(this).prop('disabled') || $(this).prop('readonly')) {
        return undefined;
      }

      $(this).focus();
      return false;
    });

    return this;
  };

  /**
   * Focus first non-empty field.
   *
   * @return {ModalForm}
   */
  this.focusFirstNonEmptyField = function() {
    if (!this.options.focusFirstNonEmptyField) {
      return this;
    }

    $('input,select,textarea', this.formEl).each(function() {
      if ($(this).prop('disabled') ||
        $(this).prop('readonly') ||
        $(this).hasClass('ignoreFocusFirstNonEmpty') ||
        $(this).val()
      ) {
        return undefined;
      }

      $(this).focus();
      return false;
    });

    return this;
  };

  /**
   * Scroll modal (form) to top.
   *
   * @return {ModalForm}
   */
  this.scrollToTop = function() {
    this.getContentContainer().scrollTop(0);

    return this;
  };

  /**
   * Get content container.
   *
   * @return {DOMElement}
   */
  this.getContentContainer = function() {
    return $('.modal-content', this.modal.getContainer());
  };

  if (true !== inherited) {
    // Initialize
    this.init();
  }
}

export default ModalForm;
