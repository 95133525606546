import BaseComponent from 'js/base_v2/component';
import TextFilter from 'js/components/text-filter';
import { translator } from 'js/translator';

/**
 * Filter Group.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {DOMElement} filterGroupCt
 * @param {object}     [options]
 */
function BaseFilterGroup(filterGroupCt, options) {
  BaseComponent.call(this, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {DOMElement}
   */
  this.filterGroupCt = filterGroupCt;

  /**
   * @prop {object[]}
   */
  this.filterParams = [];

  /**
   * @prop {object}
   */
  this.filters = {};

  /**
   * @prop {function[]}
   */
  this.listeners = [];

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      showResetButton: false,
      triggerDisabled: false,
      defaultValues: {},
      initialValues: {},
      onResetAllBtnClick: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    return this
      .processDefaultValueOptions()
      .processInitialValueOptions();
  };

  /**
   * Process default value options.
   *
   * @return {BaseFilterGroup}
   */
  this.processDefaultValueOptions = function() {
    _.each(this.filterParams, (params) => {
      const filterName = params.name;

      const filterFieldName = Object.get(
        this.options[filterName],
        'name',
      );

      if (!_.isString(filterFieldName) || _.isEmpty(filterFieldName)) {
        return;
      }

      const filterDefaultValue = this.options.defaultValues[filterFieldName];

      if (_.isUndefined(filterDefaultValue)) {
        return;
      }

      switch (params.type) {
        case 'filter_group':
          this.extendOptions({
            [filterName]: {
              defaultValues: filterDefaultValue,
            },
          });

          break;
        case 'filter':
        default:
          this.extendOptions({
            [filterName]: {
              defaultValue: filterDefaultValue,
            },
          });
      }
    });

    return this;
  };

  /**
   * Process initial value options.
   *
   * @return {BaseFilterGroup}
   */
  this.processInitialValueOptions = function() {
    _.each(this.filterParams, function(params) {
      const filterName = params.name;

      const filterFieldName = Object.get(
        this.options[filterName],
        'name',
      );

      if (!_.isString(filterFieldName) || _.isEmpty(filterFieldName)) {
        return;
      }

      const filterInitialValue = Object.get(
        this.options.initialValues,
        filterFieldName,
      );

      if (_.isUndefined(filterInitialValue)) {
        return;
      }

      switch (params.type) {
        case 'filter_group':
          this.extendOptions(Object.createFromKey(
            filterName,
            { initialValues: filterInitialValue },
          ));

          break;
        case 'filter':
        default:
          this.extendOptions(Object.createFromKey(
            filterName,
            { initialValue: filterInitialValue },
          ));
      }
    }, this);

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    if (_.isString(this.filterGroupCt)) {
      this.filterGroupCt = $(this.filterGroupCt);
    }

    this
      .createResetButton()
      .createFilters()
      .registerEventListeners();

    return this;
  };

  /**
   * Register event listeners.
   *
   * @return {BaseFilterGroup}
   */
  this.registerEventListeners = function() {
    _.each(this.filters, (f) => {
      f.addListener((filter) => {
        self.trigger(filter);
      });
    }, this);

    this.filterGroupCt.on('click', '.clearAll', (e) => {
      self.onClearAllBtnClick(e);
    });

    this.filterGroupCt.on('click', '.resetAll', (event) => {
      this.onResetAllBtnClick(event);
    });

    return this;
  };

  /**
   * On clear all button click event handler.
   *
   * @param {Event} e
   */
  this.onClearAllBtnClick = function(e) {
    e.preventDefault();
    this.clearAllFilters();
  };

  /**
   * On reset all filters button click event handler.
   *
   * @param  {Event}             event
   * @return {boolean|undefined}
   */
  this.onResetAllBtnClick = function(event) {
    event.preventDefault();

    if (_.isFunction(this.options.onResetAllBtnClick) &&
      false === this.options.onResetAllBtnClick()
    ) {
      return false;
    }

    this.resetAllFilters();

    return undefined;
  };

  /**
   * Clear all filters.
   *
   * @return {BaseFilterGroup}
   */
  this.clearAllFilters = function() {
    _.each(this.filters, (filter) => {
      filter.clear();
    }, this);

    return this;
  };

  /**
   * Reset all filters.
   *
   * @return {BaseFilterGroup}
   */
  this.resetAllFilters = function() {
    let lastFilter = null;

    this.options.triggerDisabled = true;

    _.each(this.filters, (filter) => {
      lastFilter = filter.reset();
    });

    this.options.triggerDisabled = false;

    if (_.isObject(lastFilter)) {
      // Trigger filter change only once at the end
      lastFilter.trigger();
    }

    return this;
  };

  /**
   * Create reset filters button.
   *
   * @return {BaseFilterGroup}
   */
  this.createResetButton = function() {
    if (!this.options.showResetButton) {
      return this;
    }

    if ($('a.resetAll', this.filterGroupCt).length > 0) {
      return this;
    }

    /* eslint-disable max-len */
    this.filterGroupCt.prepend(`
      <div class="reset-filters-btn-wrapper wrap_tool border_tools other_tools">
        <div class="wrap_tooltip">
          <a href="#" class="resetAll"><svg><use xlink:href="#icon-undo"></use></svg></a>
          <span class="desc_tooltip">${translator.getTitle('label_reset_filters')}</span>
        </div>
      </div>
    `);
    /* eslint-enable max-len */

    return this;
  };

  /**
   * Create filters.
   *
   * @return {BaseFilterGroup}
   */
  this.createFilters = function() {
    _.each(this.filterParams, function(params) {
      switch (params.type) {
        case 'filter_group':
          this.createFilterGroup(params);
          break;
        case 'filter':
        default:
          this.createFilter(params);
          break;
      }
    }, this);

    return this;
  };

  /**
   * Create filter.
   *
   * @param  {object}          params
   * @return {BaseFilterGroup}
   */
  this.createFilter = function(params) {
    const filterEl = $(params.filterEl, this.filterGroupCt);

    if (0 === filterEl.length) {
      return this;
    }

    this.filters[params.name] = new params.className(
      filterEl,
      this.options[params.name],
    ).create();

    return this;
  };

  /**
   * Create filter group.
   *
   * @param  {object}          params
   * @return {BaseFilterGroup}
   */
  this.createFilterGroup = function(params) {
    this.filters[params.name] = new params.className(
      $(params.filterGroupCt, this.filterGroupCt),
      this.options[params.name],
    ).create();

    return this;
  };

  /**
   * Get filter values.
   *
   * @return {object}
   */
  this.getValues = function() {
    const values = {};

    _.each(this.filters, (f) => {
      if (f.isEnabled()) {
        values[f.getName()] = f.getValue();
      }
    }, this);

    return values;
  };

  /**
   * Get filter full values.
   *
   * @return {object}
   */
  this.getFullValues = function() {
    const fullValues = {};

    _.each(this.filters, (f) => {
      if (f.isEnabled()) {
        fullValues[f.getName()] = f.getFullValue();
      }
    }, this);

    return fullValues;
  };

  /**
   * Add listener.
   *
   * @param  {function}        listener
   * @return {BaseFilterGroup}
   */
  this.addListener = function(listener) {
    this.listeners.push(listener);
    return this;
  };

  /**
   * Trigger filters change.
   *
   * @param  {BaseFilter}      sender
   * @return {BaseFilterGroup}
   */
  this.trigger = function(sender) {
    if (this.options.triggerDisabled) {
      return this;
    }

    _.each(this.listeners, function(l) {
      l(this, sender);
    }, this);

    this.updateFiltersState();

    return this;
  };

  /**
   * Update filters state.
   *
   * @return {BaseFilterGroup}
   */
  this.updateFiltersState = function() {
    _.each(this.filters, (filter) => {
      if (filter instanceof TextFilter) {
        filter.updateFilterButtonsState();
      }
    });

    return this;
  };

  /**
   * Get filter group container.
   *
   * @return {DOMElement}
   */
  this.getContainer = function() {
    return this.filterGroupCt;
  };
}

export default BaseFilterGroup;
