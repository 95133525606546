import BaseComponent from 'js/base_v2/component';
import Modal         from 'js/components/modal';

var staticSelf = ModalAlert;

/**
 * @const
 */
staticSelf.DESTROY_DELAY = 750;

/**
 * Modal Alert.
 *
 * @class
 * @extends BaseComponent
 *
 * @param {object}  options
 * @param {boolean} inherited
 */
function ModalAlert(options, inherited) {
    BaseComponent.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {Modal}
     */
    this.modal = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.defaultOptions = Object.extend(this.defaultOptions, {
            message: undefined,
            messageType: 'info',
            tmplEl: '#modal-alert',
            tmplParams: {},
            modal: {},
            onCancel: undefined
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.create = function() {
        parent.create.call(this);

        return this
            .prepareTmplParams()
            .createModal()
            .createComponents()
            .registerEventListeners();
    };

    /**
     * @inheritDoc
     */
    this.destroyNow = function() {
        parent.destroyNow.call(this);
        this.modal.destroy();

        return this;
    };

    /**
     * Prepare template parameters.
     *
     * @return {ModalAlert}
     */
    this.prepareTmplParams = function() {
        this.options.tmplParams = Object.extend(this.options.tmplParams, {
            title: this.getTitle(),
            message: this.getMessage(),
            messageType: this.options.messageType,
            cancelBtnLabel: 'Close'
        });

        return this;
    };

    /**
     * Create modal.
     *
     * @return {ModalConfirmation}
     */
    this.createModal = function() {
        var modalCt = $(this.options.tmplEl).tmpl(this.options.tmplParams);

        this.modal = new Modal(modalCt, this.options.modal).create();
        this.modalCt = this.modal.getContainer();

        return this;
    };

    /**
     * Create components.
     *
     * @return {ModalConfirmation}
     */
    this.createComponents = function() {
        return this;
    };

    /**
     * Register event listeners.
     *
     * @return {ModalConfirmation}
     */
    this.registerEventListeners = function() {
        var modalCt = this.modal.getContainer();

        modalCt.on('click', '.cancel', function(e) {
            e.preventDefault();
            self.onCancel();
        });

        return this;
    };

    /**
     * Cancel event handler.
     *
     * @return {boolean|undefined}
     */
    this.onCancel = function() {
        if (_.isFunction(this.options.onCancel)) {
            var ret = this.options.onCancel();

            if (false === ret) {
                return ret;
            }
        }

        this.destroy();

        return undefined;
    };

    /**
     * Get title.
     *
     * @return {string}
     */
    this.getTitle = function() {
        return this.options.messageType.capitalizeWords();
    };

    /**
     * Get message.
     *
     * @return {string}
     */
    this.getMessage = function() {
        return this.options.message;
    };

    if (true !== inherited) {
        this.init();
    }
}

export default ModalAlert;
