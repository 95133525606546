import BaseConfig from 'js/base_v3/configs/config';
import Translator from 'js/translator';

/**
 * Base Entity Config.
 *
 * @class
 * @abstract
 * @extends BaseConfig
 *
 * @param {object} [options]
 */
function BaseEntityConfig(options) {
  BaseConfig.call(this, options);
  const parent = this.clone();

  /**
   * @protected
   *
   * @prop {Translator}
   */
  this._translator = null;

  /**
   * @inheritDoc
   */
  this._initEarlyProps = function() {
    parent._initEarlyProps.call(this);

    this._translator = new Translator();

    return this;
  };

  /**
   * @inheritDoc
   */
  this._initDefaults = function() {
    parent._initDefaults.call(this);

    return this._extendDefaultOptions({
      entityName: undefined,
      entitiesName: undefined,
      entityLabel: undefined,
      entitiesLabel: undefined,
      entityClass: undefined,
      tableClass: undefined,
      detailsClass: undefined,
      rendererClass: undefined,
      listClass: undefined,
      listUrl: undefined,
      saveFormClass: undefined,
      saveFormGroupClass: undefined,
      saveUrl: undefined,
      saveFormTemplateSelector: undefined,
      removeConfirmationClass: undefined,
      removeUrl: undefined,
      selectionPanelClass: undefined,
      selectionPanelTemplateId: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this._processOptions = function() {
    parent._processOptions.call(this);

    return this
      ._processEntitiesNameOption()
      ._processEntityLabelOption()
      ._processEntitiesLabelOption();
  };

  /**
   * Process entities name option.
   *
   * @protected
   *
   * @return {BaseEntityConfig}
   */
  this._processEntitiesNameOption = function() {
    if (_.isString(this._options.entitiesName) ||
      _.isEmpty(this._options.entityName)
    ) {
      // Nothing to process
      return this;
    }

    return this.extendOptions({
      entitiesName: `${this._options.entityName}s`,
    });
  };

  /**
   * Process entity label option.
   *
   * @protected
   *
   * @return {BaseEntityConfig}
   */
  this._processEntityLabelOption = function() {
    if (_.isString(this._options.entityLabel) ||
      _.isEmpty(this._options.entityName)
    ) {
      // Nothing to process
      return this;
    }

    return this.extendOptions({
      entityLabel: this._translator.get(this._options.entityName),
    });
  };

  /**
   * Process entities label option.
   *
   * @protected
   *
   * @return {BaseEntityConfig}
   */
  this._processEntitiesLabelOption = function() {
    if (_.isString(this._options.entitiesLabel) ||
      _.isEmpty(this._options.entityLabel)
    ) {
      // Nothing to process
      return this;
    }

    return this.extendOptions({
      entitiesLabel: `${this._options.entityLabel}s`,
    });
  };
}

export default BaseEntityConfig;
