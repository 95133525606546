import BaseEntity from 'js/base_v2/models/entity';

const staticSelf = CorporateUser;

/**
 * @const
 */
staticSelf.STATUS_ACTIVE = 'active';

/**
 * @const
 */
staticSelf.STATUS_INACTIVE = 'inactive';

/**
 * @const
 */
staticSelf.STATUS_INVITED = 'invited';

/**
 * Corporate User Entity.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {object} [data]
 */
function CorporateUser(data) {
  BaseEntity.call(this, data);

  /**
   * Get short name.
   *
   * @return {string}
   */
  this.getShortName = function() {
    let pieces = this.name.split(' ');

    pieces = pieces.map((piece, index) => {
      if (0 === index) {
        return piece;
      }

      return piece.charAt(0);
    });

    return pieces.join(' ');
  };

  // Initialize
  this.init();
}

export default CorporateUser;
