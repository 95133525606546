import BaseComponent from 'js/base_v2/component';
import Renderer from 'js/components/renderer';
import PriceHelper from 'js/helpers/price-helper';

export default function BaseTable(tableEl, options) {
  BaseComponent.call(this, options, true);

  /**
   * @const
   */
  this.PART_DESCRIPTION_LENGTH = 60;

  /**
   * @const
   */
  this.TYPE_RECORD = '__record__';

  /**
   * @const
   */
  this.TYPE_GROUP = '__group__';

  /**
   * @prop {DOMElement}
   */
  this.tableEl = tableEl;

  /**
   * Get table DOM element.
   *
   * @return {DOMElement}
   */
  this.getTableEl = function() {
    return this.tableEl;
  };

  /**
   * Render big price.
   *
   * @param  {number} amount
   * @param  {string} className
   * @return {string}
   */
  this.renderBigPrice = function(amount, className) {
    return this.renderText(Renderer.BigPrice(amount), className);
  };

  /**
   * Render big price and GPM.
   *
   * @param  {number} price
   * @param  {number} cost
   * @param  {string} className
   * @return {string}
   */
  this.renderBigPriceAndGpm = function(price, cost) {
    const gpm = PriceHelper.computeGpm(cost, price);

    return this.renderBigPrice(price) +
            this.renderPercentage(gpm, 'percent');
  };

  /**
   * Render checkbox.
   *
   * @param  {?string} className
   * @return {string}
   */
  this.renderCheckbox = function(className = 'selectSingle') {
    return `<label class="${className} custom-control custom-checkbox">` +
            '<span class="custom-control-indicator"></span>' +
        '</label>';
  };

  /**
   * Render city and state.
   *
   * @param  {object} address
   * @param  {string} className
   * @return {string}
   */
  this.renderCityAndState = function(address, className) {
    return this.renderText(Renderer.CityAndState(address), className);
  };

  /**
   * Render date.
   *
   * @param  {string} dateString
   * @param  {string} className
   * @return {string}
   */
  this.renderDate = function(dateString, className) {
    return this.renderText(
      Renderer.DatePickerFormat(dateString),
      className,
    );
  };

  /**
   * Render email.
   *
   * @param  {string} email
   * @param  {string} className
   * @return {string}
   */
  this.renderEmail = function(email, className = '') {
    return this.renderText(Renderer.Email(email), `${className} email`);
  };

  /**
   * Render location and city.
   *
   * @param  {object} address
   * @param  {string} className
   * @return {string}
   */
  this.renderLocationAndCity = function(address, className) {
    return this.renderText(
      Renderer.LocationAndCity(address),
      className,
    );
  };

  /**
   * Render percentage.
   *
   * @param  {number} value
   * @param  {string} className
   * @return {string}
   */
  this.renderPercentage = function(value, className) {
    return this.renderText(Renderer.Percentage(value), className);
  };

  /**
   * Render phone.
   *
   * @param  {string} phone
   * @param  {string} className
   * @return {string}
   */
  this.renderPhone = function(phone, className) {
    return this.renderText(Renderer.Phone(phone), className);
  };

  /**
   * @param {string} link
   * @param {string} label
   * @param {string} className
   * @return {string}
   */
  this.renderLink = function(link, label, className = '') {
    return `<a class="${className}" + href="${link}">${label}</a>`;
  };

  /**
   * @param {string} text
   * @param {string} url
   * @param {string} className
   * @returns {string}
   */
  this.renderLinkText = function(text, url, className = '') {
    return this.renderText(Renderer.LinkText(text, url), className);
  };

  /**
   * Render part description.
   *
   * @param  {string} description
   * @return {string}
   */
  this.renderPartDescription = function(description) {
    return this.renderText(Renderer.Long(this.PART_DESCRIPTION_LENGTH)(
      description || '-',
    ));
  };

  /**
   * Render part number.
   *
   * @param  {string} partNumber
   * @return {string}
   */
  this.renderPartNumber = function(partNumber) {
    return this.renderText(Renderer.PartNumber(partNumber));
  };

  /**
   * Render price.
   *
   * @param  {number} amount
   * @param  {string} className
   * @return {string}
   */
  this.renderPrice = function(amount, className) {
    return this.renderText(Renderer.Price(amount), className);
  };

  /**
   * Render price and GPM.
   *
   * @param  {number} price
   * @param  {number} cost
   * @return {string}
   */
  this.renderPriceAndGpm = function(price, cost) {
    const gpm = PriceHelper.computeGpm(cost, price);

    return this.renderPrice(price) +
            this.renderPercentage(gpm, 'percent');
  };

  /**
   * Render quantity.
   *
   * @param  {number} amount
   * @param  {string} className
   * @return {string}
   */
  this.renderQuantity = function(amount, className) {
    return this.renderText(Renderer.Quantity(amount), className);
  };

  /**
   * Render quantity per measure.
   *
   * @param  {number} amount
   * @param  {string} className
   * @return {string}
   */
  this.renderQuantityPerMeasure = function(amount, className) {
    return this.renderText(
      Renderer.QuantityPerMeasure(amount),
      className,
    );
  };

  /**
     * Render reorder button.
     *
     * @return {string}
     */
  this.renderReorderBtn = function() {
    return '<svg class="reorderHandle reorder-btn">' +
        '<use xlink:href="#icon-ellipsis-v"></use>' +
      '</svg>';
  };

  /**
   * Render text.
   *
   * @param  {string} text
   * @param  {string} className
   * @return {string}
   */
  this.renderText = function(text, className = '') {
    return `<span class="${className}">${text}</span>`;
  };

  /**
   * Render toggle button.
   *
   * @param  {boolean} value
   * @param  {boolean} disabled
   * @return {string}
   */
  this.renderToggleBtn = function(value, disabled) {
    const disabledState = !_.isUndefined(disabled) ? disabled : false;

    return `<label class="btn_toogle${disabledState ? ' disable' : ''}">` +
            `<input type="checkbox"${value ? ' checked' : ''}>` +
            '<span class="c-indicator"></span>' +
            '</label>';
  };

  /**
   * Render UOM and quantity per measure.
   *
   * @param  {string} uom
   * @param  {number} qpm
   * @param  {string} className
   * @return {string}
   */
  this.renderUomAndQpm = function(uom, qpm, className) {
    let html = uom;

    if (qpm > 0) {
      html += ` / ${Renderer.QuantityPerMeasure(qpm)}`;
    }

    return this.renderText(html, className);
  };
}
