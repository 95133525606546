/* eslint-disable */
import BaseObject      from 'js/base_v3/objects/object';
import DefaultNotifier from 'js/components_v2/default-notifier';
import Translator      from 'js/translator';

/**
 * Base Component.
 *
 * @class
 * @abstract
 * @extends BaseObject
 *
 * @param {object} [options]
 */
function BaseComponent(options) {
    BaseObject.call(this, options);
    const parent = this.clone();
    const self = this;

    /**
     * @protected
     *
     * @prop {Translator}
     */
    this._translator = null;

    /**
     * @protected
     *
     * @prop {DefaultNotifier}
     */
    this._notifier = null;

    /**
     * @inheritDoc
     */
    this._initDefaults = function() {
        parent._initDefaults.call(this);

        return this._extendDefaultOptions({
            notifier: {}
        });
    };

    /**
     * Create component and subcomponents.
     *
     * @return {BaseComponent}
     */
    this.create = function() {
        return this
            ._render()
            ._create()
            ._registerEventListeners()
            ._createSubcomponents();
    };

    /**
     * Recreate component.
     *
     * @param  {number}        [destroyDelay]
     * @return {BaseComponent}
     */
    this.recreate = function(destroyDelay) {
        return this
            .destroy(destroyDelay)
            .create();
    };

    /**
     * Destroy component.
     *
     * @param  {number}        [delay]
     * @return {BaseComponent}
     */
    this.destroy = function(delay = 0) {
        if (0 === delay) {
            this._destroyNow();
            return this;
        }

        setTimeout(function() {
            self._destroyNow();
        }, delay);

        return this;
    };

    /**
     * @inheritDoc
     */
    this._initEarlyProps = function() {
        parent._initEarlyProps.call(this);

        this._translator = new Translator();

        return this;
    };

    /**
     * Render component.
     *
     * @protected
     *
     * @return {BaseComponent}
     */
    this._render = function() {
        return this;
    };

    /**
     * Create component.
     *
     * @protected
     *
     * @return {BaseComponent}
     */
    this._create = function() {
        return this;
    };

    /**
     * Register event listeners.
     *
     * @protected
     *
     * @return {BaseComponent}
     */
    this._registerEventListeners = function() {
        return this;
    };

    /**
     * Create subcomponents.
     *
     * @protected
     *
     * @return {BaseComponent}
     */
    this._createSubcomponents = function() {
        this._notifier = new DefaultNotifier(
            this._options.notifier
        ).create();

        return this;
    };

    /**
     * Destroy component now.
     *
     * @protected
     *
     * @return {BaseComponent}
     */
    this._destroyNow = function() {
        return this;
    };
}

export default BaseComponent;
