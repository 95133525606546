import BaseComponent from 'js/base_v2/component';

/**
 * Base Field Group.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {DOMElement} fieldGroupCt
 * @param {object}     [options]
 */
function BaseFieldGroup(fieldGroupCt, options) {
    BaseComponent.call(this, options);
    const parent = this.clone();
    const self = this;

    /**
     * @prop {DOMElement}
     */
    this.fieldGroupCt = fieldGroupCt;

    /**
     * @prop {object[]}
     */
    this.fieldParams = [];

    /**
     * @prop {object}
     */
    this.fields = {};

    /**
     * @inheritDoc
     */
    this.init = function() {
        parent.init.call(this);

        return this.initFields();
    };

    /**
     * @inheritDoc
     */
    this.create = function() {
        parent.create.call(this);

        return this
            .createFields()
            .registerEventListeners();
    };

    /**
     * Initialize fields.
     *
     * @return {BaseFieldGroup}
     */
    this.initFields = function() {
        if (_.isString(this.fieldGroupCt)) {
            this.fieldGroupCt = $(this.fieldGroupCt);
        }

        _.each(this.fieldParams, (params) => {
            switch (params.type) {
                case 'field_group':
                    this.initFieldGroup(params);
                    break;
                case 'field':
                default:
                    this.initField(params);
            }
        });

        return this;
    };

    /**
     * Initialize field.
     *
     * @param  {object}         params
     * @return {BaseFieldGroup}
     */
    this.initField = function(params) {
        let fieldCt = params.fieldCt || this.fieldGroupCt;

        if (_.isString(fieldCt)) {
            fieldCt = $(fieldCt, this.fieldGroupCt);
        }

        this.fields[params.name] = new params.className(
            fieldCt,
            params.fieldEl,
            this.options[params.name]
        );

        return this;
    };

    /**
     * Initialize field group.
     *
     * @param  {object}         params
     * @return {BaseFieldGroup}
     */
    this.initFieldGroup = function(params) {
        this.fields[params.name] = new params.className(
            params.fieldCt || this.fieldGroupCt,
            this.options[params.name]
        );

        return this;
    };

    /**
     * Create fields.
     *
     * @return {BaseFieldGroup}
     */
    this.createFields = function() {
        _.each(this.fields, (field) => {
            field.create();
        });

        return this;
    };

    /**
     * Register event listeners.
     *
     * @return {BaseFieldGroup}
     */
    this.registerEventListeners = function() {
        return this;
    };
}

export default BaseFieldGroup;
