import BaseHelper from 'js/base_v3/helpers/helper';
import { cliJobChecker } from 'js/cli_v2/helpers/job-checker';
import CliJob from 'js/cli_v2/models/job';
import Ajax from 'js/components/ajax';
import { entityHelper } from 'js/helpers/entity-helper';
import { translator } from 'js/translator';

/**
 * CLI AJAX Helper.
 *
 * @class
 * @extends BaseHelper
 *
 * @param {object} [options]
 */
function CliAjax(options) {
  BaseHelper.call(this, options);

  /**
   * Make an AJAX request and then check the CLI job status. Success callback
   * is called when the CLI job finished successfully, error callback in case of
   * an error.
   *
   * @param  {string}         type
   * @param  {string}         url
   * @param  {object}         data
   * @param  {function}       successCallback
   * @param  {function}       errorCallback
   * @param  {object}         [requestOptions]
   * @return {XMLHttpRequest}
   */
  this.request = function(
    type,
    url,
    data,
    successCallback,
    errorCallback,
    requestOptions = {},
  ) {
    return Ajax.request(
      type,
      url,
      data,
      (response) => {
        cliJobChecker.check(
          entityHelper.get(response.cli_job, CliJob), // CLI job
          2500, // Interval milliseconds
          (cliJob) => {
            successCallback(cliJob);
          }, // Finished callback
          (cliJob) => {
            // eslint-disable-next-line max-len
            errorCallback(cliJob.status_description || translator.get('error_server_error'));
          }, // Stopped callback
          null, // Check callback
          {
            instantCheck: true,
            instantCheckDelayMillisec: 500,
          }, // Check options
        );
      },
      (response, error) => {
        errorCallback(error);
      },
      requestOptions,
    );
  };

  /**
   * Make a GET AJAX request and then check the CLI job status.
   *
   * @param  {string}         url
   * @param  {object}         data
   * @param  {function}       successCallback
   * @param  {function}       errorCallback
   * @param  {object}         [requestOptions]
   * @return {XMLHttpRequest}
   */
  this.get = function(
    url,
    data,
    successCallback,
    errorCallback,
    requestOptions = {},
  ) {
    return this.request(
      'GET',
      url,
      data,
      successCallback,
      errorCallback,
      requestOptions,
    );
  };

  /**
   * Make a POST AJAX request and then check the CLI job status.
   *
   * @param  {string}         url
   * @param  {object}         data
   * @param  {function}       successCallback
   * @param  {function}       errorCallback
   * @param  {object}         [requestOptions]
   * @return {XMLHttpRequest}
   */
  this.post = function(
    url,
    data,
    successCallback,
    errorCallback,
    requestOptions = {},
  ) {
    return this.request(
      'POST',
      url,
      data,
      successCallback,
      errorCallback,
      requestOptions,
    );
  };

  // Initialize
  this._init();
}

export const cliAjax = new CliAjax();

export default CliAjax;
