import ModalConfirmation from 'js/components_v2/modal-confirmation';
import Notifier from 'js/components/notifier';
import Ajax from 'js/components/ajax';

const staticSelf = BaseActionConfirmation;

/**
 * @const
 */
staticSelf.DESTROY_DELAY = ModalConfirmation.DESTROY_DELAY;

/**
 * Base Action Confirmation.
 *
 * @class
 * @abstract
 * @extends ModalConfirmation
 *
 * @param {object|object[]} records
 * @param {object}          [options]
 */
function BaseActionConfirmation(records, options) {
  ModalConfirmation.call(this, options, true);
  const parent = this.clone();

  /**
   * @prop {object[]}
   */
  this.records = _.isArray(records) ? records : [records];

  /**
   * @prop {object}
   */
  this.recordFilters = null;

  /**
   * @prop {?number}
   */
  this.recordsCount = null;

  /**
   * @prop {Notifier}
   */
  this.notifier = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'record',
      entityLabel: 'record',
      entityIdField: 'id',
      entityNameField: 'name',
      entitiesLabel: undefined,
      actionLabel: 'apply this action',
      actionProcessingLabel: 'loading',
      actionFinishedLabel: 'completed',
      actionUrl: undefined,
      messagePrefix: 'are you sure you want to',
      messageSuffix: undefined,
      actionBtnClassName: undefined,
      actionBtnLabel: undefined,
      serverParams: {},
      includeCountInMessage: true,
      onAction: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    if (!_.isString(this.options.entitiesLabel) &&
      _.isString(this.options.entityLabel)
    ) {
      this.options.entitiesLabel = `${this.options.entityLabel}s`;
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.createComponents = function() {
    parent.createComponents.call(this);

    this.notifier = new Notifier(
      $('.notificationCt', this.modal.getContainer()),
    ).create();

    return this;
  };

  /**
   * Prepare server parameters.
   *
   * @return {BaseActionConfirmation}
   */
  this.prepareServerParams = function() {
    if (_.isObject(this.recordFilters)) {
      return this.extendOptions({
        serverParams: this.recordFilters,
      });
    }

    return this.extendOptions({
      serverParams: {
        ids: this.getRecordIds(),
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.prepareTmplParams = function() {
    parent.prepareTmplParams.call(this);

    return this.extendOptions({
      tmplParams: {
        confirmBtnClassName: this.options.actionBtnClassName,
        confirmBtnLabel: this.options.actionBtnLabel.capitalize() || 'Confirm',
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.onConfirm = function(ct) {
    if (false === parent.onConfirm.call(this, ct)) {
      return false;
    }

    $('.confirm, .cancel', this.modal.getContainer()).attr('disabled', true);

    this.notifier.notifyLoading(this.getProcessingMessage());

    this.prepareServerParams();

    Ajax.post(
      this.options.actionUrl,
      this.options.serverParams,
      (response) => {
        this.onActionSuccess(response);
      },
      (response, error) => {
        this.onActionError(response, error);
      },
    );

    return undefined;
  };

  /**
   * Action success event handler.
   *
   * @param  {object}            response
   * @return {boolean|undefined}
   */
  this.onActionSuccess = function(response) {
    if (_.isFunction(this.options.onAction) &&
      false === this.options.onAction(response)
    ) {
      return false;
    }

    this.notifier.notifySuccess(this.getActionFinishedMessage());

    this.destroy(staticSelf.DESTROY_DELAY);

    return undefined;
  };

  /**
   * Action error event handler.
   *
   * @param {object} response
   * @param {string} error
   */
  this.onActionError = function(response, error) {
    this.notifier.notifyError(error);

    const modalCt = this.modal.getContainer();

    $('.confirm', modalCt)
      .html('Try Again')
      .attr('disabled', false);

    $('.cancel', modalCt).attr('disabled', false);
  };

  /**
   * Get (first) record.
   *
   * @return {?object}
   */
  this.getRecord = function() {
    return _.isObject(this.records[0]) ? this.records[0] : null;
  };

  /**
   * Get record IDs.
   *
   * @return {number[]}
   */
  this.getRecordIds = function() {
    return this.records.map((record) => record[this.options.entityIdField]);
  };

  /**
   * @inheritDoc
   */
  this.getTitle = function() {
    return [
      // E.g. "Accept"
      `${this.options.actionLabel.capitalize()}`,
      // E.g. "parts"
      `${this.getRecordsLabel().capitalizeWords()}`,
    ].join(' ');
  };

  /**
   * @inheritDoc
   */
  this.getMessage = function() {
    const messagePieces = [];

    // E.g. "Are you sure you want to"
    messagePieces.push(`${this.options.messagePrefix.capitalize()}`);

    // E.g. "accept"
    messagePieces.push(`${this.options.actionLabel.toLowerCase()}`);

    if (this.options.includeCountInMessage) {
      const count = this.getRecordsCount();

      if (_.isNumber(count) && !this.isSingleRecordNotFilteredConfirmation()) {
        // E.g. "100"
        messagePieces.push(`${count}`);
      } else {
        messagePieces.push('the');
      }
    }

    // E.g. "parts"
    messagePieces.push(`${this.getMessageRecordsLabel()}`);

    if (!_.isEmpty(this.options.messageSuffix)) {
      // E.g. "into the system"
      messagePieces.push(`${this.options.messageSuffix}`);
    }

    return `${messagePieces.join(' ')}?`;
  };

  /**
   * Get processing message.
   *
   * @return {string}
   */
  this.getProcessingMessage = function() {
    return [
      // E.g. "Accepting"
      `${this.options.actionProcessingLabel.capitalize()}`,
      // E.g. "parts..."
      `${this.getRecordsLabel()}...`,
    ].join(' ');
  };

  /**
   * Get action finished message.
   *
   * @param {string}
   */
  this.getActionFinishedMessage = function() {
    return [
      // E.g. "Parts"
      `${this.getRecordsLabel().capitalize()}`,
      // E.g. "have been"
      `${1 === this.getRecordsCount() ? 'has' : 'have'} been successfully`,
      // E.g. "accepted."
      `${this.options.actionFinishedLabel}.`,
    ].join(' ');
  };

  /**
   * Get records label.
   *
   * @return {string}
   */
  this.getRecordsLabel = function() {
    return 1 === this.getRecordsCount() ?
      this.options.entityLabel :
      this.options.entitiesLabel;
  };

  /**
   * Get message records label.
   *
   * @return {string}
   */
  this.getMessageRecordsLabel = function() {
    if (this.isSingleRecordNotFilteredConfirmation()) {
      return this.getMessageSingleRecordLabel();
    }

    return this.getRecordsLabel();
  };

  /**
   * Get message single record label.
   *
   * @return {string}
   */
  this.getMessageSingleRecordLabel = function() {
    const record = this.records[0];
    const recordName = this.getRecordName(record);

    return this.getRecordsLabel() + (recordName ? ` <b>${recordName}</b>` : '');
  };

  /**
   * Get record name.
   *
   * @param  {object}      record
   * @return {string|null}
   */
  this.getRecordName = function(record) {
    if (_.isFunction(record.getName)) {
      return record.getName();
    }

    return Object.get(record, this.options.entityNameField);
  };

  /**
   * Get records count.
   *
   * @return {?number}
   */
  this.getRecordsCount = function() {
    return this.recordsCount || this.records.length || null;
  };

  /**
   * Determine whether it's a single record (but not filtered) confirmation.
   *
   * @return boolean
   */
  this.isSingleRecordNotFilteredConfirmation = function() {
    return 1 === this.getRecordsCount() && !_.isEmpty(this.records);
  };
}

export default BaseActionConfirmation;
