import BaseTable from 'js/base_v2/table';
import DefaultNotifier from 'js/components_v2/default-notifier';
import EntityHelper from 'js/helpers/entity-helper';
import TableColumnHelper from 'js/helpers/table/column-helper';
import TableColumnRenderHelper from 'js/helpers/table/column-render-helper';

const staticSelf = BaseDataTable;

/**
 * @const
 */
staticSelf.SCROLL_TYPE_CURRENT = 'current';

/**
 * @const
 */
staticSelf.SCROLL_TYPE_BOTTOM = 'bottom';

/**
 * @const
 */
staticSelf.SELECTION_ALL = 'all';

/**
 * @const
 */
staticSelf.SELECTION_DISPLAYED = 'displayed';

/**
 * @const
 */
staticSelf.SELECTION_FILTERED = 'filtered';

/**
 * Base Data Table.
 *
 * @class
 * @abstract
 * @extends BaseTable
 *
 * @param {DOMElement} tableEl
 * @param {object}     [options]
 */
function BaseDataTable(tableEl, options) {
  BaseTable.call(this, tableEl, options);
  const parent = this.clone();
  const self = this;

  /**
   * @const
   */
  this.ROW_CLICK_ACTION_NONE = 'none';

  /**
   * @const
   */
  this.ROW_CLICK_ACTION_CLICK = 'click';

  /**
   * @const
   */
  this.ROW_CLICK_ACTION_SELECT = 'select';

  /**
   * @const
   */
  this.SELECTED_ROW_CLASS_NAME = 'selected';

  /**
   * @const
   */
  this.SELECTED_ROW_SELECTOR = '.selected';

  /**
   * @const
   */
  this.SELECTION_CHANGE_EVENT_NAME = 'partfiniti.table.selectionChange';

  /**
   * @prop {BaseRenderer}
   */
  this.renderer = null;

  /**
   * @prop {TableColumnHelper}
   */
  this.columnHelper = null;

  /**
   * @prop {TableColumnRenderHelper}
   */
  this.columnRenderHelper = null;

  /**
   * @prop {EntityHelper}
   */
  this.entityHelper = null;

  /**
   * @prop {DOMElement}
   */
  this.tableCt = null;

  /**
   * @prop {DataTables.Api}
   */
  this.dataTable = null;

  /**
   * @prop {array}
   */
  this.previousData = [];

  /**
   * @prop {number|string}
   */
  this.scrollTop = null;

  /**
   * @prop {boolean}
   */
  this.firstLoad = true;

  /**
   * @prop {boolean}
   */
  this.triggerSelectionChange = true;

  /**
   * @prop {object}
   */
  this.ajaxData = {};

  /**
   * @prop {number}
   */
  this.activeRecordsCount = null;

  /**
   * @prop {number}
   */
  this.activeFilteredRecordsCount = null;

  /**
   * @prop {boolean}
   */
  this.isLoadingMore = false;

  /**
   * @prop {string}
   */
  this.selectedRecordsMode = null;

  /**
   * @prop {int[]}
   */
  this.excludeIds = [];

  /**
   * @prop {boolean}
   */
  this.pagingEnabled = true;

  /**
   * @prop {boolean}
   */
  this.doubleScrollInitialized = false;

  /**
   * @prop {DefaultNotifier}
   */
  this.notifier = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityClass: undefined,
      rendererClass: undefined,
      loadUrl: undefined,
      loadParams: {},
      loadMore: false,
      rowClickAction: this.ROW_CLICK_ACTION_SELECT,
      recordIdentifierField: 'id',
      onInitComplete: undefined,
      beforeAjax: undefined,
      onAjax: undefined,
      onDraw: undefined,
      onRowClick: undefined,
      onSelectionChange: undefined,
      onCreatedRow: undefined,
      onInitRow: undefined,
      onInitRowComponents: undefined,
      onRowReorder: undefined,
      onAdjustColumns: undefined,
      onFirstLoad: undefined,
      onScroll: undefined,
      hiddenColumns: [],
      hideEmptyRows: false,
      columnsOrdering: {},
      columnWidths: undefined,
      autoAdjustHeight: true,
      autoAdjustHeightDelay: 0,
      excludeRejectedFromSelection: true,
      excludeFromSelectionClasses: undefined,
      dataTable: {
        columns: [],
        autoWidth: false,
        serverSide: true,
        lengthChange: false,
        info: false,
        paging: true,
        processing: true,
        scrollY: '100%',
        scrollCollapse: true,
        search: {},
        searching: false,
        pageLength: 20,
        dom: 't',
        deferLoading: 0,
        order: [],
        ajax: {
          data(data) {
            return self.processAjaxData(data);
          },
          dataSrc(data) {
            return self.getRecordsFromAjaxResponse(data);
          },
        },
        drawCallback() {
          self.reattachRows();
        },
        initComplete(settings) {
          self.onInitComplete(settings);
        },
        createdRow(row, data, dataIndex) {
          self.createdRow($(row), data, dataIndex);
        },
        responsive: {
          details: {
            type: 'column',
            target: '.jsTableResponsiveBt',
          },
        },
        language: {
          emptyTable: this.getEmptyTableContent(),
        },
      },
      doubleScroll: false,
      columnHelper: {},
      columnRenderHelper: {},
      entityHelper: {},
      totalCells: this.getTotalCells(),
      minusHeightSelector: '.jsMinusHeight',
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    return this
      .processHiddenColumnsOptions()
      .processColumnsOptions()
      .processAjaxOptions();
  };

  /**
   * Process hidden columns options.
   *
   * @return {BaseDataTable}
   */
  this.processHiddenColumnsOptions = function() {
    if (!_.isArray(this.options.hiddenColumns)) {
      this.options.hiddenColumns = [];
    }

    let columnDefsOptions = this.options.dataTable.columnDefs;

    if (_.isUndefined(columnDefsOptions)) {
      columnDefsOptions = [];
    }

    columnDefsOptions.push({
      visible: false,
      targets: this.options.hiddenColumns,
    });

    this.options.dataTable.columnDefs = columnDefsOptions;

    return this;
  };

  /**
   * Process columns options.
   *
   * @return {BaseDataTable}
   */
  this.processColumnsOptions = function() {
    this.extendOptions({
      dataTable: {
        columns: this.getProcessedColumns(),
      },
    });

    return this;
  };

  /**
   * Convenience function for extending load params
   *
   * @param {object} params
   * @return {BaseDataTable}
   */
  this.extendLoadParams = function(params) {
    this.options.loadParams = this.options.loadParams.extend(params);

    return this;
  };

  /**
   * Process AJAX options.
   *
   * @return {BaseDataTable}
   */
  this.processAjaxOptions = function() {
    this.extendOptions({
      dataTable: {
        ajax: {
          url: this.options.loadUrl,
        },
      },
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initProps = function() {
    parent.initProps.call(this);

    this.renderer = _.isFunction(this.options.rendererClass) ?
      new this.options.rendererClass() :
      null;

    this.columnHelper = new TableColumnHelper(this.options.columnHelper);

    this.columnRenderHelper = new TableColumnRenderHelper(
      this.options.columnRenderHelper,
    );

    this.entityHelper = new EntityHelper(this.options.entityHelper);

    return this;
  };

  /**
   * Create table.
   *
   * @return {BaseDataTable}
   */
  this.create = function() {
    if (_.isString(this.tableEl)) {
      this.tableEl = $(this.tableEl);
    }

    this.dataTable = this.tableEl.DataTable(this.options.dataTable);
    this.tableCt = this.tableEl.closest('.dataTables_wrapper');
    this.notifier = new DefaultNotifier(this.options.notifier).create();

    this.registerEventListeners();

    return this;
  };

  /**
   * Destroy table.
   *
   * @return {BaseDataTable}
   */
  this.destroy = function() {
    this.dataTable.destroy();
    return this;
  };

  /**
   * Initialize complete event handler.
   *
   * @param  {DataTables.Settings} settings
   * @return {boolean|undefined}
   */
  this.onInitComplete = function(settings) {
    if (_.isFunction(this.options.onInitComplete) &&
      false === this.options.onInitComplete(settings)
    ) {
      return false;
    }

    this
      .adjustColumns()
      .initScrolling()
      .createLoadingOverlay()
      .createLoadingMoreSpinner()
      .createLoadMoreBtn();

    return undefined;
  };

  /**
   * Adjust table columns.
   *
   * @return {BaseDataTable}
   */
  this.adjustColumns = function() {
    this.tableEl.DataTable().columns.adjust();

    if (_.isFunction(this.options.onAdjustColumns)) {
      this.options.onAdjustColumns();
    }

    return this;
  };

  /**
   * Refresh double scroll (if enabled).
   *
   * @return {BaseDataTable}
   */
  this.refreshDoubleScroll = function() {
    if (!this.options.doubleScroll || !this.doubleScrollInitialized) {
      return this;
    }

    const dtScrollCt = this.tableEl.closest('.dataTables_scroll');

    if (this.tableEl.hasClass('noHeader')) {
      dtScrollCt.find('.dataTables_scrollBody').doubleScroll('refresh');
    } else {
      dtScrollCt.find('.dataTables_scrollHead').doubleScroll('refresh');
    }

    return this;
  };

  /**
   * Created row callback.
   *
   * @param  {DOMElement}        tr
   * @param  {object}            record
   * @param  {number}            index
   * @return {boolean|undefined}
   */
  this.createdRow = function(tr, record, index) {
    if (_.isFunction(this.options.onCreatedRow) &&
      false === this.options.onCreatedRow(tr, record, index)
    ) {
      return false;
    }

    this.initRow(tr, record);

    return undefined;
  };

  /**
   * Initialize table row.
   *
   * @param  {DOMElement}    tr
   * @param  {object}        record
   * @return {BaseDataTable}
   */
  this.initRow = function(tr, record) {
    tr
      .addClass('jsMainRow')
      .attr(
        'data-identifier',
        Object.get(record, this.options.recordIdentifierField),
      );

    if (_.isFunction(this.options.onInitRow)) {
      this.options.onInitRow(tr);
    }

    if (this.options.hideEmptyRows && !_.filter(record).length) {
      tr.addClass('hide');
    }

    return this.initRowComponents(tr, record);
  };

  /**
   * Disable row if record deleted.
   *
   * @param  {DOMElement}         tr
   * @param  {object}             record
   * @return {BaseQuotePartTable}
   */
  this.disableRowIfDeleted = function(tr, record) {
    if (1 === +record.deleted) {
      return this.disableRow(tr);
    }

    return this;
  };

  /**
   * Disable row.
   *
   * @param  {DOMElement}         tr
   * @return {BaseQuotePartTable}
   */
  this.disableRow = function(tr) {
    $(tr).addClass('rejected');
    $('.btn', tr).addClass('disabled');

    return this;
  };

  /**
   * Enable row.
   *
   * @param  {DOMElement}         tr
   * @return {BaseQuotePartTable}
   */
  this.enableRow = function(tr) {
    $(tr).removeClass('rejected');
    $('.btn', tr).removeClass('disabled');

    return this;
  };

  /**
   * Initialize table row components.
   *
   * @param  {DOMElement}    tr
   * @param  {object}        record
   * @return {BaseDataTable}
   */
  this.initRowComponents = function(tr, record) {
    if (_.isFunction(this.options.onInitRowComponents)) {
      this.options.onInitRowComponents(tr, record);
    }

    return this;
  };

  /**
   * Init double scroll
   *
   * @return {BaseDataTable}
   */
  this.initDoubleScroll = function() {
    const dtScrollCt = this.tableEl.closest('.dataTables_scroll');

    const dtScrollCtHead = dtScrollCt.find('.dataTables_scrollHead');
    const dtScrollCtBody = dtScrollCt.find('.dataTables_scrollBody');

    if (this.tableEl.hasClass('noHeader')) {
      dtScrollCtBody.doubleScroll();
    } else {
      dtScrollCtHead.doubleScroll();

      // handle the case of scrolling the top scrollbar manually
      const dblScrollCt = dtScrollCt.find(
        '.suwala-doubleScroll-scroll-wrapper',
      );

      let mouseOver = false;

      dblScrollCt.on('mouseover', () => {
        mouseOver = true;
      });

      dblScrollCt.on('mouseleave', () => {
        mouseOver = false;
      });

      dblScrollCt.on('scroll', function() {
        if (!mouseOver) {
          return;
        }

        dtScrollCtBody.scrollLeft($(this).scrollLeft());
      });
    }

    this.doubleScrollInitialized = true;

    return this;
  };

  /**
   * Initialize table scrolling.
   *
   * @return {BaseDataTable}
   */
  this.initScrolling = function() {
    if (this.tableEl.hasClass('noScroll')) {
      return this;
    }

    this.getScrollBody()
      .addClass('scroller_table')
      .wrap('<div class="wrapp_scroll_table"></div>');

    this.tableEl.closest('.wrapp_scroll_table')
      .append(
        '<div class="wrapp_bar_table">' +
                '  <div class="bar_table"></div>' +
                '</div>',
      );

    baron({
      root: '.wrapp_scroll_table',
      scroller: '.scroller_table',
      bar: '.bar_table',
      barOnCls: 'baron_table',
    });

    if (this.options.autoAdjustHeight) {
      setTimeout(() => {
        this.adjustHeight();
      }, this.options.autoAdjustHeightDelay);
    }

    setTimeout(() => {
      $('.jsTabLoading').removeClass('in');
    }, 500);

    return this;
  };

  /**
   * Create load more button.
   *
   * @return {BaseDataTable}
   */
  this.createLoadMoreBtn = function() {
    if (!this.options.loadMore) {
      return this;
    }

    this.getScrollBody().append(`
      <div
        class="loadMoreCt wrap_btn_table load-more-ct"
        style="display: none;"
      >
        <a
          href="#"
          class="loadMoreRecords load-more-btn btn btn_invert"
        >
          ${this.getLoadMoreButtonLabel()}
        </a>
      </div>
    `);

    return this;
  };

  /**
   * Create loading overlay.
   *
   * @return {BaseDataTable}
   */
  this.createLoadingOverlay = function() {
    this.getScrollBody().append(`
      <div class="wrapp_load fade">
        <svg class="fa-spin fa-3x"><use xlink:href="#icon-spinner"></use></svg>
      </div>
    `);

    return this;
  };

  /**
   * Create loading more spinner.
   *
   * @return {BaseDataTable}
   */
  this.createLoadingMoreSpinner = function() {
    this.getScrollBody().append(`
      <div class="loader fade">
        <svg class="fa-spin fa-3x"><use xlink:href="#icon-spinner"></use></svg>
      </div>
    `);

    return this;
  };

  /**
   * Adjust table height.
   *
   * @return {BaseDataTable}
   */
  this.adjustHeight = function() {
    let minusHeight = 0;

    this.getMinusHeightElements().each(function() {
      if ($(this).is(':visible')) {
        minusHeight += $(this).outerHeight();
      }
    });

    const headerOuterHeight = this.getScrollHeader().outerHeight();
    const footerOuterHeight = this.getScrollFooter().outerHeight();

    if (_.isNumber(headerOuterHeight)) {
      minusHeight += headerOuterHeight;
    }

    if (_.isNumber(footerOuterHeight)) {
      minusHeight += footerOuterHeight;
    }

    let initialHeight = '100vh';

    const dataTableContainer = this.tableEl.closest('.dataTableContainer');

    if (dataTableContainer.length > 0) {
      initialHeight = `${dataTableContainer.outerHeight()}px`;
    }

    this.getScrollBodyWrapper()
      .height(`calc(${initialHeight} - ${minusHeight}px)`);

    return this;
  };

  /**
   * Reattach rows detached by Datatables' _fnDraw.
   *
   * @return {BaseDataTable}
   */
  this.reattachRows = function() {
    if (this.previousData.length === 0) {
      return this;
    }

    const allData = $.merge(
      this.previousData,
      this.dataTable.rows().data(),
    );

    this.dataTable.clear();

    const renderedGroups = [];

    for (let i = 0; i < allData.length; i += 1) {
      const data = allData[i];

      // Avoid duplicate group headers
      if (data.type !== 'group' || !renderedGroups[data.id]) {
        this.dataTable.row.add(data);
      }

      if (data.type === 'group') {
        renderedGroups[data.id] = true;
      }
    }

    this.previousData = [];
    this.dataTable.draw('full-hold');

    return this;
  };

  /**
   * Get filters.
   *
   * @return {object}
   */
  this.getFilters = function() {
    return this.options.loadParams.filters;
  };

  /**
   * Set filters.
   *
   * @param  {object}        filters
   * @return {BaseDataTable}
   */
  this.setFilters = function(filters) {
    if (!_.isObject(this.options.loadParams.filters)) {
      this.options.loadParams.filters = {};
    }

    _.each(filters, function(value, key) {
      this.options.loadParams.filters[key] = value;
    }, this);

    return this;
  };

  /**
   * Set view by.
   *
   * @param  {object}        viewBy
   * @return {BaseDataTable}
   */
  this.setViewBy = function(viewBy) {
    this.options.loadParams.viewBy =
            Object.extend(this.options.loadParams.viewBy, viewBy);

    return this;
  };

  /**
   * Does clicking the element propagate?
   *
   * @param  {DOMElement} element
   * @return {bool}
   */
  this.isClickPropagated = function(element) {
    const clickedElement = $(element);

    if (clickedElement.hasClass('stopPropagation') ||
      clickedElement.closest('.stopPropagation').length > 0 ||
      clickedElement.hasClass('selectSingle') ||
      clickedElement.closest('.selectSingle').length > 0 ||
      clickedElement.hasClass('selectGroup') ||
      clickedElement.closest('.selectGroup').length > 0
    ) {
      return false;
    }

    if (clickedElement.hasClass('dataTables_empty') ||
      clickedElement.closest('.dataTables_empty').length > 0
    ) {
      return false;
    }

    if (this.ROW_CLICK_ACTION_SELECT === this.options.rowClickAction) {
      const tr = clickedElement.closest('tr.jsMainRow');

      if (0 === $('.selectSingle', tr).length) {
        return false;
      }

      if (!clickedElement.hasClass('check_item')) {
        return false;
      }
    }

    return true;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    this.tableEl.on('page.dt', (e) => {
      // Save the rows so that they can be reattached later by the
      // drawCallback function
      if (!self.isEventTarget(e)) {
        return;
      }

      self.previousData = self.dataTable.rows().data();
    });

    this.tableEl.on('preXhr.dt', (e, settings, data) => {
      if (!self.isEventTarget(e)) {
        return;
      }

      self.beforeAjax(settings, data);
    });

    this.tableEl.on('row-reorder.dt', (e, diff, edit) => {
      if (!self.isEventTarget(e)) {
        return;
      }

      self.onRowReorder(e, diff, edit);
    });

    this.tableEl.on('click', 'tbody tr', (event) => {
      const child = $(event.target);

      if (child.hasClass('defaultLink') ||
        child.closest('.defaultLink').length > 0
      ) {
        return;
      }

      this.onRowChildClick(event, child);
    });

    this.tableEl.on('draw.dt', (e) => {
      if (!self.isEventTarget(e)) {
        return;
      }

      self.onDraw();
    });

    $(window).on('resize', () => {
      this
        .adjustHeight()
        .adjustColumns()
        .refreshDoubleScroll();
    });

    this.tableEl.on('xhr.dt', (e, settings, json) => {
      if (!self.isEventTarget(e)) {
        return;
      }

      self.onAjax(json);
    });

    this.tableEl.on('error.dt', (e, settings) => {
      const errorMessage = this.getErrorMessageFromAjaxResponse(settings.jqXHR);

      this.notifier.notifyError(
        `${this.translator.get('error_prefix_load_data')}: ${errorMessage}`,
      );

      this
        .fadeOutLoadingOverlay()
        .fadeOutLoadingMoreSpinner();
    });

    this.tableEl.on('click', '.selectSingle', function(e) {
      e.preventDefault();
      self.toggleSingle($(this).closest('tr.jsMainRow'));
    });

    this.tableCt.on('click', '.dataTables_scrollHead .selectAll', (e) => {
      e.preventDefault();
      self.selectedRecordsMode = staticSelf.SELECTION_DISPLAYED;
      self.toggleAll();
    });

    this.tableCt.on(
      'click',
      '.dataTables_scrollHead .showSelectAllOptions',
      (e) => {
        if (this.isFilteredSelectionEnabled() &&
          null === this.selectedRecordsMode
        ) {
          $('ul li.selectionAll .count').text(this.getActiveRecordsCount());

          $('ul li.selectionDisplayed .count').text(
            this.getActiveDisplayedRecordsCount(),
          );

          $('ul li.selectionFiltered .count').text(
            this.getActiveFilteredRecordsCount(),
          );

          // Allow the selection dropdown to open
          return;
        }

        e.preventDefault();
        e.stopPropagation();

        this.toggleAll();
      },
    );

    this.tableCt.on('click', '.dataTables_scrollHead .selectionAll', (e) => {
      e.preventDefault();
      self.selectedRecordsMode = staticSelf.SELECTION_ALL;
      self.toggleAll();
    });

    this.tableCt.on(
      'click',
      '.dataTables_scrollHead .selectionDisplayed',
      (e) => {
        e.preventDefault();

        this.selectedRecordsMode = staticSelf.SELECTION_DISPLAYED;
        this.toggleAll();
      },
    );

    this.tableCt.on(
      'click',
      '.dataTables_scrollHead .selectionFiltered',
      (e) => {
        e.preventDefault();

        this.selectedRecordsMode = staticSelf.SELECTION_FILTERED;
        this.toggleAll();
      },
    );

    this.tableEl.on('click', '.selectGroup', function(e) {
      e.preventDefault();
      self.toggleGroup($(this).closest('tr.jsMainRow'));
    });

    this.tableEl.on(this.SELECTION_CHANGE_EVENT_NAME, () => {
      self.onSelectionChange();
    });

    this.getScrollBody().on('scroll', () => {
      self.onScroll();
    });

    return this;
  };

  /**
   * Table scroll event handler.
   *
   * @return {boolean|undefined}
   */
  this.onScroll = function() {
    if (_.isFunction(this.options.onScroll) &&
      false === this.options.onScroll(this.getScrollBody())
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Row child click event handler.
   *
   * @param {Event}      event
   * @param {DOMElement} child
   */
  this.onRowChildClick = function(event, child) {
    if (!this.isClickPropagated(child)) {
      return;
    }

    event.preventDefault();

    const tr = child.closest('tr.jsMainRow');

    switch (this.options.rowClickAction) {
      case this.ROW_CLICK_ACTION_CLICK:
        this.onRowClick(tr);
        break;
      case this.ROW_CLICK_ACTION_SELECT:
        this.toggleSingle(tr);
        break;
      default:
        // Do nothing
    }
  };

  /**
   * Row click event handler.
   *
   * @param  {DOMElement} tr
   * @return {boolean|undefined}
   */
  this.onRowClick = function(tr) {
    if (_.isFunction(this.options.onRowClick) &&
      false === this.options.onRowClick(tr)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Selection change event handler.
   *
   * @return {boolean|undefined}
   */
  this.onSelectionChange = function() {
    if (_.isFunction(this.options.onSelectionChange) &&
      false === this.options.onSelectionChange()
    ) {
      return false;
    }

    if (this.getSelectionCount() > 0) {
      this.disableRowReorderIfDefined();
    } else {
      this.enableRowReorderIfAllowed();
    }

    return undefined;
  };

  /**
   * Is filtered selection enabled.
   *
   * @return {boolean}
   */
  this.isFilteredSelectionEnabled = function() {
    return true;
  };

  /**
   * Toggle single row.
   *
   * @param  {DOMElement}    tr
   * @param  {boolean}       [triggerChange]
   * @return {BaseDataTable}
   */
  this.toggleSingle = function(tr, triggerChange = true) {
    // Toggle checkbox
    tr.find('.custom-control-indicator').toggleClass('checked');

    // Toggle selected row class
    tr.toggleClass(this.SELECTED_ROW_CLASS_NAME);

    // Update select all checkbox
    this.updateSelectAllCheckbox();

    this.updateExcludeIds(tr);

    // Update group row
    this.updateGroupRow(this.getGroupRow(tr));

    if (this.triggerSelectionChange && triggerChange) {
      // Trigger selection change event
      this.tableEl.trigger(this.SELECTION_CHANGE_EVENT_NAME);
    }

    return this;
  };

  /**
   * Toggle all rows.
   *
   * @param  {boolean}       [triggerChange]
   * @return {BaseDataTable}
   */
  this.toggleAll = function(triggerChange = true) {
    if (0 === this.getSelectionCount()) {
      return this.selectAll(triggerChange);
    }

    return this.deselectAll(triggerChange);
  };

  /**
   * Toggle all rows within the group.
   *
   * @param  {DOMElement}    groupTr
   * @param  {boolean}       [triggerChange]
   * @return {BaseDataTable}
   */
  this.toggleGroup = function(groupTr, triggerChange = true) {
    const groupSelectionCount = this.getGroupSelectionCount(groupTr);

    if (0 === groupSelectionCount) {
      return this.selectGroup(groupTr, triggerChange);
    }

    return this.deselectGroup(groupTr, triggerChange);
  };

  /**
   * Select single row.
   *
   * @param  {DOMElement}    tr
   * @param  {boolean}       [triggerChange]
   * @return {BaseDataTable}
   */
  this.selectSingle = function(tr, triggerChange = true) {
    if (tr.hasClass(this.SELECTED_ROW_CLASS_NAME)) {
      return this;
    }

    tr.find('.selectSingle').click();

    if (this.triggerSelectionChange && triggerChange) {
      // Trigger selection change event
      this.tableEl.trigger(this.SELECTION_CHANGE_EVENT_NAME);
    }

    return this;
  };

  /**
   * Select all rows.
   *
   * @param  {boolean}       [triggerChange]
   * @return {BaseDataTable}
   */
  this.selectAll = function(triggerChange = true) {
    // Disable trigger of selection change event
    this.triggerSelectionChange = false;

    this.tableEl
      .find('tr.jsMainRow')
      .not(this.SELECTED_ROW_SELECTOR)
      .not(this.options.excludeRejectedFromSelection ? '.rejected' : '')
      .not(this.options.excludeFromSelectionClasses)
      .find('.selectSingle')
      .click();
    $('.showSelectAllOptions .checkBox', this.tableCt).addClass('checked');

    if (triggerChange) {
      // Trigger selection change event
      this.tableEl.trigger(this.SELECTION_CHANGE_EVENT_NAME);
    }

    // Enable trigger of selection change event
    this.triggerSelectionChange = true;

    return this;
  };

  /**
   * Deselect all rows.
   *
   * @param  {boolean}       [triggerChange]
   * @return {BaseDataTable}
   */
  this.deselectAll = function(triggerChange = true) {
    // Disable trigger of selection change event
    this.triggerSelectionChange = false;
    this.selectedRecordsMode = null;

    this.tableEl
      .find(this.SELECTED_ROW_SELECTOR)
      .find('.selectSingle')
      .click();
    $('.showSelectAllOptions .checkBox').removeClass('checked');

    if (triggerChange) {
      // Trigger selection change event
      this.tableEl.trigger(this.SELECTION_CHANGE_EVENT_NAME);
    }

    // Enable trigger of selection change event
    this.triggerSelectionChange = true;

    return this;
  };

  /**
   * Select all rows within the group.
   *
   * @param  {DOMElement}    groupTr
   * @param  {boolean}       [triggerChange]
   * @return {BaseDataTable}
   */
  this.selectGroup = function(groupTr, triggerChange = true) {
    // Disable trigger of selection change event
    this.triggerSelectionChange = false;

    // Get group ID
    const groupId = groupTr.attr('data-id');

    this.tableEl
      .find(`tr.jsMainRow[data-group="${groupId}"]`)
      .not(this.SELECTED_ROW_SELECTOR)
      .find('.selectSingle')
      .click();

    if (triggerChange) {
      // Trigger selection change event
      this.tableEl.trigger(this.SELECTION_CHANGE_EVENT_NAME);
    }

    // Enable trigger of selection change event
    this.triggerSelectionChange = true;

    return this;
  };

  /**
   * Deselect all rows within the group.
   *
   * @param  {DOMElement}    groupTr
   * @param  {boolean}       [triggerChange]
   * @return {BaseDataTable}
   */
  this.deselectGroup = function(groupTr, triggerChange = true) {
    // Disable trigger of selection change event
    this.triggerSelectionChange = false;

    // Get group ID
    const groupId = groupTr.attr('data-id');

    this.tableEl
      .find(
        `tr.jsMainRow[data-group="${groupId}"]${this.SELECTED_ROW_SELECTOR}`,
      )
      .find('.selectSingle')
      .click();

    if (triggerChange) {
      // Trigger selection change event
      this.tableEl.trigger(this.SELECTION_CHANGE_EVENT_NAME);
    }

    // Enable trigger of selection change event
    this.triggerSelectionChange = true;

    return this;
  };

  /**
   * Update select all checkbox.
   *
   * @return {BaseDataTable}
   */
  this.updateSelectAllCheckbox = function() {
    const selectionCount = this.getSelectionCount();
    const rowCount = this.getRowCount();

    const checkbox =
            $('.selectAll .custom-control-indicator', this.tableCt);

    if (rowCount === selectionCount) {
      checkbox
        .removeClass('singlecheck')
        .addClass('checked');
    } else if (selectionCount > 0) {
      checkbox
        .removeClass('checked')
        .addClass('singlecheck');
    } else if (0 === selectionCount) {
      checkbox
        .removeClass('singlecheck')
        .removeClass('checked');
    }

    return this;
  };

  /**
   * Update exclude ids.
   *
   * @param  {DOMElement}    tr
   * @return {BaseDataTable}
   */
  this.updateExcludeIds = function(tr) {
    const groupSelectionModes = [
      staticSelf.SELECTION_ALL,
      staticSelf.SELECTION_FILTERED,
    ];

    if (!groupSelectionModes.includes(this.selectedRecordsMode)) {
      return this;
    }

    const record = this.getRowData(tr);

    if (this.isRowSelected(tr)) {
      this.excludeIds = this.excludeIds.filter((id) => +id !== +record.id);
    } else {
      this.excludeIds.push(record.id);
    }

    if (this.excludeIds.length) {
      $('.showSelectAllOptions span.checkBox').addClass('partially');
    } else {
      $('.showSelectAllOptions span.checkBox').removeClass('partially');
    }

    return this;
  };

  /**
   * Is row selected.
   *
   * @param  {DOMElement} tr
   * @return {Boolean}
   */
  this.isRowSelected = function(tr) {
    return tr.find('.custom-control-indicator').hasClass('checked');
  };

  /**
   * Update group row.
   *
   * @param  {DOMElement}    groupTr
   * @return {BaseDataTable}
   */
  this.updateGroupRow = function(groupTr) {
    const groupCount = this.getGroupCount(groupTr);
    const groupSelectionCount = this.getGroupSelectionCount(groupTr);

    const checkbox = $('.selectGroup .custom-control-indicator', groupTr);

    if (groupCount === groupSelectionCount) {
      groupTr.addClass(this.SELECTED_ROW_CLASS_NAME);

      checkbox
        .removeClass('singlecheck')
        .addClass('checked');
    } else if (groupSelectionCount > 0) {
      groupTr.removeClass(this.SELECTED_ROW_CLASS_NAME);

      checkbox
        .removeClass('checked')
        .addClass('singlecheck');
    } else if (0 === groupSelectionCount) {
      groupTr.removeClass(this.SELECTED_ROW_CLASS_NAME);

      checkbox
        .removeClass('singlecheck')
        .removeClass('checked');
    }

    return this;
  };

  /**
   * Get selected records.
   *
   * @return {object[]}
   */
  this.getSelectedRecords = function() {
    const selectedRecords = [];

    this.tableEl.find(this.SELECTED_ROW_SELECTOR).each(function() {
      selectedRecords.push(self.getRowData($(this)));
    });

    return selectedRecords;
  };

  /**
   * Get selection count.
   *
   * @return {int}
   */
  this.getSelectionCount = function() {
    return this.tableEl.find(this.SELECTED_ROW_SELECTOR).length;
  };

  /**
   * Get group selection count.
   *
   * @param  {DOMElement} groupTr
   * @return {number}
   */
  this.getGroupSelectionCount = function(groupTr) {
    const groupId = groupTr.attr('data-id');

    const selector =
      `tr.jsMainRow[data-group="${groupId}"]${this.SELECTED_ROW_SELECTOR}`;

    return $(selector).length;
  };

  /**
   * Get record row.
   *
   * @param  {object}     record
   * @return {DOMElement}
   */
  this.getRecordRow = function(record) {
    return this.getRow(record[this.options.recordIdentifierField]);
  };

  /**
   * Get row.
   *
   * @param  {number|string} recordIdentifier
   * @return {DOMElement}
   */
  this.getRow = function(recordIdentifier) {
    return $(`tr[data-identifier="${recordIdentifier}"]`);
  };

  /**
   * @param  {number|string} identifier
   * @return {?object}
   */
  this.getRecordByIdentifier = function(identifier) {
    return this.getRowData(this.getRow(identifier));
  };

  /**
   * Enable row reorder.
   *
   * @return {BaseDataTable}
   */
  this.enableRowReorder = function() {
    this.dataTable.rowReorder.enable();
    $('td.reorderRow .reorderHandle', this.tableEl).removeClass('disable');

    return this;
  };

  /**
   * Enable row reorder if it is allowed.
   *
   * @return {BaseDataTable}
   */
  this.enableRowReorderIfAllowed = function() {
    if (!this.isRowReorderAllowed()) {
      return this;
    }

    return this.enableRowReorder();
  };

  /**
   * Disable row reorder.
   *
   * @return {BaseDataTable}
   */
  this.disableRowReorder = function() {
    this.dataTable.rowReorder.disable();
    $('td.reorderRow .reorderHandle', this.tableEl).addClass('disable');

    return this;
  };

  /**
   * Disable row reorder if it is defined.
   *
   * @return {BaseDataTable}
   */
  this.disableRowReorderIfDefined = function() {
    if (!this.isRowReorderDefined()) {
      return this;
    }

    return this.disableRowReorder();
  };

  /**
   * Check if row reorder is defined.
   *
   * @return {boolean}
   */
  this.isRowReorderDefined = function() {
    return _.isObject(this.options.dataTable.rowReorder);
  };

  /**
   * Check if row reorder is allowed.
   *
   * @return {boolean}
   */
  this.isRowReorderAllowed = function() {
    return this.isRowReorderDefined();
  };

  /**
   * Get rows.
   *
   * @return {DOMElement[]}
   */
  this.getRows = function() {
    return this.tableEl.find('.jsMainRow');
  };

  /**
   * Get row count.
   *
   * @return {int}
   */
  this.getRowCount = function() {
    return this.getRows().length;
  };

  /**
   * Get row count within the group.
   *
   * @param  {DOMElement} groupTr
   * @return {number}
   */
  this.getGroupCount = function(groupTr) {
    const groupId = groupTr.attr('data-id');
    return $(`tr.jsMainRow[data-group="${groupId}"]`, this.tableEl).length;
  };

  /**
   * Table draw event handler.
   *
   * @return {boolean|undefined}
   */
  this.onDraw = function() {
    // Trigger selection change event
    this.tableEl.trigger(this.SELECTION_CHANGE_EVENT_NAME);

    if (_.isFunction(this.options.onFirstLoad)) {
      this.options.onFirstLoad();
    }

    if (_.isFunction(this.options.onDraw)) {
      const ret = this.options.onDraw();

      if (false === ret) {
        return ret;
      }
    }

    if (null !== this.scrollTop) {
      if (this.scrollTop === 'bottom') {
        this.scrollTop = this.getScrollBody().find('table:first').height();
      }

      this.getScrollBody().scrollTop(this.scrollTop);
      this.scrollTop = null;
    }

    if (this.options.doubleScroll) {
      this.initDoubleScroll();
    }

    return undefined;
  };

  /**
   * AJAX complete event handler.
   *
   * @param  {object}            response
   * @return {boolean|undefined}
   */
  this.onAjax = function(response) {
    if (_.isFunction(this.options.onAjax)) {
      const ret = this.options.onAjax(response);

      if (false === ret) {
        return ret;
      }
    }

    if (this.firstLoad) {
      this.tableEl.addClass('data-loaded');
      this.firstLoad = false;
    }

    if (_.isObject(response)) {
      this.updateTotals(response.totals)
        .setActiveRecordsCount(response.recordsActiveAll)
        .setActiveFilteredRecordsCount(response.recordsActiveFiltered);
    }

    return undefined;
  };

  /**
   * Update totals.
   *
   * @param  {object}        totals
   * @return {BaseDataTable}
   */
  this.updateTotals = function(totals) {
    const totalCells = this.getOption('totalCells');

    if (!_.isObject(totals) || _.isEmpty(totalCells)) {
      return this;
    }

    _.each(totalCells, function(totalCell) {
      const td = $(totalCell.selector, this.getFooterTableEl());
      const html = this.columnRenderHelper.buildRenderer(totalCell)(totals);

      td.html(html);
    }, this);

    return this;
  };

  /**
   * Reload table.
   *
   * @param  {?function}     callback
   * @param  {?boolean}      resetPaging
   * @param  {?string}       scrollType
   * @return {BaseDataTable}
   */
  this.reload = function(callback, resetPaging, scrollType) {
    switch (scrollType) {
      case staticSelf.SCROLL_TYPE_CURRENT:
        this.scrollTop = this.getScrollBody().scrollTop();
        break;
      case staticSelf.SCROLL_TYPE_BOTTOM:
        this.scrollTop = 'bottom';
        break;
      default:
        // Do nothing
    }

    this.dataTable.ajax.reload(callback, resetPaging);

    return this;
  };

  /**
   * Before ajax event handler.
   *
   * @param  {object}            settings
   * @param  {object}            data
   * @return {boolean|undefined}
   */
  this.beforeAjax = function(settings, data) {
    if (_.isFunction(this.options.beforeAjax) &&
      false === this.options.beforeAjax(settings, data)
    ) {
      return false;
    }

    if (this.isLoadingMore) {
      this.getLoadMoreButton().parent().hide();
      this.fadeInLoadingMoreSpinner();
    } else {
      this.fadeInLoadingOverlay();
    }

    const loadXhr = settings.jqXHR;

    if (null !== loadXhr && 4 !== loadXhr.readyState) {
      loadXhr.abort();
    }

    return undefined;
  };

  /**
   * Row reorder event handler.
   *
   * @param  {Event}             e
   * @param  {object[]}          diff
   * @param  {object}            edit
   * @return {boolean|undefined}
   */
  this.onRowReorder = function(e, diff, edit) {
    if (_.isFunction(this.options.onRowReorder) &&
      false === this.options.onRowReorder(e, diff, edit)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Process AJAX data.
   *
   * @param  {object} data
   * @return {object}
   */
  this.processSortData = function(data) {
    const sort = {
      sortField: [],
      sortDir: [],
    };

    _.each(data.order, (o) => {
      sort.sortField.push(data.columns[o.column].name);
      sort.sortDir.push(o.dir);
    }, this);

    return sort;
  };

  /**
   * Process AJAX data.
   *
   * @param  {object}                  data
   * @return {object|string|undefined}
   */
  this.processAjaxData = function(data) {
    let processedData = data.clone();

    // Merge custom parameters
    processedData = Object.extend(processedData, this.options.loadParams);

    processedData = Object.extend(
      processedData,
      this.processSortData(processedData),
    );

    delete processedData.columns;
    delete processedData.order;

    if (processedData.start >= 0 && processedData.length > 0) {
      // Set pagination parameters
      processedData = Object.extend(processedData, {
        page: processedData.start / processedData.length + 1,
        pageSize: processedData.length,
      });
    }

    this.ajaxData = processedData;

    return processedData;
  };

  /**
   * Get records from AJAX response.
   *
   * @param  {object}   response
   * @return {object[]}
   */
  this.getRecordsFromAjaxResponse = function(response) {
    const recordsData = response.data;

    if (!_.isArray(recordsData)) {
      return [];
    }

    return _.map(
      recordsData,
      (recordData) => this.entityHelper.get(
        recordData,
        this.options.entityClass,
      ),
    );
  };

  /**
   * Get processed columns.
   *
   * @return {object[]}
   */
  this.getProcessedColumns = function() {
    return this.processColumns(this.getColumns());
  };

  /**
   * Get columns.
   *
   * @return {object[]}
   */
  this.getColumns = function() {
    return [];
  };

  /**
   * Process columns.
   *
   * @param  {object[]} columns
   * @return {object[]}
   */
  this.processColumns = function(columns) {
    this.columnHelper
      .setOption('hiddenColumns', this.getOption('hiddenColumns'));

    let visibleColumnIndex = 0;

    if (this.options.columnWidths) {
      _.each(columns, function(column, index) {
        if (_.contains(this.options.hiddenColumns, index) ||
          _.isUndefined(this.options.columnWidths[visibleColumnIndex])
        ) {
          return;
        }

        // eslint-disable-next-line no-param-reassign
        column.width = this.options.columnWidths[visibleColumnIndex];

        visibleColumnIndex += 1;
      }, this);
    }

    this.processColumnsOrdering(columns);

    return this.columnHelper.processColumns(columns);
  };

  /**
   * Process columns ordering.
   *
   * @param  {object[]} columns
   * @return {object[]}
   */
  this.processColumnsOrdering = function(columns) {
    if (!_.isObject(this.options.columnsOrdering) ||
      _.isEmpty(this.options.columnsOrdering)
    ) {
      return columns;
    }

    return columns.sort((columnA, columnB) => {
      let columnAOrder = self.options.columnsOrdering[columnA.name];
      let columnBOrder = self.options.columnsOrdering[columnB.name];

      if (!_.isNumber(columnAOrder)) {
        columnAOrder = undefined;
      }
      if (!_.isNumber(columnBOrder)) {
        columnBOrder = undefined;
      }

      if (_.isUndefined(columnAOrder) && _.isUndefined(columnBOrder)) {
        return -1;
      }

      if (!_.isUndefined(columnAOrder) && _.isUndefined(columnBOrder)) {
        return -1;
      }

      if (_.isUndefined(columnAOrder) && !_.isUndefined(columnBOrder)) {
        return 1;
      }

      return columnAOrder - columnBOrder;
    });
  };

  /**
   * Get total cells.
   *
   * @return {object[]}
   */
  this.getTotalCells = function() {
    return [];
  };

  /**
   * Fetch the next page of the results and append to the existing rows.
   *
   * @return {BaseDataTable}
   */
  this.loadMore = function() {
    this.isLoadingMore = true;
    this.scrollTop = this.getScrollBody().scrollTop();

    const currentPage = this.dataTable.page();
    this.dataTable.page(currentPage + 1).draw('full-hold');

    return this;
  };

  /**
   * Determine whether the entire dataset has been loaded from the server.
   *
   * @return {boolean}
   */
  this.allLoaded = function() {
    this.isLoadingMore = false;
    this.fadeOutLoadingMoreSpinner();
    this.fadeOutLoadingOverlay();

    if (!this.pagingEnabled) {
      return true;
    }

    const info = this.dataTable.page.info();

    return info.pages === info.page + 1 || info.pages === 0;
  };

  /**
   * Fade in loading overlay.
   *
   * @return {BaseDataTable}
   */
  this.fadeInLoadingOverlay = function() {
    const scrollTop = this.getScrollBody().scrollTop();

    this.getLoadingOverlay()
      .addClass('in')
      .css('top', `${scrollTop}px`)
      .css('bottom', `-${scrollTop}px`);

    this.setScrollingEnabled(false);

    return this;
  };

  /**
   * Fade out loading overlay.
   *
   * @return {BaseDataTable}
   */
  this.fadeOutLoadingOverlay = function() {
    this.getLoadingOverlay().removeClass('in');

    this.setScrollingEnabled(true);

    return this;
  };

  /**
   * Fade in loading more spinner.
   *
   * @return {BaseDataTable}
   */
  this.fadeInLoadingMoreSpinner = function() {
    this.getLoadingMoreSpinner().addClass('in');

    return this;
  };

  /**
   * Fade out loading more spinner.
   *
   * @return {BaseDataTable}
   */
  this.fadeOutLoadingMoreSpinner = function() {
    this.getLoadingMoreSpinner().removeClass('in');

    return this;
  };

  /**
   * Determine whether the table has no data rows.
   *
   * @return {boolean}
   */
  this.isEmpty = function() {
    const rows = $('tbody tr', this.tableEl);

    if (rows.length > 1) {
      return false;
    }

    if (1 === rows.length) {
      return $('td.dataTables_empty', rows).length > 0;
    }

    if (0 === rows.length) {
      return true;
    }

    return false;
  };

  /**
   * Get table data.
   *
   * @return {object[]}
   */
  this.getTableData = function() {
    const data = this.dataTable.rows().data();
    const records = [];

    _.each(data, (r) => {
      records.push(r);
    }, this);

    return records;
  };

  /**
   * Set page length.
   *
   * @param  {number}        pageLength
   * @return {BaseDataTable}
   */
  this.setPageLength = function(pageLength) {
    this.pagingEnabled = pageLength > 0;

    // Reload table with new page length
    this.dataTable.page.len(pageLength || -1).draw();

    // Update load more button label
    this.getLoadMoreButton().html(this.getLoadMoreButtonLabel());

    return this;
  };

  /**
   * Enable pagination.
   *
   * @return {BaseDataTable}
   */
  this.enablePagination = function() {
    this.setPageLength(this.options.dataTable.pageLength);

    return this;
  };

  /**
   * Disable pagination.
   *
   * @return {BaseDataTable}
   */
  this.disablePagination = function() {
    this.setPageLength(null);

    return this;
  };

  /**
   * Get row record.
   *
   * @param  {DOMElement}             tr
   * @return {BaseEntity|object|null}
   */
  this.getRowRecord = function(tr) {
    const rowData = this.getRowData(tr);

    if (!_.isObject(rowData)) {
      return null;
    }

    return this.entityHelper.get(rowData, this.options.entityClass);
  };

  /**
   * Get row data.
   *
   * @param  {DOMElement} tr
   * @return {object}
   */
  this.getRowData = function(tr) {
    let dataRow = tr;

    if (dataRow.hasClass('child')) {
      dataRow = dataRow.prev();
    }

    return this.dataTable.row(dataRow).data();
  };

  /**
   * Set row data.
   *
   * @param  {DOMElement}    tr
   * @param  {object}        data
   * @return {BaseDataTable}
   */
  this.setRowData = function(tr, data) {
    if (tr.length === 0) {
      return this;
    }

    this.dataTable.row(tr).data(
      this.entityHelper.get(data, this.options.entityClass),
    );

    if (tr.hasClass('active')) {
      $('a.showRecord', tr).addClass('active');
    }

    return this.initRow(tr, data);
  };

  /**
   * Update row data.
   *
   * @param  {DOMElement}    tr
   * @param  {object}        data
   * @return {BaseDataTable}
   */
  this.updateRowData = function(tr, data) {
    return this.setRowData(tr, Object.extend(
      this.getRowData(tr),
      data,
      false,
    ));
  };

  /**
   * Find row by record identifier, then update its data.
   *
   * @param  {number|string} recordIdentifier
   * @param  {object}        data
   * @return {BaseDataTable}
   */
  this.updateRowDataByIdentifier = function(recordIdentifier, data) {
    return this.updateRowData(this.getRow(recordIdentifier), data);
  };

  /**
   * Render responsive button.
   *
   * @return {string}
   */
  this.renderResponsiveBtn = function() {
    return '<span class="stopPropagation table_responsive_bt">...</span>';
  };

  /**
   * Enable/disable table scrolling.
   *
   * @param  {boolean}       enabled
   * @return {BaseDataTable}
   */
  this.setScrollingEnabled = function(enabled) {
    this.getScrollBody().toggleClass('no-scroll-y', !enabled);

    return this;
  };

  /**
   * Get DataTables API.
   *
   * @return {DataTables.Api}
   */
  this.getApi = function() {
    return this.dataTable;
  };

  /**
   * Get header table element.
   *
   * @return {DOMElement}
   */
  this.getHeaderTableEl = function() {
    return $('.dataTables_scrollHead table', this.tableCt);
  };

  /**
   * Get footer table element.
   *
   * @return {DOMElement}
   */
  this.getFooterTableEl = function() {
    return $('.dataTables_scrollFoot table', this.tableCt);
  };

  /**
   * Get table wrapper.
   *
   * @return {DOMElement}
   */
  this.getWrapper = function() {
    return this.tableEl.closest('.dataTables_wrapper');
  };

  /**
   * Get scroll wrapper.
   *
   * @return {DOMElement}
   */
  this.getScrollWrapper = function() {
    return this.tableEl.closest('.dataTables_scroll');
  };

  /**
   * Get scroll body wrapper.
   *
   * @return {DOMElement}
   */
  this.getScrollBodyWrapper = function() {
    return this.tableEl.closest('.wrapp_scroll_table');
  };

  /**
   * Get scroll header DOM element.
   *
   * @return {DOMElement}
   */
  this.getScrollHeader = function() {
    return this.getScrollWrapper().find('.dataTables_scrollHead');
  };

  /**
   * Get scroll footer DOM element.
   *
   * @return {DOMElement}
   */
  this.getScrollFooter = function() {
    return this.getScrollWrapper().find('.dataTables_scrollFoot');
  };

  /**
   * Get scroll body DOM element.
   *
   * @return {DOMElement}
   */
  this.getScrollBody = function() {
    return this.tableEl.closest('.dataTables_scrollBody');
  };

  /**
   * Get loading overlay.
   *
   * @return {DOMElement}
   */
  this.getLoadingOverlay = function() {
    return this.getScrollBody().find('.wrapp_load.fade');
  };

  /**
   * Get loading more spinner.
   *
   * @return {DOMElement}
   */
  this.getLoadingMoreSpinner = function() {
    return this.getScrollBody().find('.loader.fade');
  };

  /**
   * Get load more button.
   *
   * @return {DOMElement}
   */
  this.getLoadMoreButton = function() {
    return this.getScrollBody().find('.loadMoreRecords');
  };

  /**
   * Get group row.
   *
   * @param  {DOMElement} tr
   * @return {DOMElement}
   */
  this.getGroupRow = function(tr) {
    const groupId = tr.attr('data-group');
    return $(`tr.groupRow[data-id="${groupId}"]`, this.tableEl);
  };

  /**
   * Get empty table content.
   *
   * @return {string}
   */
  this.getEmptyTableContent = function() {
    return `
      <div class="empty-ct">
        <span>No matching records found.</span>
      </div>
    `;
  };

  /**
   * Get column count.
   *
   * @return {number}
   */
  this.getColumnCount = function() {
    return this.getColumns().length;
  };

  /**
   * Get the number of records currently loaded and displayed.
   *
   * @return {number}
   */
  this.getRecordCount = function() {
    return $('tr.jsMainRow', this.tableEl).length;
  };

  /**
   * Get the total number of (filtered) records passed from the server.
   *
   * @return {number}
   */
  this.getTotalRecordCount = function() {
    const info = this.dataTable.page.info();
    return info.recordsDisplay;
  };

  /**
   * Is event target.
   *
   * @param  {Event}   event
   * @return {boolean}
   */
  this.isEventTarget = function(event) {
    return $(event.target).is(this.tableEl);
  };

  /**
   * Get column widths.
   *
   * @return {array}
   */
  this.getColumnWidths = function() {
    const widths = [];

    $('thead tr th', this.getTableEl()).each(function() {
      widths.push($(this).outerWidth());
    });

    return widths;
  };

  /**
   * Get (last request) AJAX data.
   *
   * @return {object}
   */
  this.getAjaxData = function() {
    return this.ajaxData;
  };

  /**
   * Set active records count.
   *
   * @param  {number}        count
   * @return {BaseDataTable}
   */
  this.setActiveRecordsCount = function(count) {
    this.activeRecordsCount = count;

    return this;
  };

  /**
   * Get active records count.
   *
   * @return {number}
   */
  this.getActiveRecordsCount = function() {
    return this.activeRecordsCount;
  };

  /**
   * Set active filtered records count.
   *
   * @param  {number}        count
   * @return {BaseDataTable}
   */
  this.setActiveFilteredRecordsCount = function(count) {
    this.activeFilteredRecordsCount = count;

    return this;
  };

  /**
   * Get active filtered records count.
   *
   * @return {number}
   */
  this.getActiveFilteredRecordsCount = function() {
    return this.activeFilteredRecordsCount;
  };

  /**
   * Get active displayed records count.
   *
   * @return {number}
   */
  this.getActiveDisplayedRecordsCount = function() {
    return this.tableEl
      .find('tr.jsMainRow[data-identifier]')
      .not(this.options.excludeRejectedFromSelection ? '.rejected' : '')
      .not(this.options.excludeFromSelectionClasses)
      .length;
  };

  /**
   * Get checkbox column.
   *
   * @return {object}
   */
  this.getCheckboxColumn = function() {
    return {
      className: 'check_item',
      type: TableColumnRenderHelper.TYPE_CHECKBOX,
    };
  };

  /**
   * Get responsive button column.
   *
   * @return {object}
   */
  this.getResponsiveButtonColumn = function() {
    return {
      type: TableColumnRenderHelper.TYPE_RESPONSIVE_BTN,
    };
  };

  /**
   * Get load more button label.
   *
   * @return {string}
   */
  this.getLoadMoreButtonLabel = function() {
    return this.translator.get('load_more');
  };

  /**
   * Get element(s) whose height will be subtracted from screen height to get
   * the table height.
   *
   * @return {DOMElement} Collection of jQuery element(s).
   */
  this.getMinusHeightElements = function() {
    return $(this.options.minusHeightSelector);
  };

  /**
   * Get error message from AJAX response.
   *
   * @param  {?object} jqXHR
   * @return {string}
   */
  this.getErrorMessageFromAjaxResponse = function(jqXHR) {
    const responseCode = _.isObject(jqXHR) ? jqXHR.status : null;

    switch (responseCode) {
      case 401:
      case 403:
        return this.translator.get('error_access_denied');
      case 404:
        return this.translator.get('error_resource_not_found');
      case 422:
        return this.translator.get('error_validation_error');
      case 522:
      case 524:
        return this.translator.get('error_timeout_error');
      default:
        return this.translator.get('error_server_error');
    }
  };
}

export default BaseDataTable;
