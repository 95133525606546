import BaseDataTable from 'js/base_v2/data-table';
/**
 * Base Entity Table.
 *
 * @class
 * @abstract
 * @extends BaseDataTable
 *
 * @param {DOMElement} tableEl
 * @param {object}     [options]
 */
function BaseEntityTable(tableEl, options) {
    BaseDataTable.call(this, tableEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {BaseEntityConfig}
     */
    this.entityConfig = null;

    /**
     * @inheritDoc
     */
    this.init = function() {
        this.entityConfig = this.getEntityConfig();

        return parent.init.call(this);
    };

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            entityClass  : this.entityConfig.get('entityClass'),
            rendererClass: this.entityConfig.get('rendererClass'),
            loadUrl      : this.entityConfig.get('listUrl')
        });
    };

    /**
     * Get entity config.
     *
     * @abstract
     *
     * @return {BaseEntityConfig}
     */
    this.getEntityConfig = null;
}

export default BaseEntityTable;
