import BaseObject from 'js/base_v3/objects/object';
/**
 * Base Config.
 *
 * @class
 * @abstract
 * @extends BaseObject
 *
 * @param {object} [options]
 */
function BaseConfig(options) {
    BaseObject.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * Alias for "getOption".
     *
     * @param  {string} key
     * @return {*}
     */
    this.get = function(key) {
        return this.getOption(key);
    };
}

export default BaseConfig;
