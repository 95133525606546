function BaseEntity(data) {
    var self = this;

    /**
     * @prop {object}
     */
    this.data = {};

    /**
     * @prop {string[]}
     */
    this.modifiedFields = [];

    /**
     * @prop {object}
     */
    this.originalValues = {};

    /**
     * Initialize.
     *
     * @return {BaseEntity}
     */
    this.init = function() {
        return this
            .setData(data)
            .clearModifiedFields();
    };

    /**
     * Get data.
     *
     * @return {object}
     */
    this.getData = function() {
        return this.data;
    };

    /**
     * Set data.
     *
     * @param  {object}     data
     * @return {BaseEntity}
     */
    this.setData = function(data) {
        this.data = this.data.extend(data);

        _.each(data, function(value, field) {
            this.set(field, value);
        }, this);

        return this;
    };

    /**
     * Set field value.
     *
     * @param  {string}     field
     * @param  {*}          value
     * @return {BaseEntity}
     */
    this.set = function(field, value) {
        this.setOriginalValue(field);

        this.data[field] = value;
        this[field] = value;
        this.modifiedFields.push(field);

        return this;
    };

    /**
     * Set original values.
     *
     * @param  {string}     field
     * @return {BaseEntity}
     */
    this.setOriginalValue = function(field) {
        if (!_.isUndefined(this.originalValues[field])) {
            return this;
        }

        this.originalValues[field] = this[field];

        return this;
    };

    /**
     * Restore original values.
     *
     * @return {BaseEntity}
     */
    this.restore = function() {
        _.each(this.originalValues, function(value, field) {
            this.data[field] = value;
            this[field] = value;
        }, this);

        return this.clearModifiedFields();
    };

    /**
     * Get modified field values.
     *
     * @return {object}
     */
    this.getModifiedFieldValues = function() {
        var modifiedFieldValues = {};

        _.each(this.modifiedFields, function(modifiedField) {
            modifiedFieldValues[modifiedField] = this[modifiedField];
        }, this);

        return modifiedFieldValues;
    };

    /**
     * Clear modified fields.
     *
     * @return {BaseEntity}
     */
    this.clearModifiedFields = function() {
        this.modifiedFields = [];
        this.originalValues = {};

        return this;
    };
}

export default BaseEntity;
