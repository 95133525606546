import BaseObject from 'js/base_v2/object';
import Translator from 'js/translator';
/**
 * Base Helper.
 *
 * @class
 * @abstract
 * @extends BaseObject
 *
 * @param {?object} options
 */
function BaseHelper(options) {
    BaseObject.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {Translator}
     */
    this.translator = null;

    /**
     * @inheritDoc
     */
    this.initProps = function() {
        parent.initProps.call(this);

        this.translator = new Translator();

        return this;
    };
}

export default BaseHelper;
