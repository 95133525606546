import ModalForm from 'js/base_v2/modal-form';
import Ajax from 'js/components/ajax';
import ConfirmationForm from 'js/components/confirmation-form';
import { notifier } from 'js/components_v2/default-notifier';

/**
 * Base Save Form.
 *
 * @class
 * @abstract
 * @extends ModalForm
 *
 * @param {object} [record]
 * @param {object} [options]
 */
function BaseSaveForm(record, options) {
  ModalForm.call(this, options, true);
  const parent = this.clone();
  const self = this;

  /**
   * @const
   */
  this.ACTION_TYPE_ADD = 'add';

  /**
   * @const
   */
  this.ACTION_TYPE_CREATE = 'create';

  /**
   * @const
   */
  this.ACTION_TYPE_UPDATE = 'update';

  /**
   * @const
   */
  this.FIELD_TYPE_PRICE = 'price';

  /**
   * @const
   */
  this.FIELD_TYPE_QUANTITY = 'quantity';

  /**
   * @const
   */
  this.FIELD_TYPE_QUANTITY_PER_MEASURE = 'quantity_per_measure';

  /**
   * @const
   */
  this.FIELD_TYPE_SELECT = 'select';

  /**
   * @const
   */
  this.FIELD_TYPE_TEXT = 'text';

  /**
   * @const
   */
  this.FIELDS = [];

  /**
   * @prop {object}
   */
  this.record = record || null;

  /**
   * @prop {object}
   */
  this.originalRecord = Object.clone(this.record);

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'record',
      // entitiesName: 'records',
      entityLabel: 'record',
      // entitiesLabel: 'records',
      actionType: 'create', // ['create', 'update', 'add', 'set']
      actionUrl: undefined,
      restoreUrl: undefined,
      // closeAfterSubmit: true,
      // formCt: null,
      // renderType: 'modal',
      removeBtnLabel: undefined,
      restoreBtnLabel: undefined,
      focusFirstNonEmptyField: true,
      removeBtnEnabled: this.getRemoveBtnEnabled(),
      removeBtnHidden: this.getRemoveBtnHidden(),
      defaultRecord: {},
      originalDefaultRecord: {},
      data: {},
      hiddenFields: [],
      lockedFields: [],
      disabledFields: [],
      requiredFields: [],
      focusFieldActionTypes: [
        this.ACTION_TYPE_CREATE,
        this.ACTION_TYPE_ADD,
      ],
      serverParams: {},
      // triggerBtn: null,
      removeConfirmationClass: undefined,
      removeConfirmation: {
        onCancel() {
          self.onRemoveCancel();
        },
        onAction(response) {
          self.onRemove(response);
        },
      },
      onRemove: undefined,
      onRestore: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    if (_.isNull(this.options.hiddenFields)) {
      this.options.hiddenFields = [];
    }

    this.options = Object.extend(this.options, {
      originalDefaultRecord: this.options.defaultRecord,
    }, true, false);

    return this
      .initActionType()
      .initFieldRecords();
  };

  /**
   * @inheritDoc
   */
  this.processFormData = function(formData) {
    let processedFormData = formData;

    $('input[type="checkbox"]:enabled', this.formEl).each(function() {
      if (0 == $(this).data('process')) { // eslint-disable-line eqeqeq
        return;
      }

      const name = $(this).attr('name');

      if (!_.isString(name) || _.isEmpty(name)) {
        return;
      }

      const value = $(this).prop('checked') ? 1 : 0;

      processedFormData = processedFormData.extend(name.toObject(value));
    });

    return processedFormData;
  };

  /**
   * @inheritDoc
   */
  this.prepareServerParams = function() {
    parent.prepareServerParams.call(this);

    const params = {};
    const formRecord = this.getRecord();

    if (null !== formRecord && !_.isUndefined(formRecord.id)) {
      const obj = {};
      obj[this.options.entityName] = {};
      obj[this.options.entityName].id = formRecord.id;

      this.options.serverParams = Object.extend(
        this.options.serverParams,
        obj,
      );
    }

    this.options.serverParams =
      Object.extend(this.options.serverParams, params, true, false);

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.formEl.on('click', '.switchTo', function(e) {
      e.preventDefault();
      self.onSwitchToBtnClick($(this));
    });

    this.formEl.on('click', '.remove', (e) => {
      e.preventDefault();
      self.onRemoveBtnClick();
    });

    this.formEl.on('click', '.restore', (event) => {
      this.onRestoreBtnClick(event);
    });

    this.formEl.on('click', '.jsRemoveRowBtn', (e) => {
      e.preventDefault();

      self.destroy();

      new ConfirmationForm(self.record, {
        actionType: 'remove',
        serverParams: Object.extend({
        }, self.options.serverParams),
        tmplParams: {
          actionUrl: self.options.deleteUrl,
        },
        afterSubmit() {
          self.options.afterDelete();
        },
      }).create();
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initFieldFocus = function() {
    if (!_.contains(this.options.focusFieldActionTypes, this.getActionType())) {
      return this;
    }

    return parent.initFieldFocus.call(this);
  };

  /**
   * Initialize field records.
   *
   * @return {BaseSaveForm}
   */
  this.initFieldRecords = function() {
    return this;
  };

  /**
   * Create field.
   *
   * @param  {string}       name
   * @param  {object}       opts
   * @return {BaseSaveForm}
   */
  this.createField = function(name, opts) {
    const fieldName = `${name}Field`;
    const fieldClass = this.options[`${fieldName}Class`];

    this[fieldName] = new fieldClass(
      $(this.getFieldCtSelector(name), this.formEl),
      $(this.getFieldSelector(name), this.formEl),
      this.options[fieldName].extend(opts || {}),
    ).create();

    return this;
  };

  /**
   * Get field container selector.
   *
   * @param  {string} name
   * @return {string}
   */
  this.getFieldCtSelector = function(name) {
    if (_.isUndefined(this.options[`${name}FieldCtSelector`])) {
      return this.getDefaultFieldCtSelector(name);
    }

    return this.options[`${name}FieldCtSelector`];
  };

  /**
   * Get field selector.
   *
   * @param {string} name
   * @return {string}
   */
  this.getFieldSelector = function(name) {
    if (_.isUndefined(this.options[`${name}Selector`])) {
      return this.getDefaultFieldSelector(name);
    }

    return this.options[`${name}Selector`];
  };

  /**
   * Get default field container selector.
   *
   * @param  {string} name
   * @return {string}
   */
  this.getDefaultFieldCtSelector = function(name) {
    return `.${name}FieldCt`;
  };

  /**
   * Get default field selector.
   *
   * @param  {string} name
   * @return {string}
   */
  this.getDefaultFieldSelector = function(name) {
    return `${this.getFieldCtSelector(name)} .${name}Field`;
  };

  /**
   * Switch to button event handler.
   *
   * @param  {DOMElement}        btn
   * @return {boolean|undefined}
   */
  this.onSwitchToBtnClick = function(btn) {
    const targetName = btn.attr('data-target-name');

    if (_.isFunction(this.options.onSwitchTo)) {
      const ret = this.options.onSwitchTo(targetName);

      if (false === ret) {
        return ret;
      }
    }

    return undefined;
  };

  /**
   * Remove button click event handler.
   */
  this.onRemoveBtnClick = function() {
    this.removeRecord();
  };

  /**
   * Restore button click event handler.
   *
   * @param {Event} event
   */
  this.onRestoreBtnClick = function(event) {
    event.preventDefault();

    this.restoreRecord();
  };

  /**
   * Remove record.
   *
   * @return {BaseSaveForm}
   */
  this.removeRecord = function() {
    if (_.isUndefined(this.options.removeConfirmationClass)) {
      throw new Error('BaseSaveForm.removeRecord is not implemented.');
    }

    if (!_.isObject(this.record)) {
      return this;
    }

    this.hide();

    return this.createRemoveConfirmation();
  };

  /**
   * Create remove confirmation.
   *
   * @return {BaseSaveForm}
   */
  this.createRemoveConfirmation = function() {
    new this.options.removeConfirmationClass(
      this.record,
      this.options.removeConfirmation,
    ).create();

    return this;
  };

  /**
   * Restore (current) record.
   *
   * @return {BaseSaveForm}
   */
  this.restoreRecord = function() {
    if (_.isUndefined(this.options.restoreUrl)) {
      throw new Error('BaseSaveForm.restoreRecord is not implemented.');
    }

    if (!_.isObject(this.record)) {
      return this;
    }

    this.hide();

    notifier.notifyLoading(
      `${this.translator.get('loading_prefix_restore')} ${this.getLabel()}...`,
    );

    Ajax.post(
      this.options.restoreUrl,
      { ids: [this.record.id] },
      (response) => {
        this.onRestoreSuccess(response);
      },
      (response, error) => {
        this.onRestoreError(error);
      },
    );

    return this;
  };

  /**
   * Restore success event handler.
   *
   * @param  {object}          response
   * @return {false|undefined}
   */
  this.onRestoreSuccess = function(response) {
    if (_.isFunction(this.options.onRestore) &&
      false === this.options.onRestore(response)
    ) {
      return false;
    }

    notifier.notifySuccess(
      // eslint-disable-next-line max-len
      `${this.getLabel().capitalize()} ${this.translator.get('success_suffix_restored')}.`,
    );

    return undefined;
  };

  /**
   * Restore error event handler.
   *
   * @param {string} error
   */
  this.onRestoreError = function(error) {
    notifier.notifyError(
      // eslint-disable-next-line max-len
      `${this.translator.get('error_prefix_restore')} ${this.getLabel()}: ${error}`,
    );
  };

  /**
   * @inheritDoc
   */
  this.prepareTmplParams = function() {
    parent.prepareTmplParams.call(this);

    const formRecord = this.getRecord();

    const params = {
      actionType: this.options.actionType,
      actionLabel: this.getActionLabel(),
      // renderType: this.options.renderType,
      entityName: this.options.entityName,
      entityLabel: this.options.entityLabel,
      title: this.getTitle(),
      subtitle: this.getSubtitle(),
      submitBtnLabel: this.getSubmitBtnLabel(),
      deleteBtnLabel: this.getDeleteBtnLabel(),
      removeBtnLabel: this.getRemoveBtnLabel(),
      restoreBtnLabel: this.getRestoreBtnLabel(),
      hiddenFields: this.options.hiddenFields,
      lockedFields: this.options.lockedFields,
      disabledFields: this.options.disabledFields,
      requiredFields: this.options.requiredFields,
      removeBtnEnabled: this.options.removeBtnEnabled,
      removeBtnHidden: this.options.removeBtnHidden,
      record: null !== formRecord ? formRecord : this.options.defaultRecord,
    };

    this.options.tmplParams.hiddenFields = undefined;
    this.options.tmplParams.lockedFields = undefined;
    this.options.tmplParams.disabledFields = undefined;
    this.options.tmplParams.requiredFields = undefined;
    this.options.tmplParams.record = undefined;

    this.options.tmplParams = Object.extend(
      this.options.tmplParams,
      params,
    );

    return this;
  };

  /**
   * Remove cancel event handler.
   */
  this.onRemoveCancel = function() {
    this.show();
  };

  /**
   * Remove event handler.
   *
   * @param  {object}            response
   * @return {boolean|undefined}
   */
  this.onRemove = function(response) {
    if (_.isFunction(this.options.onRemove) &&
      false === this.options.onRemove(response)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Show or hide remove button based on form state.
   *
   * @return {this}
   */
  this.showOrHideRemoveBtn = function() {
    const removeBtnWrapper = $('.removeBtnWrapper', this.formEl);

    if (this.getRemoveBtnHidden()) {
      removeBtnWrapper.hide();
    } else {
      removeBtnWrapper.show();
    }

    return this;
  };

  /**
   * Get record.
   *
   * @return {object}
   */
  this.getRecord = function() {
    return this.record;
  };

  /**
   * Set record.
   *
   * @param  {object}       newRecord
   * @return {BaseSaveForm}
   */
  this.setRecord = function(newRecord) {
    this.record = newRecord;
    this.originalRecord = Object.clone(this.record);

    return this;
  };

  /**
   * Update record.
   *
   * @param  {object}       data
   * @return {BaseSaveForm}
   */
  this.updateRecord = function(data) {
    this.record = Object.extend(this.record, data);
    return this;
  };

  /**
   * Update default record.
   *
   * @param  {object}       data
   * @return {BaseSaveForm}
   */
  this.updateDefaultRecord = function(data) {
    this.options.defaultRecord = this.options.defaultRecord.extend(data);
    return this;
  };

  /**
   * Get entity or entities label.
   */
  this.getLabel = function() {
    return this.options.entityLabel;
  };

  /**
   * Get action label.
   */
  this.getActionLabel = function() {
    return this.options.actionType;
  };

  /**
   * Get form title.
   *
   * @return {string}
   */
  this.getTitle = function() {
    return `${this.getActionLabel()} ${this.getLabel()}`.capitalizeWords();
  };

  /**
   * Get subtitle.
   *
   * @return {string}
   */
  this.getSubtitle = function() {
    return '';
  };

  /**
   * Get submit button label.
   *
   * @return {string}
   */
  this.getSubmitBtnLabel = function() {
    return this.getTitle();
  };

  /**
   * Get remove button enabled.
   *
   * @return {boolean}
   */
  this.getRemoveBtnEnabled = function() {
    return true;
  };

  /**
   * Get remove button hidden.
   *
   * @return {boolean}
   */
  this.getRemoveBtnHidden = function() {
    return !this.getRemoveBtnEnabled();
  };

  /**
   * Get delete button label.
   *
   * @return {string}
   */
  this.getDeleteBtnLabel = function() {
    return `Delete ${this.getLabel().capitalizeWords()}`;
  };

  /**
   * Get remove button label.
   *
   * @return {string}
   */
  this.getRemoveBtnLabel = function() {
    return this.options.removeBtnLabel ||
      `Remove ${this.getLabel().capitalizeWords()}`;
  };

  /**
   * Get restore button label.
   *
   * @return {string}
   */
  this.getRestoreBtnLabel = function() {
    return this.options.restoreBtnLabel ||
      // eslint-disable-next-line max-len
      `${this.translator.getTitle('label_restore')} ${this.getLabel().capitalizeWords()}`;
  };

  /**
   * @inheritDoc
   */
  this.getBeforeSubmitMessage = function() {
    const actions = {
      add: 'adding',
      create: 'creating',
      update: 'updating',
      set: 'setting',
      clone: 'cloning',
      assign: 'assigning',
      copy: 'copying',
      search: 'searching',
    };
    const actionName = actions[this.options.actionType].capitalize();

    return `${actionName} ${this.getLabel()}...`;
  };

  /**
   * @inheritDoc
   */
  this.getAfterSubmitMessage = function() {
    const actions = {
      add: 'added',
      create: 'created',
      update: 'updated',
      set: 'set',
      clone: 'cloned',
      assign: 'assigned',
    };
    const label = this.getLabel().capitalize();
    const action = actions[this.options.actionType];

    return `${label} successfully ${action}.`;
  };

  /**
   * @inheritDoc
   */
  this.saveState = function() {
    const formData = this.getFormData(false, true);
    let formRecord = this.getRecord();

    if (null === formRecord) {
      this.options.defaultRecord = Object.extend(
        this.options.defaultRecord,
        formData[this.options.entityName],
      );
    } else {
      formRecord = Object.extend(
        formRecord,
        formData[this.options.entityName],
      );

      this.setRecord(formRecord);
    }

    return this;
  };

  /**
   * Save field state.
   *
   * @param  {string}       fieldName
   * @param  {string}       [fieldGroupName]
   * @return {BaseSaveForm}
   */
  this.saveFieldState = function(fieldName, fieldGroupName) {
    const field = _.isString(fieldGroupName) ?
      this[fieldGroupName].fields[fieldName] :
      this[fieldName];

    let fieldRecords = field.getRecord();

    if (!_.isArray(fieldRecords)) {
      fieldRecords = [fieldRecords];
    }

    fieldRecords = fieldRecords.filter((rec) => _.isObject(rec));

    let newOptions = {};

    const fieldSelectedRecords = Object.createFromKey(
      fieldName,
      { selectedRecords: fieldRecords },
    );

    if (_.isString(fieldGroupName)) {
      newOptions = Object.createFromKey(
        fieldGroupName,
        fieldSelectedRecords,
      );
    } else {
      newOptions = fieldSelectedRecords;
    }

    this.extendOptions(newOptions);

    return this;
  };

  /**
   * Reset form state.
   *
   * @param  {object}       [opts]
   * @return {BaseSaveForm}
   */
  this.resetState = function(opts = {}) {
    const formOpts = {
      clearDefaultRecord: false,
    }.extend(opts);

    const formRecord = this.getRecord();

    if (null === formRecord) {
      this.options.originalDefaultRecord = formOpts.clearDefaultRecord ?
        {} :
        this.options.originalDefaultRecord;

      this.options.defaultRecord =
        Object.clone(this.options.originalDefaultRecord);
    } else {
      this.setRecord(this.originalRecord);
    }

    return this;
  };

  /**
   * Initialize action type.
   *
   * @return {BaseSaveForm}
   */
  this.initActionType = function() {
    if (this.ACTION_TYPE_CREATE === this.options.actionType &&
      _.isObject(this.getRecord())
    ) {
      this.options.actionType = this.ACTION_TYPE_UPDATE;
    }

    return this;
  };

  /**
   * Get action type.
   *
   * @return {string}
   */
  this.getActionType = function() {
    return this.options.actionType;
  };

  /**
   * Set action type.
   *
   * @param  {string}       actionType
   * @return {BaseSaveForm}
   */
  this.setActionType = function(actionType) {
    this.options.actionType = actionType;

    return this
      .renderTitle()
      .renderSubtitle()
      .renderSubmitBtnLabel();
  };

  /**
   * Render title.
   *
   * @param  {string}       title
   * @return {BaseSaveForm}
   */
  this.renderTitle = function(title) {
    $('.title', this.formEl).html(title || this.getTitle());

    return this;
  };

  /**
   * Render subtitle.
   *
   * @param  {string}       subtitle
   * @return {BaseSaveForm}
   */
  this.renderSubtitle = function(subtitle) {
    $('.subtitle', this.formEl).html(subtitle || this.getSubtitle());

    return this;
  };

  /**
   * Render submit button label.
   *
   * @param  {string}       label
   * @return {BaseSaveForm}
   */
  this.renderSubmitBtnLabel = function(label) {
    $('button[type="submit"]', this.formEl)
      .html(label || this.getSubmitBtnLabel());

    return this;
  };

  /**
   * Set field values.
   *
   * @param  {string[]}      [skipFields]
   * @return {LaborSaveForm}
   */
  this.setFieldValues = function(skipFields) {
    const skipFieldsValidated = _.isArray(skipFields) ? skipFields : [];
    const formRecord = this.getRecord();

    _.each(this.FIELDS, function(field) {
      if (_.contains(skipFieldsValidated, field.name)) {
        return;
      }

      const name = `${this.options.entityName}[${field.name}]`;
      const value = _.isObject(formRecord) ?
        formRecord[field.property_name] :
        null;

      const inputSelector = `input[name="${name}"]`;
      const textareaSelector = `textarea[name="${name}"]`;
      const selectSelector = `select[name="${name}"]`;
      const selector = `${inputSelector},${textareaSelector},${selectSelector}`;

      $(selector, this.formEl).val(this.formatFieldValue(field, value));
    }, this);

    return this.setCustomFieldValues(skipFieldsValidated);
  };

  /**
   * Set custom field values.
   *
   * @return {LaborSaveForm}
   */
  this.setCustomFieldValues = function() {
    return this;
  };

  /**
   * Format field value.
   *
   * @param  {object} field
   * @param  {*}      value
   * @return {string}
   */
  this.formatFieldValue = function(field, value) {
    switch (field.type) {
      case this.FIELD_TYPE_PRICE:
      case this.FIELD_TYPE_QUANTITY:
        return value > 0 ? parseFloat(value).toFixed(2) : null;
      case this.FIELD_TYPE_QUANTITY_PER_MEASURE:
        return value > 0 ? parseInt(value, 10) : null;
      case this.FIELD_TYPE_SELECT:
      case this.FIELD_TYPE_TEXT:
      default:
        return value;
    }
  };

  /**
   * Add field to hidden fields.
   *
   * @param  {string}       fieldName
   * @return {BaseSaveForm}
   */
  this.addToHiddenFields = function(fieldName) {
    if (-1 !== this.options.hiddenFields.indexOf(fieldName)) {
      // Field already present in hidden fields
      return this;
    }

    this.options.hiddenFields.push(fieldName);

    return this;
  };

  /**
   * Remove field from hidden fields.
   *
   * @param  {string}       fieldName
   * @return {BaseSaveForm}
   */
  this.removeFromHiddenFields = function(fieldName) {
    const index = this.options.hiddenFields.indexOf(fieldName);

    if (-1 === index) {
      // Field not present in hidden fields
      return this;
    }

    this.options.hiddenFields.splice(index, 1);

    return this;
  };
}

export default BaseSaveForm;
