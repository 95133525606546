import ModalForm from 'js/base_v2/modal-form';

export default function ConfirmationForm(record, options) {
    ModalForm.call(this, options, true);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {object}
     */
    this.record = record;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.defaultOptions = Object.extend(this.defaultOptions, {
             tmplEl: '#components-modal-confirmation',
             tmplParams: {
                questionText: 'Are you sure?',
                confirmText: 'Yes, remove it',
                cancelText: 'No, cancel'
             }
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.processOptions = function() {
        parent.processOptions.call(this);

        if (_.isString(this.options.tmplEl)) {
            this.options.tmplEl = $(this.options.tmplEl);
        }

        return this;
    };

    this.init();
};
