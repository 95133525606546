import BaseComponent from 'js/base_v2/component';

/**
 * Base Filter.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {DOMElement} filterEl
 * @param {object}     [options]
 */
function BaseFilter(filterEl, options) {
  BaseComponent.call(this, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {DOMElement}
   */
  this.filterEl = filterEl;

  /**
   * @prop {function[]}
   */
  this.listeners = [];

  /**
   * @prop {int}
   */
  this.inputTimeout = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      defaultValue: undefined,
      initialValue: undefined,
      enabled: true,
      triggerEvent: 'change input',
      inputDelay: 250,
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    return this.processInitialValueOptions();
  };

  /**
   * Process initial value options.
   *
   * @return {BaseFilter}
   */
  this.processInitialValueOptions = function() {
    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    if (_.isString(this.filterEl)) {
      this.filterEl = $(this.filterEl);
    }

    return this
      .initializeValue()
      .createField()
      .registerEventListeners();
  };

  /**
   * Initialize filter value.
   *
   * @return {BaseFilter}
   */
  this.initializeValue = function() {
    return this;
  };

  /**
   * Create filter field.
   *
   * @return {BaseFilter}
   */
  this.createField = function() {
    return this;
  };

  /**
   * Register event listeners.
   *
   * @return {BaseFilter}
   */
  this.registerEventListeners = function() {
    this.filterEl.on(this.options.triggerEvent, (event) => {
      if ('input' === event.type) {
        clearTimeout(self.inputTimeout);

        self.inputTimeout = setTimeout(() => {
          self.trigger(self);
        }, self.options.inputDelay);
      } else {
        self.trigger(self);
      }
    });

    return this;
  };

  /**
   * Clear filter.
   *
   * @return {BaseFilter}
   */
  this.clear = function() {
    return this;
  };

  /**
   * Reset filter.
   *
   * @return {BaseFilter}
   */
  this.reset = function() {
    return this;
  };

  /**
   * Get filter value.
   *
   * @return {*}
   */
  this.getValue = function() {
    return this.filterEl.val();
  };

  /**
   * Get filter full value.
   *
   * @return {*}
   */
  this.getFullValue = function() {
    return this.getValue();
  };

  /**
   * Get filter name.
   *
   * @return {string}
   */
  this.getName = function() {
    return this.options.name;
  };

  /**
   * Trigger filter change.
   *
   * @param  {BaseFilter} sender
   * @return {BaseFilter}
   */
  this.trigger = function(sender) {
    clearTimeout(self.inputTimeout);

    _.each(this.listeners, function(listener) {
      listener(sender, this);
    }, this);

    return this;
  };

  /**
   * Add listener.
   *
   * @param  {function}   listener
   * @return {BaseFilter}
   */
  this.addListener = function(listener) {
    this.listeners.push(listener);
    return this;
  };

  /**
   * Determin whether the filter is enabled or not.
   *
   * @return {boolean}
   */
  this.isEnabled = function() {
    return this.options.enabled;
  };

  /**
   * Set enabled.
   *
   * @param  {boolean}    enabled
   * @return {BaseFilter}
   */
  this.setEnabled = function(enabled) {
    this.options.enabled = enabled;
    return this;
  };
}

export default BaseFilter;
