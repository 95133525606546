import BaseHelper from 'js/helpers/base/helper';
import TableColumnRenderHelper from 'js/helpers/table/column-render-helper';
var staticSelf = TableColumnHelper;

/**
 * @const
 */
staticSelf.CLASS_ALIGN_LEFT = 'text_left';

/**
 * @const
 */
staticSelf.CLASS_ALIGN_CENTER = 'text_center';

/**
 * @const
 */
staticSelf.CLASS_ALIGN_RIGHT = 'text_right';

/**
 * @prop {string[]}
 * @static
 */
staticSelf.alignmentClassNames = [
    staticSelf.CLASS_ALIGN_LEFT,
    staticSelf.CLASS_ALIGN_CENTER,
    staticSelf.CLASS_ALIGN_RIGHT
];

/**
 * Table Column Helper.
 *
 * @class
 * @extends BaseHelper
 *
 * @param {?object} options
 */
function TableColumnHelper(options) {
    BaseHelper.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {TableColumnRenderHelper}
     */
    this.columnRenderHelper = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            hiddenColumns: [],
            columnRenderHelper: {}
        });
    };

    /**
     * @inheritDoc
     */
    this.initProps = function() {
        parent.initProps.call(this);

        this.columnRenderHelper = new TableColumnRenderHelper(
            this.options.columnRenderHelper
        );

        return this;
    };

    /**
     * Process columns.
     *
     * @param  {object[]} columns
     * @return {object[]}
     */
    this.processColumns = function(columns) {
        _.each(columns, function(column) {
            column = this.processColumn(column);
        }, this);

        return columns;
    };

    /**
     * Process column.
     *
     * @param  {object} column
     * @return {object}
     */
    this.processColumn = function(column) {
        column = this.processColumnClassName(column);
        column = this.processColumnType(column);
        column = this.processColumnData(column);
        column = this.processColumnRender(column);

        return column;
    };

    /**
     * Process column class name.
     *
     * @param  {object} column
     * @return {object}
     */
    this.processColumnClassName = function(column) {
        column.className = column.className || '';

        if (!_.isUndefined(column.name) &&
            _.contains(this.options.hiddenColumns, column.name)
        ) {
            // Add hidden class
            column = this.addClass(column, 'hide');
        }

        if (TableColumnRenderHelper.TYPE_CHECKBOX === column.type) {
            // Add checkbox specific class
            column = this.addClass(column, 'check_item');
        }

        if (TableColumnRenderHelper.TYPE_RESPONSIVE_BTN === column.type) {
            // Add responsive button specific classes
            column = this.addClass(column, 'all responsive_cell jsTableResponsiveBt');
        }

        if (!this.hasAlignmentClass(column)) {
            // Add alignment class
            column = this.addClass(column, this.determineAlignmentClass(column));
        }

        return column;
    };

    /**
     * Process column type.
     *
     * @param  {object} column
     * @return {object}
     */
    this.processColumnType = function(column) {
        if (!_.isUndefined(column.type)) {
            return column;
        }

        column.type = TableColumnRenderHelper.DEFAULT_TYPE;

        return column;
    };

    /**
     * Process column data.
     *
     * @param  {object} column
     * @return {object}
     */
    this.processColumnData = function(column) {
        if (!_.isUndefined(column.data)) {
            return column;
        }

        column.data = null;

        return column;
    };

    /**
     * Process column render.
     *
     * @param  {object} column
     * @return {object}
     */
    this.processColumnRender = function(column) {
        return this.columnRenderHelper.processRender(column);
    };

    /**
     * Determine whether column has alignment class.
     *
     * @param  {object}  column
     * @return {boolean}
     */
    this.hasAlignmentClass = function(column) {
        var classes = this.getClasses(column);

        return !_.isEmpty(_.intersection(
            classes,
            staticSelf.alignmentClassNames
        ));
    };

    /**
     * Get column classes.
     *
     * @param  {object}   column
     * @return {string[]}
     */
    this.getClasses = function(column) {
        return column.className.split(' ').filter(function(piece) {
            return '' !== piece;
        });
    };

    /**
     * Add column class.
     *
     * @param  {object} column
     * @param  {string} className
     * @return {object}
     */
    this.addClass = function(column, className) {
        if (_.isEmpty(className)) {
            return column;
        }

        column.className = !_.isEmpty(column.className) ?
            (column.className + ' ' + className) : className;

        return column;
    };

    /**
     * Determine alignment class based on column type.
     *
     * @param  {object} column
     * @return {string}
     */
    this.determineAlignmentClass = function(column) {
        switch (column.type) {
            case TableColumnRenderHelper.TYPE_TEXT:
                return staticSelf.CLASS_ALIGN_LEFT;
            case TableColumnRenderHelper.TYPE_DATETIME:
            case TableColumnRenderHelper.TYPE_INT:
            case TableColumnRenderHelper.TYPE_QUANTITY:
                return staticSelf.CLASS_ALIGN_RIGHT;
            case TableColumnRenderHelper.TYPE_CHECKBOX:
            case TableColumnRenderHelper.TYPE_RESPONSIVE_BTN:
            default:
                return '';
        }
    };

    // Initialize
    this.init();
}

export default TableColumnHelper;
