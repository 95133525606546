import Quill from 'components/quill/quill-modified';
import BaseComponent from 'js/base_v2/component';

/**
 * Quill Textarea.
 *
 * @class
 * @extends BaseComponent
 *
 * @param {DOMElement} container
 * @param {object}     [options]
 */
function QuillTextarea(container, options) {
  BaseComponent.call(this, options);
  const parent = this.clone();

  /**
   * @prop {DOMElement}
   */
  this.container = container;

  /**
   * @prop {Quill}
   */
  this.quill = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      initialContent: null,
      quillEditor: {
        theme: 'snow',
        readOnly: false,
        modules: {
          toolbar: this.getToolbarConfig(),
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);
    return this.createQuillEditor();
  };

  /**
   * Get toolbar config.
   *
   * @return {array}
   */
  this.getToolbarConfig = function() {
    return [
      [{ header: ['1', '2', '3', false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ];
  };

  /**
   * Create quill editor.
   *
   * @return {QuillTextarea}
   */
  this.createQuillEditor = function() {
    this.quill = new Quill(
      this.container[0],
      this.options.quillEditor,
    );

    if (_.isString(this.options.initialContent)) {
      this.setContent(this.options.initialContent);
    }

    return this;
  };

  /**
   * Set content.
   *
   * @param  {string}        content
   * @return {QuillTextarea}
   */
  this.setContent = function(content) {
    if (!_.isString(content) || _.isEmpty(content)) {
      return this;
    }

    this.quill.root.innerHTML = content;

    return this;
  };

  /**
   * Get content.
   *
   * @return {string}
   */
  this.getContent = function() {
    return this.quill.root.innerHTML;
  };

  /**
   * Get content as plain text.
   *
   * @return {string}
   */
  this.getPlainText = function() {
    const content = this.getContent();

    return content.replace(/<[^>]*>/g, '');
  };

  /**
   * @return {string}
   */
  this.getContentWithWhitespaces = function() {
    const contentPlainText = this.getPlainText().trim();

    return _.isEmpty(contentPlainText) ?
      contentPlainText :
      this.getContent().split('  ').join(' &nbsp;');
  };

  // Initialize
  this.init();
}

export default QuillTextarea;
