import BaseExtension from 'js/base_v3/extensions/extension';

/**
 * Field Extension.
 *
 * @class
 * @extends BaseExtension
 *
 * @param {object}  parentComponent
 * @param {object}  [options]
 */
function FieldExtension(parentComponent, options) {
    BaseExtension.call(this, parentComponent, options);
    const parent = this.clone();
    const self = this;

    /**
     * @inheritDoc
     */
    this._componentType = BaseExtension.COMPONENT_TYPE_FIELD;

    /**
     * Save field state.
     *
     * @param  {string}         name
     * @return {FieldExtension}
     */
    this.saveFieldState = function(name) {
        const field = this._getComponent(name);
        let fieldRecords = field.getRecord();

        if (!_.isArray(fieldRecords)) {
            fieldRecords = [fieldRecords];
        }

        fieldRecords = fieldRecords.filter(function(fieldRecord) {
            return _.isObject(fieldRecord);
        });

        this._parentComponent.extendOptions(Object.createFromKey(
            this._getComponentOptionsKey(name),
            { selectedRecords: fieldRecords }
        ));

        return this;
    };

    // Initialize
    this._init();
}

export default FieldExtension;
