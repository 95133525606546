import BaseHelper from 'js/helpers/base/helper';

/**
 * Entity Helper.
 *
 * @class
 * @extends BaseHelper
 *
 * @param {object} [options]
 */
function EntityHelper(options) {
  BaseHelper.call(this, options);

  /**
   * Get entity record as entity object (e.g. transform from plain Object
   * to Quote).
   *
   * @param  {BaseEntity|object|null} entityRecord
   * @param  {*}                      entityClass
   * @return {BaseEntity|null}
   */
  this.get = function(entityRecord, entityClass) {
    if (null === entityRecord ||
      !_.isFunction(entityClass) ||
      entityRecord instanceof entityClass
    ) {
      return entityRecord;
    }

    if (!_.isObject(entityRecord)) {
      throw new Error('Invalid entity record provided.');
    }

    return new entityClass(entityRecord);
  };

  // Initialize
  this.init();
}

export const entityHelper = new EntityHelper();

export default EntityHelper;
