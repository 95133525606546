import BaseComponent from 'js/base_v2/component';
import BaseDataTable from 'js/base_v2/data-table';
import DefaultNotifier from 'js/components_v2/default-notifier';
import EntityHelper from 'js/helpers/entity-helper';
import UrlHelper from 'js/helpers/url-helper';
import { storageHelper } from 'js/helpers/storage-helper';

/**
 * Base List.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {DOMElement} listCt
 * @param {DOMElement} tableEl
 * @param {DOMElement} [detailsCt]
 * @param {object}     [options]
 */
function BaseList(listCt, tableEl, detailsCt, options) {
  BaseComponent.call(this, options);
  const parent = this.clone();
  const self = this;

  /**
   * @const {string}
   */
  this.DETAILS_TYPE_STANDALONE = 'standalone';

  /**
   * @const {string}
   */
  this.DETAILS_TYPE_COLLAPSE = 'collapse';

  /**
   * @const {string}
   */
  this.OPENED_ROW_CLASS_NAME = 'opened';

  /**
   * @const {string}
   */
  this.OPENED_ROW_SELECTOR = 'tbody tr.opened';

  /**
   * @prop {DOMElement}
   */
  this.listCt = listCt;

  /**
   * @prop {DOMElement}
   */
  this.tableEl = tableEl;

  /**
   * @prop {DOMElement}
   */
  this.detailsCt = detailsCt;

  /**
   * @prop {object}
   */
  this.defaultOptions = {};

  /**
   * @prop {object}
   */
  this.options = options || {};

  /**
   * @prop {DefaultNotifier}
   */
  this.notifier = null;

  /**
   * @prop {BaseTable}
   */
  this.table = null;

  /**
   * @prop {BaseDetails}
   */
  this.details = null;

  /**
   * @prop {object}
   */
  this.detailsMap = {};

  /**
   * @prop {FilterGroup}
   */
  this.filterGroup = null;

  /**
   * @prop {FilterGroup}
   */
  this.viewByGroup = null;

  /**
   * @prop {BaseSelectionPanel}
   */
  this.selectionPanel = null;

  /**
   * @prop {BaseSaveForm}
   */
  this.saveForm = null;

  /**
   * @prop {BaseFormGroup}
   */
  this.saveFormGroup = null;

  /**
   * @prop {BaseSaveForm}
   */
  this.addForm = null;

  /**
   * @prop {BaseFormGroup}
   */
  this.addFormGroup = null;

  /**
   * @prop {boolean}
   */
  this.showDetailsOnTableDraw = false;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      activeRecord: undefined,
      entityName: 'record',
      entityLabel: 'record',
      entityClass: undefined,
      // editable: this.isEditable(),
      editAllowed: true,
      isGlobal: false,
      editNotAllowedMessage: undefined,
      tableClass: undefined,
      filterGroupClass: undefined,
      filterGroupCt: undefined,
      viewByGroupClass: undefined,
      viewByGroupCt: undefined,
      detailsClass: undefined,
      rowDetailsClass: undefined,
      selectionPanelClass: undefined,
      selectionPanel: this.getDefaultSelectionPanelOptions(),
      saveFormClass: undefined,
      saveFormGroupClass: undefined,
      addFormClass: undefined,
      addFormGroupClass: undefined,
      addFormGroupMainForm: undefined,
      // removeConfirmationClass: undefined,
      // removeFromConfirmationClass: undefined,
      loadUrl: undefined,
      loadParams: {},
      saveUrl: undefined,
      addUrl: undefined,
      // removeUrl: undefined,
      // removeFromUrl: undefined,
      table: this.getDefaultTableOptions(),
      pageSize: 15,
      filterGroup: this.getDefaultFilterGroupOptions(),
      viewByGroup: this.getDefaultViewByGroupOptions(),
      details: this.getDefaultDetailsOptions(),
      rowDetails: this.getDefaultRowDetailsOptions(),
      detailsType: this.DETAILS_TYPE_STANDALONE,
      reloadOnSave: true,
      showDetailsOnSave: true,
      showDetailsOnAdd: true,
      showDetailsOnTableDraw: true,
      showDetailsOnTableReload: true,
      saveFilterSelection: false,
      filterSelectionNamespace: undefined,
      filterSelectionTtlInMin: 10,
      saveForm: this.getDefaultSaveFormOptions(),
      saveFormGroup: this.getDefaultSaveFormGroupOptions(),
      addForm: this.getDefaultAddFormOptions(),
      addFormGroup: this.getDefaultAddFormGroupOptions(),
      // removeConfirmation: this.getDefaultRemoveConfirmationOptions(),
      // removeFromConfirmation: this.getDefaultRemoveFromConfirmationOptions()
      onFilterUpdate: undefined,
      onTableDraw: undefined,
      onTableAjax: undefined,
      onTableReload: undefined,
      onTableRowClick: undefined,
      onTableRowReorder: undefined,
      onTableScroll: undefined,
      entityHelper: {},
    });
  };

  /**
   * Process options.
   *
   * @return {BaseList}
   */
  this.processOptions = function() {
    this
      .processTableOptions()
      .processFilterGroupOptions()
      .processViewByGroupOptions()
      .processDetailsOptions()
      .processSaveFormOptions()
      .processSaveFormGroupOptions()
      .processAddFormOptions()
      .processAddFormGroupOptions();
    // .processRemoveConfirmationOptions()
    // .processRemoveFromConfirmationOptions()

    return this;
  };

  /**
   * Get default table options.
   *
   * @return {object}
   */
  this.getDefaultTableOptions = function() {
    return {
      onInitComplete(settings) { return self.onTableInitComplete(settings); },
      onDraw() { self.onTableDraw(); },
      onAjax(response) { self.onTableAjax(response); },
      onCreatedRow(tr, record, index) {
        self.onTableCreatedRow(tr, record, index);
      },
      onInitRow(tr, record) { self.onTableInitRow(tr, record); },
      onInitRowComponents(tr, record) {
        self.onTableInitRowComponents(tr, record);
      },
      onRowClick(tr) { self.onTableRowClick(tr); },
      onSelectionChange() { self.onTableSelectionChange(); },
      onRowReorder(e, diff, edit) { self.onTableRowReorder(e, diff, edit); },
      onAdjustColumns() { self.onTableAdjustColumns(); },
      onFirstLoad() { self.onTableFirstLoad(); },
      onScroll(scrollBody) { self.onTableScroll(scrollBody); },
    };
  };

  /**
   * Get default filter group options.
   *
   * @return {object}
   */
  this.getDefaultFilterGroupOptions = function() {
    return {};
  };

  /**
   * Get default view by group options.
   *
   * @return {object}
   */
  this.getDefaultViewByGroupOptions = function() {
    return {};
  };

  /**
   * Get default details options.
   *
   * @return {object}
   */
  this.getDefaultDetailsOptions = function() {
    return {};
  };

  /**
   * Get default row details options.
   *
   * @return {object}
   */
  this.getDefaultRowDetailsOptions = function() {
    return {};
  };

  /**
   * Get default save form options.
   *
   * @return {object}
   */
  this.getDefaultSaveFormOptions = function() {
    return {};
  };

  /**
   * Get default save form group options.
   *
   * @return {object}
   */
  this.getDefaultSaveFormGroupOptions = function() {
    return {};
  };

  /**
   * Get default add form options.
   *
   * @return {object}
   */
  this.getDefaultAddFormOptions = function() {
    return {};
  };

  /**
   * Get default add form group options.
   *
   * @return {object}
   */
  this.getDefaultAddFormGroupOptions = function() {
    return {};
  };

  /**
   * Get default selection panel options.
   *
   * @return {object}
   */
  this.getDefaultSelectionPanelOptions = function() {
    return {
      onActionSuccess() {
        self.onSelectionPanelActionSuccess();
      },
      onHide(animate) {
        return self.onSelectionPanelHide(animate);
      },
    };
  };

  /**
   * Get default remove confirmation options.
   *
   * @return {object}
   */
  // this.getDefaultRemoveConfirmationOptions = function() {
  // return {
  // afterRemove: function(response) {
  // self.reload();
  // }
  // };
  // };

  /**
   * Get default remove from confirmation options.
   *
   * @return {object}
   */
  // this.getDefaultRemoveFromConfirmationOptions = function() {
  // return this.getDefaultRemoveConfirmationOptions();
  // };

  /**
   * Process table options.
   *
   * @return {BaseList}
   */
  this.processTableOptions = function() {
    this.options.table = Object.extend(this.options.table, {
      loadUrl: this.options.loadUrl,
      loadParams: this.options.loadParams,
    }, true, false);

    return this;
  };

  /**
   * Process filter group options.
   *
   * @return {BaseList}
   */
  this.processFilterGroupOptions = function() {
    return this.extendOptions({
      filterGroup: {
        defaultValues: this.getDefaultFilterValues(),
        initialValues: this.getInitialFilterValues(),
      },
    });
  };

  /**
   * Process view by group options.
   *
   * @return {BaseList}
   */
  this.processViewByGroupOptions = function() {
    return this.extendOptions({
      viewByGroup: {
        initialValues: this.getInitialViewByValues(),
      },
    });
  };

  /**
   * Process details options.
   *
   * @return {BaseList}
   */
  this.processDetailsOptions = function() {
    return this;
  };

  /**
   * Process save form options.
   *
   * @return {BaseList}
   */
  this.processSaveFormOptions = function() {
    this.options.saveForm = Object.extend(this.options.saveForm, {
      actionUrl: this.options.saveUrl,
    }, true, false);

    return this;
  };

  /**
   * Process save form group options.
   *
   * @return {BaseList}
   */
  this.processSaveFormGroupOptions = function() {
    return this;
  };

  /**
   * Process add form options.
   *
   * @return {BaseList}
   */
  this.processAddFormOptions = function() {
    this.options.addForm = Object.extend(this.options.addForm, {
      actionUrl: this.options.addUrl,
    }, true, false);

    return this;
  };

  /**
   * Process add form group options.
   *
   * @return {BaseList}
   */
  this.processAddFormGroupOptions = function() {
    return this;
  };

  /**
   * Process remove confirmation options.
   */
  // this.processRemoveConfirmationOptions = function() {
  // if (!_.isUndefined(this.options.removeUrl) &&
  // _.isUndefined(this.options.removeConfirmation.removeUrl)
  // ) {
  // this.options.removeConfirmation.removeUrl = this.options.removeUrl;
  // }
  // };

  /**
   * Process remove from confirmation options.
   */
  // this.processRemoveFromConfirmationOptions = function() {
  // if (!_.isUndefined(this.options.removeFromUrl) &&
  // _.isUndefined(this.options.removeFromConfirmation.removeUrl)
  // ) {
  // this.options.removeFromConfirmation.removeUrl =
  // this.options.removeFromUrl;
  // }
  // };

  /**
   * Get save form options.
   *
   * @param  {string}  actionType ['add', 'create', 'update', 'remove']
   * @param  {?object} record
   * @return {object}
   */
  // eslint-disable-next-line no-unused-vars
  this.getSaveFormOptions = function(actionType, record) {
    const afterSubmit = function(response) {
      if (_.isFunction(self.options.saveForm.afterSubmit)) {
        const ret =
          self.options.saveForm.afterSubmit(response, actionType);

        if (false === ret) {
          return ret;
        }
      }

      if (self.options.reloadOnSave) {
        const responseRecord = response[self.options.entityName];

        self.reload(() => {
          if (self.options.showDetailsOnSave) {
            self.showActiveRecordDetails(responseRecord);
          }

          return false;
        });
      }

      return undefined;
    };

    return Object.extend(this.options.saveForm, {
      actionType: actionType || undefined,
      afterSubmit,
      onRemove() { self.reload(); },
    });
  };

  /**
   * Get save form group options.
   *
   * @param  {string} actionType ['add', 'create', 'update', 'remove']
   * @return {object}
   */
  this.getSaveFormGroupOptions = function(actionType) {
    const afterSubmit = function(response) {
      if (_.isFunction(self.options.saveFormGroup.afterSubmit)) {
        const ret = self.options.saveFormGroup.afterSubmit(
          response,
          actionType,
        );

        if (false === ret) {
          return ret;
        }
      }

      if (self.options.reloadOnSave) {
        const record = response[self.options.entityName];

        self.reload(() => {
          if (self.options.showDetailsOnSave) {
            self.showActiveRecordDetails(record);
          }

          return false;
        });
      }

      return undefined;
    };

    const saveFormGroupOptions = { afterSubmit };
    const { saveFormGroupMainForm } = this.options;

    if (saveFormGroupMainForm) {
      saveFormGroupOptions[saveFormGroupMainForm] = {
        onRemove() { self.reload(); },
        actionType: actionType || undefined,
      };
    }

    return Object.extend(this.options.saveFormGroup, saveFormGroupOptions);
  };

  /**
   * Get add form options.
   *
   * @param  {string} actionType ['add', 'create', 'update', 'remove']
   * @return {object}
   */
  this.getAddFormOptions = function(actionType) {
    const afterSubmit = function(response) {
      if (_.isFunction(self.options.addForm.afterSubmit)) {
        const ret =
          self.options.addForm.afterSubmit(response, actionType);

        if (false === ret) {
          return ret;
        }
      }

      const record = response[self.options.entityName];

      self.reload(() => {
        if (self.options.showDetailsOnAdd) {
          self.showActiveRecordDetails(record);
        }

        return false;
      });

      return undefined;
    };

    return Object.extend(this.options.addForm, {
      actionType: actionType || undefined,
      afterSubmit,
      onRemove() { self.reload(); },
    });
  };

  /**
   * Get add form group options.
   *
   * @param  {string} actionType ['add', 'create', 'update', 'remove']
   * @return {object}
   */
  this.getAddFormGroupOptions = function(actionType) {
    const afterSubmit = function(response) {
      if (_.isFunction(self.options.addFormGroup.afterSubmit)) {
        const ret = self.options.addFormGroup.afterSubmit(
          response,
          actionType,
        );

        if (false === ret) {
          return ret;
        }
      }

      const record = response[self.options.entityName];

      self.reload(() => {
        if (self.options.showDetailsOnAdd) {
          self.showActiveRecordDetails(record);
        }

        return false;
      });

      return undefined;
    };

    const addFormGroupOptions = { afterSubmit };
    const { addFormGroupMainForm } = this.options;

    if (addFormGroupMainForm) {
      addFormGroupOptions[addFormGroupMainForm] = {
        onRemove() { self.reload(); },
        actionType: actionType || undefined,
      };
    }

    return Object.extend(this.options.addFormGroup, addFormGroupOptions);
  };

  /**
   * @inheritDoc
   */
  this.initProps = function() {
    parent.initProps.call(this);

    this.entityHelper = new EntityHelper(this.options.entityHelper);

    return this;
  };

  /**
   * Create list.
   *
   * @return {BaseList}
   */
  this.create = function() {
    if (_.isString(this.listCt)) {
      this.listCt = $(this.listCt);
    }

    this
      .createTable()
      .createFilterGroup()
      .createViewByGroup()
      .createSelectionPanel()
      .createDetails()
      .createSubcomponents()
      .registerEventListeners()
      .reload();

    return this;
  };

  /**
   * Destroy list.
   *
   * @return {BaseList}
   */
  this.destroy = function() {
    this.table.destroy();

    return this;
  };

  /**
   * Create table.
   *
   * @return {BaseList}
   */
  this.createTable = function() {
    if (_.isUndefined(this.options.tableClass)) {
      throw new Error('BaseList.createTable is not implemented.');
    }

    this.table = new this.options.tableClass(
      this.tableEl,
      this.options.table,
    );

    this.table.create();

    this.tableEl = this.table.getTableEl();

    return this;
  };

  /**
   * Create filter group.
   *
   * @return {BaseList}
   */
  this.createFilterGroup = function() {
    if (!_.isFunction(this.options.filterGroupClass) ||
      _.isUndefined(this.options.filterGroupCt)
    ) {
      return this;
    }

    this.filterGroup = new this.options.filterGroupClass(
      this.options.filterGroupCt,
      this.options.filterGroup,
    ).create();

    this.setFilters(this.filterGroup.getValues());

    return this;
  };

  /**
   * Create view by group.
   *
   * @return {BaseList}
   */
  this.createViewByGroup = function() {
    if (!_.isFunction(this.options.viewByGroupClass) ||
      _.isUndefined(this.options.viewByGroupCt)
    ) {
      return this;
    }

    this.viewByGroup = new this.options.viewByGroupClass(
      this.options.viewByGroupCt,
      this.options.viewByGroup,
    ).create();

    this.setViewBy(this.viewByGroup.getValues());

    return this;
  };

  /**
   * Create selection panel.
   *
   * @return {BaseList}
   */
  this.createSelectionPanel = function() {
    if (_.isUndefined(this.options.selectionPanelClass)) {
      return this;
    }

    this.selectionPanel = new this.options.selectionPanelClass(
      this.options.selectionPanel.extend(
        this.getAdditionalSelectionPanelOptions(),
      ),
    ).create();

    return this;
  };

  /**
   * Get additional selection panel options.
   *
   * @return {object}
   */
  this.getAdditionalSelectionPanelOptions = function() {
    return {
      afterUpdate() {
        self.table.reload(null, false);
      },
    };
  };

  /**
   * Create details.
   *
   * @return {BaseList}
   */
  this.createDetails = function() {
    if (!_.isFunction(this.options.detailsClass)) {
      return this;
    }

    this.details = new this.options.detailsClass(
      this.detailsCt,
      null,
      this.options.details,
    );

    return this;
  };

  /**
   * Create row details.
   *
   * @param  {DOMElement}        container
   * @param  {BaseEntity|object} record
   * @return {BaseList}
   */
  this.createRowDetails = function(container, record) {
    new this.options.rowDetailsClass(
      container,
      this.options.rowDetails.extend({
        data: {
          record,
        },
      }),
    ).create();

    return this;
  };

  /**
   * Create form.
   *
   * @param  {string}   name
   * @param  {?object}  record
   * @param  {object}   formOptions
   * @return {BaseList}
   */
  this.createForm = function(name, record = null, formOptions = {}) {
    const formName = `${name}Form`;
    const formClass = this.options[`${formName}Class`];

    this[formName] = new formClass(
      record,
      this.options[formName].extend(formOptions),
    ).create();

    return this;
  };

  /**
   * Create save form.
   *
   * @param  {string}   actionType ['add', 'create', 'update', 'remove']
   * @param  {object}   record
   * @return {BaseList}
   */
  this.createSaveForm = function(actionType, record) {
    if (_.isUndefined(this.options.saveFormClass)) {
      throw new Error('BaseList.createSaveForm is not implemented.');
    }

    if (_.isObject(record) && !this.isRecordEditAllowed(record)) {
      if (_.isString(this.options.editNotAllowedMessage)) {
        this.notifier.notifyWarning(this.options.editNotAllowedMessage);
      }

      return this;
    } if (!_.isObject(record) && !this.isRecordCreationAllowed()) {
      if (_.isString(this.options.editNotAllowedMessage)) {
        this.notifier.notifyWarning(this.options.editNotAllowedMessage);
      }

      return this;
    }

    this.saveForm = new this.options.saveFormClass(
      record,
      this.getSaveFormOptions(actionType, record),
    ).create();

    return this;
  };

  /**
   * Create save form group.
   *
   * @param  {string}   actionType ['add', 'create', 'update', 'remove']
   * @param  {object}   record
   * @param  {object}   saveFormGroupOptions
   * @return {BaseList}
   */
  this.createSaveFormGroup = function(
    actionType,
    record,
    saveFormGroupOptions = {},
  ) {
    if (_.isUndefined(this.options.saveFormGroupClass)) {
      throw new Error('BaseList.createSaveFormGroup is not implemented.');
    }

    this.saveFormGroup = new this.options.saveFormGroupClass(
      [],
      this.getSaveFormGroupOptions(actionType).extend(saveFormGroupOptions),
    ).create();

    return this;
  };

  /**
   * Create add form.
   *
   * @param  {string}      actionType ['add', 'create', 'update', 'remove']
   * @param  {object}      record
   * @return {BaseAddForm}
   */
  this.createAddForm = function(actionType, record) {
    if (_.isUndefined(this.options.addFormClass)) {
      throw new Error('BaseList.createAddForm is not implemented.');
    }

    this.addForm = new this.options.addFormClass(
      record,
      this.getAddFormOptions(actionType),
    ).create();

    return this;
  };

  /**
   * Create add form group.
   *
   * @param  {string}   actionType ['add', 'create', 'update', 'remove']
   * @param  {object}   record
   * @return {BaseList}
   */
  this.createAddFormGroup = function(actionType, record) {
    if (_.isUndefined(this.options.addFormGroupClass)) {
      throw new Error('BaseList.createAddFormGroup is not implemented.');
    }

    const addFormGroupRecords = {};

    if (record && this.options.addFormGroupMainForm) {
      addFormGroupRecords[this.options.addFormGroupMainForm] = record;
    }

    this.addFormGroup = new this.options.addFormGroupClass(
      addFormGroupRecords,
      this.getAddFormGroupOptions(actionType),
    ).create();

    return this;
  };

  /**
   * Create remove confirmation.
   *
   * @param  {object}                 record
   * @return {BaseRemoveConfirmation}
   */
  // this.createRemoveConfirmation = function(record) {
  // if (_.isUndefined(this.options.removeConfirmationClass)) {
  // throw 'BaseList.createRemoveConfirmation is not implemented.';
  // }

  // return new window[this.options.removeConfirmationClass](
  // record,
  // this.options.removeConfirmation
  // ).create();
  // };

  /**
   * Create remove from confirmation.
   *
   * @param  {object}                 record
   * @return {BaseRemoveConfirmation}
   */
  // this.createRemoveFromConfirmation = function(record) {
  // if (_.isUndefined(this.options.removeFromConfirmationClass)) {
  // throw 'BaseList.createRemoveFromConfirmation is not implemented.';
  // }

  // return new window[this.options.removeFromConfirmationClass](
  // record,
  // this.options.removeFromConfirmation
  // ).create();
  // };

  /**
   * Reload list.
   *
   * @param  {function} callback
   * @param  {boolean}  resetPaging
   * @param  {boolean}  keepScrolling
   * @return {BaseList}
   */
  this.reload = function(callback, resetPaging, keepScrolling) {
    if (this.DETAILS_TYPE_COLLAPSE === this.options.detailsType) {
      this.clearDetailsMap();
    }

    this.table.reload(() => {
      self.onTableReload(callback);
    }, resetPaging, keepScrolling);

    return this;
  };

  /**
   * Reload list and keep current scrolling.
   *
   * @param  {?function} callback
   * @param  {?boolean}  resetPaging
   * @return {BaseList}
   */
  this.reloadAndKeepScrolling = function(callback, resetPaging) {
    return this.reload(
      callback,
      resetPaging,
      BaseDataTable.SCROLL_TYPE_CURRENT,
    );
  };

  /**
   * Reload list and scroll to bottom.
   *
   * @param  {?function} callback
   * @param  {?boolean}  resetPaging
   * @return {BaseList}
   */
  this.reloadAndScrollToBottom = function(callback, resetPaging) {
    return this.reload(
      callback,
      resetPaging,
      BaseDataTable.SCROLL_TYPE_BOTTOM,
    );
  };

  /**
   * Action button click event handler.
   *
   * @param {string}     actionType ['add', 'create', 'update', 'remove']
   * @param {DOMElement} btn
   */
  this.onActionBtnClick = function(actionType, btn) {
    const tr = btn.closest('tr');
    const record = this.table.getRowData(tr);

    switch (actionType) {
      case 'add':
        this.createAddForm(actionType, record);
        break;
      case 'addGroup':
        this.createAddFormGroup('add', record);
        break;
      case 'create':
        this.createSaveForm();
        break;
      case 'createGroup':
        this.createSaveFormGroup('create');
        break;
      case 'update':
        this.createSaveForm(actionType, record);
        break;
      // case 'remove':
        // this.createRemoveConfirmation(record);
        // break;
      // case 'removeFrom':
        // this.createRemoveFromConfirmation(record);
        // break;
      case 'show':
        this.showDetails(record, tr);
        break;
      case 'loadMore':
        this.loadMore();
        break;
      default:
        // Do nothing
    }
  };

  /**
   * Toggle (show/hide) row details button click event handler.
   *
   * @param {DOMElement} button
   * @param {Event}      event
   */
  this.onToggleRowDetailsButtonClick = function(button, event) {
    event.preventDefault();
    this.toggleRowDetails(button.closest('tr'));
  };

  /**
   * Table load event handler.
   *
   * @param {object} response
   * @param {object} params {
   *                          actionType: ['add', 'create', 'update', 'remove'],
   *                          record: ...,
   *                        }
   */
  // this.onTableLoad = function(response, params) {
  // params = params || {};

  // this.highlightRecord(params.actionType, params.record);
  // this.showActiveRecordDetails(params.record);
  // };

  /**
   * Highlight record.
   *
   * @param {string} actionType ['add', 'create', 'update', 'remove']
   * @param {object} record
   */
  // this.highlightRecord = function(actionType, record) {
  // if (!actionType || !record) {
  // return;
  // }

  // var tdClass = '';

  // switch (actionType) {
  // case 'add':
  // case 'create':
  // tdClass = 'alert-success-cell';
  // break;
  // case 'update':
  // tdClass = 'alert-info-cell';
  // break;
  // }

  // $('tr.data-row', $(this.table.getTableEl())).each(function() {
  // var tr = $(this);

  // if (record.id == tr.data('row').id) {
  // $('td:first', tr).addClass(tdClass);
  // return false;
  // }
  // });
  // };

  /**
   * Show active record details.
   *
   * @param  {object}   record
   * @return {BaseList}
   */
  this.showActiveRecordDetails = function(record) {
    if (!_.isObject(this.details)) {
      return this;
    }

    if (this.table.isEmpty()) {
      return this.showDetails(null);
    }

    const activeRecord = record || this.details.getRecord();
    let found = false;

    if (null !== activeRecord) {
      $('tbody tr', this.getTableEl()).each(function() {
        const r = self.table.getRowData($(this));

        if (_.isObject(r) && +activeRecord.id === +r.id) {
          self.showDetails(r, $(this));
          found = true;

          return false;
        }

        return undefined;
      });
    }

    if (null === activeRecord || !found) {
      const tr = $('tbody tr:first', this.getTableEl());
      this.showDetails(this.table.getRowData(tr), tr);
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.createSubcomponents = function() {
    parent.createSubcomponents.call(this);

    this.notifier = new DefaultNotifier(this.options.notifier).create();

    return this;
  };

  /**
   * Register event listeners.
   *
   * @return {BaseList}
   */
  this.registerEventListeners = function() {
    const actionTypes = [
      'create',
      'createGroup',
      'add',
      'addGroup',
      'update',
      // 'remove',
      // 'removeFrom',
      'show',
      'loadMore',
    ];

    _.each(actionTypes, (actionType) => {
      const className = `${actionType}Record${
        _.contains(['loadMore'], actionType) ? 's' : ''}`;

      // eslint-disable-next-line no-shadow
      (function(actionType) {
        self.listCt.on('click', `.${className}`, function(e) {
          e.preventDefault();
          e.stopImmediatePropagation();

          self.onActionBtnClick(actionType, $(this));
        });
      }(actionType));
    });

    if (this.filterGroup) {
      this.filterGroup.addListener((filterGroup) => {
        self.onFilterUpdate(filterGroup);
      });
    }

    if (this.viewByGroup) {
      this.viewByGroup.addListener((viewByGroup) => {
        self.onViewByUpdate(viewByGroup);
      });
    }

    if (this.DETAILS_TYPE_COLLAPSE === this.options.detailsType) {
      this.registerDetailsCollapseListeners();
    }

    this.listCt.on('click', '.toggleRowDetails', function(event) {
      self.onToggleRowDetailsButtonClick($(this), event);
    });

    $('.pageSize').on('change', () => {
      self.table.setPageLength($('.pageSize').val() || self.options.pageSize);
      self.reload();
    });

    return this;
  };

  /**
   * Register details collapse event listeners.
   *
   * @return {BaseList}
   */
  this.registerDetailsCollapseListeners = function() {
    this.listCt.on('show.bs.collapse', '.detailsCt', function() {
      self.onDetailsCtShow($(this));
    });

    this.listCt.on('hidden.bs.collapse', '.detailsCt', function() {
      self.onDetailsCtHidden($(this));
    });

    return this;
  };

  /**
   * Table selection change event handler.
   */
  this.onTableSelectionChange = function() {
    if (!this.selectionPanel) {
      return;
    }

    if (this.table.getSelectionCount() > 0) {
      this.showSelectionPanel();
    } else {
      this.hideSelectionPanel();
    }
  };

  /**
   * Show selection panel.
   *
   * @return {BaseList}
   */
  this.showSelectionPanel = function() {
    const records = this.table.getSelectedRecords();
    let recordFilters;
    let recordsCount;

    switch (this.table.selectedRecordsMode) {
      case BaseDataTable.SELECTION_FILTERED:
        recordFilters = this.getFilteredSelectionFilters();
        recordsCount = this.table.getActiveFilteredRecordsCount();
        break;
      case BaseDataTable.SELECTION_ALL:
        recordFilters = this.getAllSelectionFilters();
        recordsCount = this.table.getActiveRecordsCount();
        break;
      default:
        recordFilters = null;
    }

    this.selectionPanel.show(
      records,
      recordFilters,
      this.table.selectedRecordsMode,
      recordsCount,
      this.table.getActiveRecordsCount(),
      this.table.excludeIds,
      this.getViewBy(),
    );

    return this;
  };

  /**
   * Get all selection record filters.
   *
   * @return {object}
   */
  this.getAllSelectionFilters = function() {
    const recordFilters = {
      filtered: 1,
      filters: {
        exclude_ids: this.table.excludeIds,
      },
    };

    return recordFilters;
  };

  /**
   * Get filtered selection record filters.
   *
   * @return {object}
   */
  this.getFilteredSelectionFilters = function() {
    let recordFilters = this.getAllSelectionFilters();

    if (_.isObject(this.viewByGroup)) {
      recordFilters = recordFilters.extend({
        viewBy: this.viewByGroup.getValues(),
      });
    }
    if (_.isObject(this.filterGroup)) {
      recordFilters = recordFilters.extend({
        filters: this.filterGroup.getValues(),
      });
    }

    return recordFilters;
  };

  /**
   * Hide selection panel.
   *
   * @return {BaseList}
   */
  this.hideSelectionPanel = function() {
    this.selectionPanel.hide();
    return this;
  };

  /**
   * Table row reorder event handler.
   *
   * @param  {Event}             e
   * @param  {object[]}          diff
   * @param  {object}            edit
   * @return {boolean|undefined}
   */
  this.onTableRowReorder = function(e, diff, edit) {
    if (_.isFunction(this.options.onTableRowReorder) &&
      false === this.options.onTableRowReorder(e, diff, edit)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Table adjust columns event handler.
   *
   * @return {boolean|undefined}
   */
  this.onTableAdjustColumns = function() {
    if (_.isFunction(this.options.onTableAdjustColumns) &&
      false === this.options.onTableAdjustColumns()
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Table initialize complete event handler.
   *
   * @param  {DataTables.Settings} settings
   * @return {boolean|undefined}
   */
  // eslint-disable-next-line no-unused-vars
  this.onTableInitComplete = function(settings) {
    return undefined;
  };

  /**
   * Table draw event handler.
   *
   * @return {boolean|undefined}
   */
  this.onTableDraw = function() {
    if (_.isFunction(this.options.onTableDraw)) {
      const ret = this.options.onTableDraw();

      if (false === ret) {
        return ret;
      }
    }

    this.initRows();

    if (this.showDetailsOnTableDraw) {
      if (this.options.showDetailsOnTableDraw) {
        this.showActiveRecordDetails();
      }

      this.showDetailsOnTableDraw = false;
    }

    this.toggleLoadMoreBtn();

    return undefined;
  };

  /**
   * Table reload event handler.
   *
   * @param  {function}          callback
   * @return {boolean|undefined}
   */
  this.onTableReload = function(callback) {
    if (_.isFunction(callback)) {
      if (false === callback()) {
        return false;
      }
    }

    if (_.isFunction(this.options.onTableReload)) {
      if (false === this.options.onTableReload(self)) {
        return false;
      }
    }

    if (this.options.showDetailsOnTableReload) {
      this.showActiveRecordDetails();
    }

    return undefined;
  };

  /**
   * Table first load event handler
   *
   * @return {undefined}
   */
  this.onTableFirstLoad = function() {
    if (this.options.activeRecord) {
      const row = this.table.getRecordRow(this.options.activeRecord);

      this.showDetails(
        this.options.activeRecord,
        row,
      );
    }
  };

  /**
   * Table scroll event handler.
   *
   * @param  {DOMElement}        scrollBody
   * @return {boolean|undefined}
   */
  this.onTableScroll = function(scrollBody) {
    if (_.isFunction(this.options.onTableScroll) &&
      false === this.options.onTableScroll(scrollBody)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Table AJAX complete event handler.
   *
   * @param  {object}            response
   * @return {boolean|undefined}
   */
  this.onTableAjax = function(response) {
    if (_.isFunction(this.options.onTableAjax)) {
      const ret = this.options.onTableAjax(response);

      if (false === ret) {
        return ret;
      }
    }

    return undefined;
  };

  /**
   * Table created row event handler.
   *
   * @param {DOMElement} tr
   * @param {object}     record
   * @param {number}     index
   */
  // eslint-disable-next-line no-unused-vars
  this.onTableCreatedRow = function(tr, record, index) {};

  /**
   * Table initialize row event handler.
   *
   * @param {DOMElement} tr
   * @param {object}     record
   */
  this.onTableInitRow = function(tr) {
    if (tr.hasClass('selected')) {
      $('.selectSingle .customControlIndicator', tr)
        .addClass('checked');
    }
  };

  /**
   * Table initialize row components event handler.
   *
   * @param {DOMElement} tr
   * @param {object}     record
   */
  // eslint-disable-next-line no-unused-vars
  this.onTableInitRowComponents = function(tr, record) {};

  /**
   * Table row click event handler.
   *
   * @param {DOMElement} tr
   */
  this.onTableRowClick = function(tr) {
    if (_.isFunction(this.options.onTableRowClick) &&
      false === this.options.onTableRowClick(tr)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Details container show event handler.
   *
   * @param {DOMElement} detailsContainer
   */
  this.onDetailsCtShow = function(detailsContainer) {
    const tr = detailsContainer.closest('tr');
    const record = this.table.getRowData(tr);

    if (!_.isObject(this.detailsMap[record.id])) {
      this.detailsMap[record.id] = new this.options.detailsClass(
        detailsContainer,
        record,
        this.options.details,
      ).create();
    }

    this.showDetails(record, tr);
  };

  /**
   * Selection panel action succes event handler.
   */
  this.onSelectionPanelActionSuccess = function() {};

  /**
   * Selection panel hide event handler.
   */
  this.onSelectionPanelHide = function() {
    this.table.deselectAll(false);
    this.table.enableRowReorderIfAllowed();
  };

  /**
   * Details container hidden event handler.
   *
   * @param {DOMElement} detailsContainer
   */
  // eslint-disable-next-line no-unused-vars
  this.onDetailsCtHidden = function(detailsContainer) {};

  /**
   * Initialize table rows.
   *
   * @return {BaseList}
   */
  this.initRows = function() {
    $('tbody tr', this.getTableEl()).each(function() {
      self.initRow($(this));
    });

    return this;
  };

  /**
   * Initialize table row.
   *
   * @param  {DOMElement} tr
   * @return {BaseList}
   */
  // eslint-disable-next-line no-unused-vars
  this.initRow = function(tr) {
    return this;
  };

  /**
   * Toggle (show/hide) row details.
   *
   * @param  {DOMElement} tr
   * @return {BaseList}
   */
  this.toggleRowDetails = function(tr) {
    if (tr.hasClass('show_sub_tables')) {
      this.hideRowDetails(tr);
    } else {
      this.showRowDetails(tr);
    }

    return this;
  };

  /**
   * Toggle load more button.
   *
   * @return {BaseList}
   */
  this.toggleLoadMoreBtn = function() {
    const loadMoreCt = $('.loadMoreRecords', this.listCt)
      .closest('.loadMoreCt');

    if (this.table.allLoaded()) {
      loadMoreCt.hide();
    } else {
      loadMoreCt.show();
    }

    return this;
  };

  /**
   * Filter update event handler.
   *
   * @param {FilterGroup} filterGroup
   */
  this.onFilterUpdate = function(filterGroup) {
    if (_.isFunction(this.options.onFilterUpdate)) {
      this.options.onFilterUpdate(filterGroup);
    }

    this
      .setFilters(filterGroup.getValues())
      .reload(null, true);

    if (this.options.saveFilterSelection) {
      const expiry = moment().add(
        this.options.filterSelectionTtlInMin,
        'minutes',
      );

      storageHelper.save(
        this.options.filterSelectionNamespace,
        {
          filters: filterGroup.getFullValues(),
          expiry,
        },
      );
    }
  };

  /**
   * View by update event handler.
   *
   * @param {FilterGroup} viewByGroup
   */
  this.onViewByUpdate = function(viewByGroup) {
    this
      .setViewBy(viewByGroup.getValues())
      .reload(null, true);
  };

  /**
   * Adjust table height.
   *
   * @return {BaseList}
   */
  this.adjustTableHeight = function() {
    this.table.adjustHeight();
    return this;
  };

  /**
   * Adjust table columns.
   *
   * @return {BaseList}
   */
  this.adjustTableColumns = function() {
    this.table.adjustColumns();
    return this;
  };

  /**
   * Refresh double scroll (if enabled).
   *
   * @return {BaseList}
   */
  this.refreshDoubleScroll = function() {
    this.table.refreshDoubleScroll();
    return this;
  };

  /**
   * Load the next page of the search results and append to the current
   * result set.
   *
   * @return {BaseList}
   */
  this.loadMore = function() {
    if (_.isFunction(this.options.onLoadMore)) {
      const ret = this.options.onLoadMore();

      if (false === ret) {
        return ret;
      }
    }

    this.table.loadMore();
    this.showDetailsOnTableDraw = true;

    return this;
  };

  /**
   * Get filters.
   *
   * @return {object}
   */
  this.getFilters = function() {
    return this.table.getFilters();
  };

  /**
   * Set filters.
   *
   * @param  {object}   filters
   * @return {BaseList}
   */
  this.setFilters = function(filters) {
    this.table.setFilters(filters);
    return this;
  };

  /**
   * Get view by.
   *
   * @return {?object}
   */
  this.getViewBy = function() {
    return _.isObject(this.viewByGroup) ?
      this.viewByGroup.getValues() :
      null;
  };

  /**
   * Set view by.
   *
   * @param  {object}   viewBy
   * @return {BaseList}
   */
  this.setViewBy = function(viewBy) {
    this.table.setViewBy(viewBy);
    return this;
  };

  /**
   * Get row data.
   *
   * @param  {DOMElement} tr
   * @return {object}
   */
  this.getRowData = function(tr) {
    return this.table.getRowData(tr);
  };

  /**
   * Set row data.
   *
   * @param  {DOMElement} tr
   * @param  {object}     data
   * @return {BaseList}
   */
  this.setRowData = function(tr, data) {
    this.table.setRowData(
      tr,
      this.entityHelper.get(data, this.options.entityClass),
    );

    return this;
  };

  /**
   * Set record rows data.
   *
   * @param  {object[]} records
   * @return {BaseList}
   */
  this.setRecordRowsData = function(records) {
    _.each(records, (record) => {
      this.setRowData(this.table.getRecordRow(record), record);
    }, this);

    return this;
  };

  /**
   * Update row data.
   *
   * @param  {DOMElement} tr
   * @param  {object}     data
   * @return {BaseList}
   */
  this.updateRowData = function(tr, data) {
    this.table.updateRowData(
      tr,
      this.entityHelper.get(data, this.options.entityClass),
    );

    return this;
  };

  /**
   * Find row by record identifier, then update its data.
   *
   * @param  {number|string} recordIdentifier
   * @param  {object}        data
   * @return {BaseList}
   */
  this.updateRowDataByIdentifier = function(recordIdentifier, data) {
    this.table.updateRowDataByIdentifier(recordIdentifier, data);
  };

  /**
   * Get row record.
   *
   * @param  {DOMElement}             tr
   * @return {BaseEntity|object|null}
   */
  this.getRowRecord = function(tr) {
    const rowData = this.getRowData(tr);

    if (!_.isObject(rowData)) {
      return null;
    }

    return this.entityHelper.get(rowData, this.options.entityClass);
  };

  /**
   * Get table data.
   *
   * @return {object[]}
   */
  this.getTableData = function() {
    return this.table.getTableData();
  };

  /**
   * Show details.
   *
   * @param  {object}     record
   * @param  {DOMElement} tr
   * @return {BaseList}
   */
  this.showDetails = function(record, tr) {
    if (record) {
      const activeA = $('tbody tr a.active', this.getTableEl());
      const activeTr = activeA.closest('tr');

      if (activeTr.length > 0) {
        const activeRecord = this.table.getRowData(activeTr);

        if (this.getRecordUid(activeRecord) === this.getRecordUid(record)) {
          return this;
        }
      }

      activeTr.removeClass('active');
      tr.addClass('active');

      activeA.removeClass('active');
      $('a', tr).addClass('active');
    }

    switch (this.options.detailsType) {
      case this.DETAILS_TYPE_STANDALONE:
        this.details.load(record);
        break;
      case this.DETAILS_TYPE_COLLAPSE:
        this.detailsMap[record.id].load(record, this.options.entityName);
        break;
      default:
        // Do nothing
    }

    return this;
  };

  /**
   * Show row details.
   *
   * @param  {DOMElement} tr
   * @return {BaseList}
   */
  this.showRowDetails = function(tr) {
    this.table.disableRowReorderIfDefined();

    tr.addClass('show_sub_tables');

    const detailsTr = $(`\
      <tr class="sub_tables">\
        <td\
          class="rowDetailsContainer row-details-container"\
          colspan="${this.table.getColumnCount()}"\
        ></td>\
      </tr>\
    `);

    tr.after(detailsTr);

    return this.createRowDetails($('td', detailsTr), this.getRowRecord(tr));
  };

  /**
   * Hide row details.
   *
   * @param  {DOMElement} tr
   * @return {BaseList}
   */
  this.hideRowDetails = function(tr) {
    tr.removeClass('show_sub_tables');

    if (!tr.next().hasClass('jsMainRow') &&
      tr.next().hasClass('sub_tables')
    ) {
      tr.next().remove();
    }

    this.table.enableRowReorderIfAllowed();

    return this;
  };

  /**
   * Clear details map.
   *
   * @return {BaseList}
   */
  this.clearDetailsMap = function() {
    _.each(this.detailsMap, function(details, id) {
      details.destroy();
      delete this.detailsMap[id];
    }, this);

    return this;
  };

  /**
   * Show loading overlay.
   *
   * @return {BaseList}
   */
  this.showLoadingOverlay = function() {
    this.table.fadeInLoadingOverlay();

    return this;
  };

  /**
   * Hide loading overlay.
   *
   * @return {BaseList}
   */
  this.hideLoadingOverlay = function() {
    this.table.fadeOutLoadingOverlay();

    return this;
  };

  /**
   * Get active row.
   *
   * @return {DOMElement}
   */
  this.getActiveRow = function() {
    const activeA = $('tbody tr a.active', tableEl);
    return activeA.closest('tr');
  };

  /**
   * @return {object}
   */
  this.getActiveRecord = function() {
    return this.table.getRowData(
      this.getActiveRow(),
    );
  };

  /**
   * Get record unique identifier.
   *
   * @param  {object} record
   * @return {*}
   */
  this.getRecordUid = function(record) {
    return +record.id;
  };

  /**
   * Check whether the list is editable.
   *
   * @param  {object}  record
   * @return {boolean}
   */
  // this.isEditable = function(record) {
  // return false;
  // };

  /**
   * @param  {number|string} identifier
   * @return {?object}
   */
  this.getRecordByIdentifier = function(identifier) {
    return this.table.getRecordByIdentifier(identifier);
  };

  /**
   * Get records.
   *
   * @return {object[]}
   */
  this.getRecords = function() {
    return _.map(
      this.table.getTableData(),
      (recordData) => this.entityHelper.get(
        recordData,
        this.options.entityClass,
      ),
    );
  };

  /**
   * Get table DOM element.
   *
   * @return {DOMElement}
   */
  this.getTableEl = function() {
    return this.table.getTableEl();
  };

  /**
   * Get filters from current URL's query string.
   *
   * @return {?object}
   */
  this.getFiltersFromQueryString = function() {
    if (!this.options.isGlobal) {
      return null;
    }

    return Object.get(UrlHelper.getParsedQueryString(), 'filters');
  };

  /**
   * Get filters from local storage.
   *
   * @return {?object}
   */
  this.getFiltersFromLocalStorage = function() {
    if (!this.options.saveFilterSelection) {
      return null;
    }

    const storedItem = storageHelper.get(this.options.filterSelectionNamespace);

    if (_.isObject(storedItem) && !_.isEmpty(storedItem.expiry)) {
      const expiry = moment(storedItem.expiry);

      if (moment() > expiry) {
        storageHelper.remove(this.options.filterSelectionNamespace);

        return null;
      }
    }

    return storedItem?.filters || null;
  };

  /**
   * Get view by from current URL's query string.
   *
   * @return {?object}
   */
  this.getViewByFromQueryString = function() {
    if (!this.options.isGlobal) {
      return null;
    }

    return Object.get(UrlHelper.getParsedQueryString(), 'viewBy');
  };

  /**
   * Determine whether record creation is allowed.
   *
   * @return {boolean}
   */
  this.isRecordCreationAllowed = function() {
    return this.options.editAllowed;
  };

  /**
   * Determine whether record edit is allowed.
   *
   * @param  {object}  record
   * @return {boolean}
   */
  // eslint-disable-next-line no-unused-vars
  this.isRecordEditAllowed = function(record) {
    return this.options.editAllowed;
  };

  /**
   * Get default filter values.
   *
   * @return {object}
   */
  this.getDefaultFilterValues = function() {
    return {};
  };

  /**
   * Get initial filter values.
   *
   * @return {object}
   */
  this.getInitialFilterValues = function() {
    let filters = this.getFiltersFromQueryString();

    if (_.isEmpty(filters)) {
      filters = this.getFiltersFromLocalStorage();
    }

    return filters || {};
  };

  /**
   * Get initial view-by values.
   *
   * @return {object}
   */
  this.getInitialViewByValues = function() {
    return this.getViewByFromQueryString() || {};
  };
}

export default BaseList;
