import BaseHelper from 'js/base_v3/helpers/helper';
import CliJob from 'js/cli_v2/models/job';
import Ajax from 'js/components/ajax';
import { entityHelper } from 'js/helpers/entity-helper';

const staticSelf = CliJobChecker;

/**
 * @const
 */
staticSelf.INTERVAL_MILLISECONDS = 5000;

/**
 * Cli Job Checker.
 *
 * @class
 * @extends BaseHelper
 *
 * @param {object} [options]
 */
function CliJobChecker(options) {
  BaseHelper.call(this, options);

  /**
   * @prop {object}
   */
  this.checkCliJobIntervalsMap = {};

  /**
   * Check CLI job.
   *
   * @param {CliJob}    cliJob
   * @param {?number}   [intervalMillisec]
   * @param {?function} [finishedCallback]
   * @param {?function} [stoppedCallback]
   * @param {?function} [checkCallback]
   * @param {object}    [checkOpts]
   */
  this.check = function(
    cliJob,
    intervalMillisec,
    finishedCallback,
    stoppedCallback,
    checkCallback,
    checkOpts = {},
  ) {
    const intervalMilliseconds = _.isNumber(intervalMillisec) ?
      intervalMillisec :
      staticSelf.INTERVAL_MILLISECONDS;

    const checkOptions = {
      instantCheck: false,
      instantCheckDelayMillisec: 0,
    }.extend(checkOpts);

    const checkJobStatus = () => {
      Ajax.get(
        '/cli/job/details',
        { job: cliJob.id },
        (response) => {
          this.onCheckSuccess(
            response,
            finishedCallback,
            stoppedCallback,
            checkCallback,
          );
        },
      );
    };

    if (checkOptions.instantCheck) {
      setTimeout(() => {
        checkJobStatus();
      }, checkOptions.instantCheckDelayMillisec);
    }

    this.checkCliJobIntervalsMap[cliJob.id] = setInterval(() => {
      checkJobStatus();
    }, intervalMilliseconds);
  };

  /**
   * CLI job check success event handler.
   *
   * @param {object}    response
   * @param {?function} [finishedCallback]
   * @param {?function} [stoppedCallback]
   * @param {?function} [checkCallback]
   */
  this.onCheckSuccess = function(
    response,
    finishedCallback,
    stoppedCallback,
    checkCallback,
  ) {
    const cliJob = entityHelper.get(response.job, CliJob);

    if (_.isFunction(checkCallback)) {
      checkCallback(cliJob, response);
    }

    switch (cliJob.status) {
      case CliJob.STATUS_FINISHED:
        this.onCliJobFinished(cliJob, finishedCallback);
        break;
      case CliJob.STATUS_FAILED:
      case CliJob.STATUS_CANCELED:
        this.onCliJobStopped(cliJob, stoppedCallback);
        break;
      default:
        // Do nothing
    }
  };

  /**
   * CLI job finished event handler.
   *
   * @param {CliJob}    cliJob
   * @param {?function} [callback]
   */
  this.onCliJobFinished = function(cliJob, callback) {
    clearInterval(this.checkCliJobIntervalsMap[cliJob.id]);

    if (_.isFunction(callback)) {
      callback(cliJob);
    }
  };

  /**
   * CLI job stopped event handler.
   *
   * @param {CliJob}    cliJob
   * @param {?function} [callback]
   */
  this.onCliJobStopped = function(cliJob, callback) {
    clearInterval(this.checkCliJobIntervalsMap[cliJob.id]);

    if (_.isFunction(callback)) {
      callback(cliJob);
    }
  };

  // Initialize
  this._init();
}

export const cliJobChecker = new CliJobChecker();

export default CliJobChecker;
