var UrlHelper = {};

/**
 * Get base URL.
 *
 * @param  {?string} url
 * @return {?string}
 */
UrlHelper.getBaseUrl = function(url) {
    if (null === url) {
        return null;
    }

    var startCut = url.indexOf('://');

    if (-1 != startCut) {
        url = url.substring(startCut + 3, url.length);
    }

    endCut = url.indexOf('/');

    if (-1 != endCut) {
        url = url.substring(0, endCut);
    }

    return url;
};

/**
 * Get query string from current URL.
 *
 * @return {string}
 */
UrlHelper.getQueryString = function() {
    var queryString = window.location.search;

    if ('?' === queryString[0]) {
        queryString = queryString.slice(1);
    }

    return queryString;
};

/**
 * Get parsed query string from current URL.
 *
 * @return {object}
 */
UrlHelper.getParsedQueryString = function() {
    return UrlHelper.parseQueryString(UrlHelper.getQueryString());
};

/**
 * Encode.
 *
 * @param  {string} value
 * @return {string}
 */
UrlHelper.encode = function(value) {
    return encodeURIComponent(value);
};

/**
 * Parse query string.
 *
 * @param  {string} queryString
 * @return {object}
 */
UrlHelper.parseQueryString = function(queryString) {
    var pieces = _.filter(queryString.split('&'), function(piece) {
        return !_.isEmpty(piece);
    });

    var queryObj = {};

    for (var i = 0; i < pieces.length; i++) {
        var pair = pieces[i].split('=');

        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);

        var isVarArray = '[]' === key.slice(-2);

        if (isVarArray) {
            key = key.slice(0, -2);
        }

        if (isVarArray) {
            queryObj[key] = _.isArray(queryObj[key]) ? queryObj[key] : [];
            queryObj[key] = queryObj[key].concat([value]);
        } else {
            queryObj[key] = value;
        }
    }

    var processedQueryObj = {};

    for (var key in queryObj) {
        processedQueryObj = processedQueryObj.extend(key.toObject(queryObj[key]));
    }

    return processedQueryObj;
};

export default UrlHelper;
